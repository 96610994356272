import React from 'react'
import PropTypes from 'prop-types'
import Button from 'observian-ui/lib/button'
import map from 'lodash/map'

import CommentForm from 'components/Form/Comment'
import { getComments, createComment } from 'api/blog'

import style from './comments.module.css'

class BlogPostComments extends React.Component {
  static renderDate(timestamp) {
    return new Date(timestamp).toLocaleString('en-US', {
      timeZone: 'America/Denver',
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
  }

  state = {
    addComment: false,
    isCommentSuccess: false,
    comments: [],
    totalCount: 0,
  }

  componentDidMount = async () => {
    const { post } = this.props

    try {
      const res = await getComments({ postId: post.postId })

      if (200 === res.status) {
        this.setState(state => ({
          ...state,
          comments: res.data.comments,
          totalCount: res.data.totalCount,
        }))
      }
    } catch (error) {
      console.error(error, 'Unable to fetch post comments')
    }
  }

  toggleAddComment = () => {
    this.setState(state => ({ addComment: !state.addComment }))
  }

  handleSubmit = async event => {
    const { post } = this.props
    const formData = {
      comment: event.comment,
      postId: post.id,
      blogId: post.parent_blog.id,
      authorEmail: post.author_email,
      authorName: post.author_name,
      blogUrl: post.absolute_url,
      postTitle: post.page_title,
      email: event.email,
      fullName: `${event.firstName} ${event.lastName}`,
    }

    try {
      const res = await createComment(post.slug, formData)

      if (200 === res.status) {
        return this.setState({
          addComment: false,
          isCommentSuccess: true,
        })
      }

      return console.error('Unable to add new comment to post')
    } catch (error) {
      return console.error(error)
    }
  }

  render() {
    const { addComment, isCommentSuccess, comments, totalCount } = this.state

    return (
      <section className={style.container}>
        <div className={style.info}>
          <h2>Comments ({totalCount})</h2>
          <Button
            label="Add Comment"
            onClick={this.toggleAddComment}
            className={style.addComment}
          />
          <div className={style.commentForm}>
            {addComment ? (
              <CommentForm
                handleOnSubmit={e => this.handleSubmit(e)}
                handleOnClose={this.toggleAddComment}
              />
            ) : isCommentSuccess ? (
              <div className={style.commentPosted}>
                <span>Comment submitted successfully.</span>
              </div>
            ) : null}
          </div>
        </div>
        <div className={style.comments}>
          {comments && !!comments.length ? (
            map(comments, (comment, index) => (
              <div key={`blog:comment:thread:${index + 1}`} className={style.thread}>
                <div key={`blog:parent:comment:${index + 1}`} className={style.parent}>
                  <div className={style.commentInfo}>
                    <span>{comment.firstName}</span>
                    <span>{BlogPostComments.renderDate(comment.createdAt)}</span>
                  </div>
                  <p>{comment.comment}</p>
                </div>
              </div>
            ))
          ) : (
            <span className={style.noComments}>No comments to display.</span>
          )}
        </div>
      </section>
    )
  }
}

BlogPostComments.defaultProps = {}

BlogPostComments.propTypes = {
  post: PropTypes.object.isRequired,
}

export default BlogPostComments
