import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import BlogPage from 'components/Page/Blog'
import Layout from 'components/Layout'
import { transformTopics, transformPosts } from 'utils/blogTransform'

const Blog = ({ data }) => {
  const {
    observian: {
      allBlogPosts: { posts },
      allTopics: { topics },
      allAuthors: { authors },
      allBigMarkerWebinars,
    },
    allEmployees: { members },
    allContentfulWebinars: { contentfulWebinars },
    allContentfulWebinarEvents: { contentfulWebinarEvents },
  } = data

  const allCurrentTopics = transformTopics(topics)
  const allCurrentPosts = transformPosts({ posts, authors, members })

  const allWebinarProps = bgWebinars => {
    const combinedWebinars = bgWebinars.reduce((previous, current) => {
      const cfWebinars = contentfulWebinars.find(
        webinar => current.conferenceId === webinar.webinarEvent.webinarId,
      )

      const cfWebinarEvents = contentfulWebinarEvents.find(
        webinar => current.conferenceId === webinar.webinarId,
      )

      const webinarEvents = cfWebinars || cfWebinarEvents

      return [
        ...previous,
        {
          ...current,
          useExternalEventPage: webinarEvents.useExternalEventPage || false,
          featuredImage: webinarEvents
            ? webinarEvents.featuredImage
            : { file: { url: data.file.childImageSharp.fixed.src } },
          page: webinarEvents
            ? webinarEvents.useExternalEventPage
              ? { url: webinarEvents.webinarUrl, title: webinarEvents.title }
              : webinarEvents.page
            : { url: '/blog', title: 'Blog Page' },
        },
      ]
    }, [])

    return combinedWebinars
  }

  const allWebinars = allWebinarProps(allBigMarkerWebinars)

  return (
    <Layout
      title="Blog | Cloud Management Utah"
      description="View Observian's blog here to learn more about cloud management and cloud services. Stay up to date on the latest cloud-related news!"
      keywords="blog,cloud,all things cloud,experts,certified experts,all things"
    >
      <BlogPage
        members={members}
        posts={allCurrentPosts}
        topics={allCurrentTopics}
        webinars={allWebinars}
      />
    </Layout>
  )
}

Blog.defaultProps = {}

Blog.propTypes = {
  data: PropTypes.shape({
    observian: PropTypes.shape({
      allBlogPosts: PropTypes.shape({
        posts: PropTypes.arrayOf(PropTypes.object),
      }),
      allTopics: PropTypes.shape({
        topics: PropTypes.arrayOf(PropTypes.object),
      }),
      allAuthors: PropTypes.shape({
        authors: PropTypes.arrayOf(PropTypes.object),
      }),
      allBigMarkerWebinars: PropTypes.array,
    }),
    allEmployees: PropTypes.shape({
      members: PropTypes.arrayOf(PropTypes.object),
    }),
    allContentfulWebinars: PropTypes.shape({
      contentfulWebinars: PropTypes.arrayOf(PropTypes.object),
    }),
    allContentfulWebinarEvents: PropTypes.shape({
      contentfulWebinarEvents: PropTypes.arrayOf(PropTypes.object),
    }),
    file: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fixed: PropTypes.shape({
          src: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
}

const GET_FEATURED_BLOG_POST = graphql`
  query FEATURED_BLOG_POST {
    observian {
      allBlogPosts: blogPosts(limit: 50, state: PUBLISHED) {
        posts {
          postId
          slug
          postBody
          name
          topicIds
          metaDescription
          htmlTitle
          featuredImage
          publishDate
          blogAuthor {
            authorId
            avatar
            fullName
            email
          }
        }
      }
      allAuthors: blogPostAuthors {
        authors {
          authorId
          avatar
          email
          fullName
          slug
          displayName
        }
      }
      allTopics: blogPostTopics {
        topics {
          topicId
          name
          slug
        }
      }
      allBigMarkerWebinars: webinars(channelId: "observian") {
        conferenceId
        title
        startTime
        purpose
      }
    }
    allEmployees: allContentfulEmployee {
      members: nodes {
        id
        company
        employeeId
        firstName
        lastName
        fullName
        title
        photos {
          profile {
            fixed(width: 100, quality: 100) {
              src
            }
          }
        }
        emails {
          primary
          alias
        }
      }
    }
    allContentfulWebinars: allContentfulWebinar {
      contentfulWebinars: nodes {
        webinarEvent {
          title
          webinarId
          webinarUrl
          useExternalEventPage
          featuredImage {
            file {
              url
            }
            title
          }
        }
        title
        page {
          url
        }
      }
    }
    allContentfulWebinarEvents: allContentfulWebinarEvent {
      contentfulWebinarEvents: nodes {
        title
        webinarId
        webinarUrl
        useExternalEventPage
        featuredImage {
          file {
            url
          }
          title
        }
      }
    }
    file(relativePath: { eq: "default/blog/default-blog-featured-image.webp" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 250, height: 150) {
          src
        }
      }
    }
  }
`

export const BlogPost = props => (
  <StaticQuery query={GET_FEATURED_BLOG_POST} render={data => <Blog data={data} {...props} />} />
)

export default Blog
