import React from 'react'
import PropTypes from 'prop-types'

const CostOptimization = ({ className, classNames }) => (
  <svg
    className={className}
    width="230px"
    height="203px"
    viewBox="0 0 230 203"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient
        x1="12.4534884%"
        y1="84.9243045%"
        x2="87.8953488%"
        y2="16.3295925%"
        id="cost-optimization-gradient"
      >
        <stop stopColor="#1D8DBA" offset="0%" />
        <stop stopColor="#03ECAE" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="15.4356061%"
        y1="90.5184659%"
        x2="114.318971%"
        y2="21.4133523%"
        id="cost-optimization-gradient-1"
      >
        <stop stopColor="#172A3F" offset="0%" />
        <stop stopColor="#344960" offset="100%" />
      </linearGradient>
      <linearGradient x1="21.6456574%" y1="0%" x2="73.0551136%" y2="100%" id="linearGradient-3">
        <stop stopColor="#FF5B0B" offset="0%" />
        <stop stopColor="#05E5AF" offset="100%" />
      </linearGradient>
      <filter
        x="-14.6%"
        y="-31.8%"
        width="129.2%"
        height="163.5%"
        filterUnits="objectBoundingBox"
        id="cost-optimization-filter"
      >
        <feGaussianBlur stdDeviation="1.5" in="SourceGraphic" />
      </filter>
      <filter
        x="-175.0%"
        y="-175.0%"
        width="450.0%"
        height="450.0%"
        filterUnits="objectBoundingBox"
        id="cost-optimization-filter-1"
      >
        <feGaussianBlur stdDeviation="1.5" in="SourceGraphic" />
      </filter>
      <filter
        x="-175.0%"
        y="-175.0%"
        width="450.0%"
        height="450.0%"
        filterUnits="objectBoundingBox"
        id="cost-optimization-filter-2"
      >
        <feGaussianBlur stdDeviation="1.5" in="SourceGraphic" />
      </filter>
    </defs>
    <g id="cost-optimization" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="cost-optimization-container" transform="translate(-6.000000, 0.000000)">
        <image
          x="0.105777733"
          y="0.612211018"
          width="245.618197"
          height="209.25"
          xlinkHref="/images/clouds/cost-optimization-cloud.png"
        />
        <path
          d="M148.679688,59.2866211 C152.825688,59.2866211 156.185688,62.6451211 156.185688,66.7881211 L156.185688,115.538121 L73.6811875,115.538121 L73.6811875,66.7881211 C73.6811875,62.6451211 77.0411875,59.2866211 81.1811875,59.2866211 L148.679688,59.2866211 Z"
          fill="url(#cost-optimization-gradient)"
          fillRule="nonzero"
        />
        <rect
          strokeOpacity="0.348675272"
          stroke="#4A5D72"
          strokeWidth="0.625"
          fill="#F6FBFA"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
          x="124.309188"
          y="66.1365967"
          width="26.2485"
          height="49.4015244"
        />
        <rect
          strokeOpacity="0.348675272"
          stroke="#4A5D72"
          strokeWidth="0.625"
          fill="#F6FBFA"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
          x="79.3031875"
          y="100.541121"
          width="45"
          height="14.997"
        />
        <rect
          strokeOpacity="0.348675272"
          stroke="#4A5D72"
          strokeWidth="0.625"
          fill="#F6FBFA"
          fillRule="nonzero"
          strokeLinecap="round"
          strokeLinejoin="round"
          x="79.3031875"
          y="66.1365967"
          width="45"
          height="34.4045244"
        />
        <path
          d="M167.435687,121.164621 L167.435687,119.289621 C167.435687,117.216621 165.754187,115.538121 163.682687,115.538121 L66.1826875,115.538121 C64.1111875,115.538121 62.4296875,117.216621 62.4296875,119.289621 L62.4296875,121.164621 L70.3286875,125.114121 C72.1721875,126.032121 75.3716875,126.788121 77.4341875,126.788121 L152.438687,126.788121 C154.501187,126.788121 157.694687,126.032121 159.536687,125.114121 L167.435687,121.164621 Z"
          fill="url(#cost-optimization-gradient)"
          fillRule="nonzero"
        />
        <path
          d="M64.4700317,121.164621 L165.417175,121.164621 L64.4700317,121.164621 Z"
          stroke="#4A5D72"
          strokeWidth="1.125"
          opacity="0.357366071"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M126.184187,115.538121 L124.964687,117.248121 C124.604687,117.752121 123.802187,118.163121 123.185687,118.163121 L106.681187,118.163121 C106.069187,118.163121 105.266687,117.752121 104.905187,117.248121 L103.684187,115.538121 L126.184187,115.538121 Z"
          fill="#04EAAE"
          fillRule="nonzero"
        />
        <path d="M167.435687,121.164621 L167.435687,119.289621 C167.435687,117.216621 165.754187,115.538121 163.682687,115.538121 L66.1826875,115.538121 C64.1111875,115.538121 62.4296875,117.216621 62.4296875,119.289621 L62.4296875,121.164621 L70.3286875,125.114121 C72.1721875,126.032121 75.3716875,126.788121 77.4341875,126.788121 L152.438687,126.788121 C154.501187,126.788121 157.694687,126.032121 159.536687,125.114121 L167.435687,121.164621 Z" />
        <polygon
          id="Path-110"
          fill="url(#cost-optimization-gradient)"
          transform="translate(50.484619, 89.729004) rotate(21.000000) translate(-50.484619, -89.729004) "
          points="56.4355469 90.9038086 35.8720703 90.9038086 60.5942383 94.6479492 65.097168 84.8100586"
        />
        <g
          transform="translate(84.750000, 103.500000)"
          fill="url(#cost-optimization-gradient-1)"
          fillOpacity="0.5"
          opacity="0.5"
        >
          <path
            className={classNames.dollars}
            d="M2.13375391,7.93623047 L2.13375391,7.30810547 C0.933753906,7.08310547 0.361878906,6.23935547 0.155628906,5.65810547 L1.85250391,4.73935547 C2.09625391,5.15185547 2.43375391,5.43310547 2.72437891,5.43310547 C2.84625391,5.43310547 2.91187891,5.36748047 2.91187891,5.27373047 C2.91187891,4.67373047 0.352503906,4.58935547 0.352503906,2.64873047 C0.352503906,1.58935547 1.04625391,0.839355469 2.13375391,0.623730469 L2.13375391,0.0518554687 L3.53062891,0.0518554687 L3.53062891,0.642480469 C4.56187891,0.895605469 5.11500391,1.66435547 5.26500391,2.12373047 L3.66187891,3.06123047 C3.52125391,2.77060547 3.24000391,2.46123047 2.94000391,2.46123047 C2.81812891,2.46123047 2.74312891,2.53623047 2.74312891,2.62998047 C2.74312891,3.24873047 5.31187891,3.39873047 5.31187891,5.24560547 C5.31187891,6.31435547 4.64625391,7.06435547 3.53062891,7.28935547 L3.53062891,7.93623047 L2.13375391,7.93623047 Z"
          />
          <path
            className={classNames.dollars}
            d="M11.1337539,7.93623047 L11.1337539,7.30810547 C9.93375391,7.08310547 9.36187891,6.23935547 9.15562891,5.65810547 L10.8525039,4.73935547 C11.0962539,5.15185547 11.4337539,5.43310547 11.7243789,5.43310547 C11.8462539,5.43310547 11.9118789,5.36748047 11.9118789,5.27373047 C11.9118789,4.67373047 9.35250391,4.58935547 9.35250391,2.64873047 C9.35250391,1.58935547 10.0462539,0.839355469 11.1337539,0.623730469 L11.1337539,0.0518554687 L12.5306289,0.0518554687 L12.5306289,0.642480469 C13.5618789,0.895605469 14.1150039,1.66435547 14.2650039,2.12373047 L12.6618789,3.06123047 C12.5212539,2.77060547 12.2400039,2.46123047 11.9400039,2.46123047 C11.8181289,2.46123047 11.7431289,2.53623047 11.7431289,2.62998047 C11.7431289,3.24873047 14.3118789,3.39873047 14.3118789,5.24560547 C14.3118789,6.31435547 13.6462539,7.06435547 12.5306289,7.28935547 L12.5306289,7.93623047 L11.1337539,7.93623047 Z"
          />
          <path
            className={classNames.dollars}
            d="M20.1337539,7.93623047 L20.1337539,7.30810547 C18.9337539,7.08310547 18.3618789,6.23935547 18.1556289,5.65810547 L19.8525039,4.73935547 C20.0962539,5.15185547 20.4337539,5.43310547 20.7243789,5.43310547 C20.8462539,5.43310547 20.9118789,5.36748047 20.9118789,5.27373047 C20.9118789,4.67373047 18.3525039,4.58935547 18.3525039,2.64873047 C18.3525039,1.58935547 19.0462539,0.839355469 20.1337539,0.623730469 L20.1337539,0.0518554687 L21.5306289,0.0518554687 L21.5306289,0.642480469 C22.5618789,0.895605469 23.1150039,1.66435547 23.2650039,2.12373047 L21.6618789,3.06123047 C21.5212539,2.77060547 21.2400039,2.46123047 20.9400039,2.46123047 C20.8181289,2.46123047 20.7431289,2.53623047 20.7431289,2.62998047 C20.7431289,3.24873047 23.3118789,3.39873047 23.3118789,5.24560547 C23.3118789,6.31435547 22.6462539,7.06435547 21.5306289,7.28935547 L21.5306289,7.93623047 L20.1337539,7.93623047 Z"
          />
          <path
            className={classNames.dollars}
            d="M29.1337539,7.93623047 L29.1337539,7.30810547 C27.9337539,7.08310547 27.3618789,6.23935547 27.1556289,5.65810547 L28.8525039,4.73935547 C29.0962539,5.15185547 29.4337539,5.43310547 29.7243789,5.43310547 C29.8462539,5.43310547 29.9118789,5.36748047 29.9118789,5.27373047 C29.9118789,4.67373047 27.3525039,4.58935547 27.3525039,2.64873047 C27.3525039,1.58935547 28.0462539,0.839355469 29.1337539,0.623730469 L29.1337539,0.0518554687 L30.5306289,0.0518554687 L30.5306289,0.642480469 C31.5618789,0.895605469 32.1150039,1.66435547 32.2650039,2.12373047 L30.6618789,3.06123047 C30.5212539,2.77060547 30.2400039,2.46123047 29.9400039,2.46123047 C29.8181289,2.46123047 29.7431289,2.53623047 29.7431289,2.62998047 C29.7431289,3.24873047 32.3118789,3.39873047 32.3118789,5.24560547 C32.3118789,6.31435547 31.6462539,7.06435547 30.5306289,7.28935547 L30.5306289,7.93623047 L29.1337539,7.93623047 Z"
          />
        </g>
        <path
          className={classNames.dollar1}
          d="M178.083754,84.262793 L178.083754,81.750293 C173.283754,80.850293 170.996254,77.475293 170.171254,75.150293 L176.958754,71.475293 C177.933754,73.125293 179.283754,74.250293 180.446254,74.250293 C180.933754,74.250293 181.196254,73.987793 181.196254,73.612793 C181.196254,71.212793 170.958754,70.875293 170.958754,63.112793 C170.958754,58.875293 173.733754,55.875293 178.083754,55.012793 L178.083754,52.725293 L183.671254,52.725293 L183.671254,55.087793 C187.796254,56.100293 190.008754,59.175293 190.608754,61.012793 L184.196254,64.762793 C183.633754,63.600293 182.508754,62.362793 181.308754,62.362793 C180.821254,62.362793 180.521254,62.662793 180.521254,63.037793 C180.521254,65.512793 190.796254,66.112793 190.796254,73.500293 C190.796254,77.775293 188.133754,80.775293 183.671254,81.675293 L183.671254,84.262793 L178.083754,84.262793 Z"
          fill="url(#cost-optimization-gradient)"
        />
        <path
          className={classNames.dollar2}
          d="M47.4784375,50.7655664 L47.4784375,49.0068164 C44.1184375,48.3768164 42.5171875,46.0143164 41.9396875,44.3868164 L46.6909375,41.8143164 C47.3734375,42.9693164 48.3184375,43.7568164 49.1321875,43.7568164 C49.4734375,43.7568164 49.6571875,43.5730664 49.6571875,43.3105664 C49.6571875,41.6305664 42.4909375,41.3943164 42.4909375,35.9605664 C42.4909375,32.9943164 44.4334375,30.8943164 47.4784375,30.2905664 L47.4784375,28.6893164 L51.3896875,28.6893164 L51.3896875,30.3430664 C54.2771875,31.0518164 55.8259375,33.2043164 56.2459375,34.4905664 L51.7571875,37.1155664 C51.3634375,36.3018164 50.5759375,35.4355664 49.7359375,35.4355664 C49.3946875,35.4355664 49.1846875,35.6455664 49.1846875,35.9080664 C49.1846875,37.6405664 56.3771875,38.0605664 56.3771875,43.2318164 C56.3771875,46.2243164 54.5134375,48.3243164 51.3896875,48.9543164 L51.3896875,50.7655664 L47.4784375,50.7655664 Z"
          fill="url(#cost-optimization-gradient)"
        />
        <path
          d="M82.8679199,84.9558105 C87.9807129,77.2702637 92.7565918,75.348877 97.1955566,79.1916504 C103.854004,84.9558105 104.655273,95.175293 118.775635,93.6525879"
          stroke="url(#linearGradient-3)"
          strokeWidth="1.5"
          opacity="0.433482143"
          filter="url(#cost-optimization-filter)"
        />
        <circle
          stroke="#4A5D72"
          strokeWidth="1.5"
          fill="#04EAAE"
          filter="url(#cost-optimization-filter-1)"
          cx="82.75"
          cy="84"
          r="1.5"
        />
        <circle
          stroke="#4A5D72"
          strokeWidth="1.5"
          fill="#04EAAE"
          filter="url(#cost-optimization-filter-2)"
          cx="118.775635"
          cy="92.3054251"
          r="1.5"
        />
        <path
          d="M82.8679199,81.9558105 C87.9807129,74.2702637 92.7565918,72.348877 97.1955566,76.1916504 C103.854004,81.9558105 104.655273,92.175293 118.775635,90.6525879"
          stroke="url(#linearGradient-3)"
          strokeWidth="1.5"
        />
        <circle fill="#FF5B0C" cx="82.75" cy="82.5" r="1.5" />
        <circle fill="#04EAAE" cx="118.775635" cy="90.8054251" r="1.5" />
        <path
          className={classNames.line1}
          d="M133.108291,106.487787 L133.108291,79.4877866"
          stroke="#03ECAE"
          strokeWidth="5"
          strokeLinecap="square"
          fillRule="nonzero"
        />
        <path
          className={classNames.line2}
          d="M143.108291,106.487787 L143.108291,79.4877866"
          stroke="#03ECAE"
          strokeWidth="5"
          strokeLinecap="square"
        />
        <polygon
          fill="url(#cost-optimization-gradient)"
          points="122.892578 8.93408203 138.063965 4.94677734 156.976563 26.0493164 138.063965 11.0024414 128.91748 13.6113281"
        />
      </g>
    </g>
  </svg>
)

CostOptimization.defaultProps = {
  className: '',
  classNames: {
    dollar1: 'dollar1',
    dollar2: 'dollar2',
    dollars: 'dollars',
    line1: 'line1',
    line2: 'line2',
  },
}

CostOptimization.propTypes = {
  className: PropTypes.string,
  classNames: PropTypes.shape({
    dollar1: PropTypes.string,
    dollar2: PropTypes.string,
    dollars: PropTypes.string,
    line1: PropTypes.string,
    line2: PropTypes.string,
  }),
}

export default CostOptimization
