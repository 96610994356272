import React from 'react'
import cn from 'clsx'

import canUseDOM from 'utils/canUseDOM'

import style from './meetingPlugin.module.css'

class MeetingPlugin extends React.Component {
  static loadHubspot() {
    if (canUseDOM) {
      const script = window.document.createElement('script')
      script.type = 'text/javascript'
      script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'
      script.async = true
      script.id = 'hubspot-meeting'

      document.head.appendChild(script)
    }
  }

  static removeHubspot() {
    if (canUseDOM) {
      const script = document.getElementById('hubspot-meeting')

      if (script) document.head.removeChild(script)
    }
  }

  componentDidMount() {
    MeetingPlugin.loadHubspot()
  }

  componentWillUnmount() {
    MeetingPlugin.removeHubspot()
  }

  render() {
    return (
      <div
        className={cn('meetings-iframe-container', style.hubspot)}
        data-src="https://meetings.hubspot.com/observian/30-minute?embed=true"
        data-testid="meetings-iframe-container"
      />
    )
  }
}

MeetingPlugin.defaultProps = {}

MeetingPlugin.propTypes = {}

export default MeetingPlugin
