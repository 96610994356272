import React from 'react'
import PropTypes from 'prop-types'
import Button from 'observian-ui/lib/button'
import cn from 'clsx'
import { Form as FinalForm, Field } from 'react-final-form'

import { isRequired, isEmail } from 'utils/validation'

import style from './commentForm.module.css'
/* eslint-disable jsx-a11y/label-has-associated-control */
const CommentForm = props => {
  const { handleOnSubmit, handleOnClose, className, commentTitle } = props
  return (
    <FinalForm validateOnBlur onSubmit={e => handleOnSubmit(e)}>
      {({ handleSubmit, pristine, submitting }) => (
        <form onSubmit={handleSubmit} className={cn(style.commentForm, className)}>
          <div role="presentation" className={style.close} onClick={handleOnClose}>
            &times;
          </div>
          <Field name="firstName" validate={isRequired}>
            {({ input, meta }) => (
              <div className={style.inputText}>
                <label htmlFor="firstName">
                  First Name:
                  <input {...input} id={input.name} />
                  {meta.error && meta.touched && (
                    <span data-testid="form-firstName-error" className={style.validationError}>
                      {meta.error}
                    </span>
                  )}
                </label>
              </div>
            )}
          </Field>
          <Field name="lastName" validate={isRequired}>
            {({ input, meta }) => (
              <div className={style.inputText}>
                <label htmlFor="lastName">
                  Last Name:
                  <input {...input} id={input.name} />
                  {meta.error && meta.touched && (
                    <span data-testid="form-lastName-error" className={style.validationError}>
                      {meta.error}
                    </span>
                  )}
                </label>
              </div>
            )}
          </Field>
          <Field name="email" validate={isEmail}>
            {({ input, meta }) => (
              <div className={style.inputText}>
                <label htmlFor="email">
                  Email:
                  <input {...input} id={input.name} type="email" />
                  {meta.error && meta.touched && (
                    <span data-testid="form-email-error" className={style.validationError}>
                      {meta.error}
                    </span>
                  )}
                </label>
              </div>
            )}
          </Field>
          <Field name="comment">
            {({ input }) => (
              <div className={style.inputText}>
                <label htmlFor="comment">
                  {commentTitle}
                  <textarea {...input} id={input.name} />
                </label>
              </div>
            )}
          </Field>
          <Button variant="secondary" label="Submit" disabled={pristine || submitting} />
        </form>
      )}
    </FinalForm>
  )
}

CommentForm.defaultProps = {
  className: '',
  commentTitle: 'Comment',
}

CommentForm.propTypes = {
  className: PropTypes.string,
  commentTitle: PropTypes.string,
  handleOnSubmit: PropTypes.func.isRequired,
  handleOnClose: PropTypes.func.isRequired,
}

export default CommentForm
