export const submitHubspotFormMutation = `
  mutation SubmitHubspotForm($form: HubspotFormSubmission!) {
    submitHubspotForm(form: $form) {
      redirectUrl
      inlineMessage
      errors {
        message
        errorType
      }
    }
  }
`

export const submitWebinarFormMutation = `
  mutation SubmitWebinarForm($form: WebinarRegistration!) {
    webinarRegistration(form: $form) {
      conferenceUrl
    }
  }
`

export default submitHubspotFormMutation
