import React from 'react'
import PropTypes from 'prop-types'

import SecurityImage from '../../../static/images/clouds/security-image.png'

const ObservianSecurity = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="779"
    height="467"
    viewBox="0 0 779 467"
  >
    <defs>
      <linearGradient id="observian-security-a" x1="12.453%" x2="87.895%" y1="55.242%" y2="44.946%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <filter
        id="observian-security-b"
        width="115.3%"
        height="139.5%"
        x="-7.6%"
        y="-19.7%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="8" />
      </filter>
      <linearGradient id="observian-security-c" x1="12.453%" x2="87.895%" y1="55.34%" y2="44.852%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <filter
        id="observian-security-d"
        width="141.4%"
        height="206%"
        x="-20.7%"
        y="-53%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="8" />
      </filter>
      <linearGradient d="observian-security-e" x1="18.153%" x2="82.143%" y1="84.924%" y2="16.33%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <filter
        id="observian-security-f"
        width="185.5%"
        height="178.7%"
        x="-42.7%"
        y="-39.4%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="8" />
      </filter>
      <linearGradient id="observian-security-g" x1="23.831%" x2="76.412%" y1="84.924%" y2="16.33%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <filter
        id="observian-security-h"
        width="368.5%"
        height="280.1%"
        x="-134.3%"
        y="-90%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
      </filter>
      <filter
        id="observian-security-i"
        width="368.6%"
        height="268.2%"
        x="-134.3%"
        y="-84.1%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
      </filter>
      <filter
        id="observian-security-j"
        width="242.1%"
        height="566.4%"
        x="-71.1%"
        y="-233.2%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="2" />
      </filter>
      <filter
        id="observian-security-k"
        width="212.2%"
        height="531%"
        x="-56.1%"
        y="-215.5%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="2" />
      </filter>
      <linearGradient id="observian-security-n" x1="96.8%" x2="16.877%" y1="48.254%" y2="52.006%">
        <stop offset="0%" stopColor="#1C3148" />
        <stop offset="4.603%" stopColor="#1C3047" />
        <stop offset="100%" stopColor="#0F172D" />
      </linearGradient>
      <polyline id="observian-security-m" points="179.164 277.131 285 219.262 406.954 266.112" />
      <filter
        id="observian-security-l"
        width="137.3%"
        height="242.9%"
        x="-16.5%"
        y="-65.7%"
        filterUnits="objectBoundingBox"
      >
        <feMorphology in="SourceAlpha" operator="dilate" radius="7.5" result="shadowSpreadOuter1" />
        <feOffset dx="5" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feMorphology in="SourceAlpha" radius="7.5" result="shadowInner" />
        <feOffset dx="5" dy="2" in="shadowInner" result="shadowInner" />
        <feComposite
          in="shadowOffsetOuter1"
          in2="shadowInner"
          operator="out"
          result="shadowOffsetOuter1"
        />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="10" />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.211764706   0 0 0 0 0.933333333   0 0 0 0 0.745098039  0 0 0 0.25 0"
        />
      </filter>
      <linearGradient id="observian-security-q" x1="65.111%" x2="39.305%" y1="22.945%" y2="81.085%">
        <stop offset="0%" stopColor="#1C3148" />
        <stop offset="4.603%" stopColor="#1C3047" />
        <stop offset="100%" stopColor="#0F172D" />
      </linearGradient>
      <polyline id="observian-security-p" points="419.164 477.131 495 409.262 508.954 319.112" />
      <filter
        id="observian-security-o"
        width="193.7%"
        height="153.3%"
        x="-42.4%"
        y="-24.7%"
        filterUnits="objectBoundingBox"
      >
        <feMorphology in="SourceAlpha" operator="dilate" radius="7.5" result="shadowSpreadOuter1" />
        <feOffset dx="5" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feMorphology in="SourceAlpha" radius="7.5" result="shadowInner" />
        <feOffset dx="5" dy="2" in="shadowInner" result="shadowInner" />
        <feComposite
          in="shadowOffsetOuter1"
          in2="shadowInner"
          operator="out"
          result="shadowOffsetOuter1"
        />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="10" />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.211764706   0 0 0 0 0.933333333   0 0 0 0 0.745098039  0 0 0 0.25 0"
        />
      </filter>
      <filter
        id="observian-security-r"
        width="109.7%"
        height="138%"
        x="-4.8%"
        y="-19%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
      </filter>
      <filter
        id="observian-security-s"
        width="124.5%"
        height="113.9%"
        x="-12.3%"
        y="-7%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
      </filter>
      <polygon id="observian-security-t" points=".036 .14 545.836 .14 545.836 461.62 .036 461.62" />
      <polygon id="observian-security-v" points=".126 .361 23.836 .361 23.836 144.62 .126 144.62" />
      <polygon
        id="observian-security-x"
        points=".878 127.672 511.945 422.451 511.945 294.874 .878 .102"
      />
      <linearGradient id="observian-security-y" x1="96.8%" x2="16.877%" y1="31.523%" y2="71.229%">
        <stop offset="0%" stopColor="#1C3148" />
        <stop offset="4.603%" stopColor="#1C3047" />
        <stop offset="100%" stopColor="#0F172D" />
      </linearGradient>
      <linearGradient id="observian-security-A" x1="29.022%" x2="89.036%" y1="90.518%" y2="21.413%">
        <stop offset="0%" stopColor="#172A3F" />
        <stop offset="100%" stopColor="#344960" />
      </linearGradient>
      <linearGradient id="observian-security-B" x1="17.576%" x2="82.725%" y1="84.924%" y2="16.33%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <filter
        id="observian-security-C"
        width="153.3%"
        height="149.5%"
        x="-26.7%"
        y="-24.8%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="2" />
      </filter>
      <linearGradient id="observian-security-D" x1="-.013%" x2="100.001%" y1="50.087%" y2="50.087%">
        <stop offset="0%" stopColor="#FA6262" />
        <stop offset="100%" stopColor="#FFB74D" />
      </linearGradient>
      <filter
        id="observian-security-E"
        width="153.3%"
        height="151.7%"
        x="-26.7%"
        y="-25.8%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="2" />
      </filter>
      <filter
        id="observian-security-F"
        width="153.3%"
        height="151.7%"
        x="-26.7%"
        y="-25.8%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="2" />
      </filter>
      <linearGradient id="observian-security-G" x1="29.022%" x2="89.038%" y1="90.518%" y2="21.413%">
        <stop offset="0%" stopColor="#172A3F" />
        <stop offset="100%" stopColor="#344960" />
      </linearGradient>
      <linearGradient id="observian-security-H" x1="16.553%" x2="83.758%" y1="84.924%" y2="16.33%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <filter
        id="observian-security-I"
        width="153.3%"
        height="150.3%"
        x="-26.7%"
        y="-25.2%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="2" />
      </filter>
      <filter
        id="observian-security-J"
        width="153.3%"
        height="149.5%"
        x="-26.7%"
        y="-24.8%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="2" />
      </filter>
      <filter
        id="observian-security-K"
        width="153.3%"
        height="149.5%"
        x="-26.7%"
        y="-24.8%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="2" />
      </filter>
      <linearGradient id="observian-security-L" x1="35.056%" x2="65.083%" y1="84.924%" y2="16.33%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <linearGradient id="observian-security-M" x1="39.875%" x2="60.219%" y1="84.924%" y2="16.33%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <linearGradient id="observian-security-N" x1="35.056%" x2="65.082%" y1="84.924%" y2="16.33%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <linearGradient id="observian-security-O" x1="39.875%" x2="60.219%" y1="84.924%" y2="16.33%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(0 -180)">
      <g transform="translate(178.4)">
        <image width="625.995" height="625.995" x=".764" y=".715" xlinkHref={SecurityImage} />
        <polygon
          fill="url(#observian-security-a)"
          points="152.269 381.752 277.047 412.213 436.734 341.341 256.968 431.339 159.874 389.667 141.434 381.752 122.905 309.752"
          filter="url(#observian-security-b)"
          transform="rotate(5 279.82 370.545)"
        />
        <polygon
          fill="url(#observian-security-a)"
          points="152.269 381.752 277.047 412.213 436.734 341.341 256.968 431.339 159.874 389.667 141.434 381.752 122.905 309.752"
        />
        <polygon
          fill="url(#observian-security-c)"
          points="398.574 257.238 471.041 266.547 514.436 302.543 456.505 272.059"
          filter="url(#observian-security-d)"
          transform="rotate(6 456.505 279.89)"
        />
        <polygon
          fill="url(#observian-security-c)"
          points="398.574 257.238 471.041 266.547 514.436 302.543 456.505 272.059"
        />
        <polygon
          fill="url(#observian-security-e)"
          points="185.836 261.209 162.03 216.155 218.198 200.222 211.59 216.74 174.183 221.024"
          filter="url(#observian-security-f)"
          transform="rotate(-7 190.114 230.715)"
        />
        <polygon
          fill="url(#observian-security-g)"
          points="185.818 261.502 162.013 216.447 213.416 199.929 205.342 216.447 174.165 221.317"
        />
      </g>
      <path
        stroke="#36EEBE"
        strokeLinecap="round"
        strokeWidth="4"
        d="M405.953713,271.804564 C414.143033,270.457329 416.190363,266.385389 412.095703,259.588744"
        filter="url(#observian-security-h)"
        opacity=".725"
      />
      <path
        stroke="#36EEBE"
        strokeLinecap="round"
        strokeWidth="4"
        d="M405.953713,274.580932 C414.143033,272.658169 416.190363,268.298466 412.095703,261.501821"
        filter="url(#observian-security-i)"
        opacity=".725"
      />
      <g
        stroke="#36EEBE"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        opacity=".617"
        transform="translate(503.004 311.644)"
      >
        <path
          d="M1,7.45201065 C4.7421875,3.13951065 8.49422201,3.22601781 12.2561035,7.71153213"
          filter="url(#observian-security-j)"
        />
        <path
          d="M0,2.45201065 C5.7421875,-1.19382268 10.494222,-0.77398219 14.2561035,3.71153213"
          filter="url(#observian-security-k)"
        />
      </g>
      <g strokeLinecap="round" strokeLinejoin="round">
        <use fill="#000" filter="url(#observian-security-l)" xlinkHref="#observian-security-m" />
        <use
          stroke="url(#observian-security-n)"
          strokeWidth="15"
          xlinkHref="#observian-security-m"
        />
      </g>
      <g strokeLinecap="round" strokeLinejoin="round">
        <use fill="#000" filter="url(#observian-security-o)" xlinkHref="#observian-security-p" />
        <use
          stroke="url(#observian-security-q)"
          strokeWidth="15"
          xlinkHref="#observian-security-p"
        />
      </g>
      <polyline
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        points="179.164 277.131 285 219.262 406.954 266.112"
        filter="url(#observian-security-r)"
        opacity=".196"
      />
      <polyline
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        points="419.164 477.131 495 409.262 508.954 319.112"
        filter="url(#observian-security-s)"
        opacity=".196"
      />
      <g transform="translate(0 183.06)">
        <g transform="translate(0 .86)">
          <g transform="translate(8)">
            <mask id="observian-security-u" fill="#fff">
              <use xlinkHref="#observian-security-t" />
            </mask>
            <path
              fill="#F6FBFA"
              d="M0.036,11.381 L15.16,2.679 L15.182,2.694 C20.087,-0.362 26.532,-0.924 32.253,2.376 L528.071,288.355 C539.063,294.694 545.837,306.419 545.837,319.109 L545.837,443.028 C545.837,447.456 543.348,451.008 539.927,452.827 L539.928,452.846 L524.702,461.62 L522.285,455.293 L526.68,451.214 L19.29,158.557 C11.905,154.297 7.354,146.419 7.354,137.894 L7.354,16.758 C7.354,16.524 7.391,16.302 7.401,16.07 L0.036,11.381 Z"
              mask="url(#observian-security-u)"
            />
          </g>
          <path
            fill="#607791"
            fillOpacity=".349"
            d="M497.6678,333.8263 L550.2228,303.5133 C547.1678,297.2673 542.2988,291.9453 536.0708,288.3543 L530.2218,284.9803 L497.6888,303.7963 L497.6678,333.8263 Z"
          />
          <g transform="translate(530 317)">
            <mask id="observian-security-w" fill="#fff">
              <use xlinkHref="#observian-security-v" />
            </mask>
            <path
              fill="#607791"
              fillOpacity=".349"
              d="M23.7782,0.3614 L0.1262,14.0414 L0.1262,131.5884 L4.6792,134.2144 L0.2852,138.2934 L2.7022,144.6204 L17.9272,135.8454 L17.9262,135.8274 C21.3472,134.0074 23.8372,130.4564 23.8372,126.0284 L23.8372,2.1084 C23.8372,1.5234 23.8062,0.9424 23.7782,0.3614"
              mask="url(#observian-security-w)"
            />
          </g>
          <path
            fill="#DCE1E0"
            d="M24.8983,11.2208 L520.7163,297.1988 C531.7093,303.5378 538.4813,315.2628 538.4813,327.9518 L538.4813,451.8728 C538.4813,460.4238 529.2263,465.7698 521.8193,461.4968 L11.9353,167.4008 C4.5503,163.1408 0.0003,155.2638 0.0003,146.7378 L0.0003,25.6028 C0.0003,12.8248 13.8293,4.8358 24.8983,11.2208"
          />
        </g>
        <g transform="translate(12 22.86)">
          <mask id="observian-security-z" fill="#fff">
            <use xlinkHref="#observian-security-x" />
          </mask>
          <polygon
            fill="url(#observian-security-y)"
            points=".878 127.672 511.945 422.451 511.945 294.874 .878 .102"
            mask="url(#observian-security-z)"
          />
        </g>
        <g transform="translate(28 47.86)">
          <polygon
            fill="url(#observian-security-A)"
            points=".277 42.67 59.234 76.676 59.234 35.003 .277 .998"
          />
          <polygon fill="#607791" points="64.657 38.121 64.657 42.13 97.765 61.303 97.765 57.295" />
          <polygon fill="#607791" points="64.657 50.22 97.765 69.393 97.765 65.385 64.657 46.211" />
          <polygon fill="#607791" points="64.657 58.31 97.765 77.483 97.765 73.474 64.657 54.301" />
          <polygon fill="#607791" points="64.657 66.4 84.517 77.9 84.517 73.891 64.657 62.392" />
          <path
            stroke="url(#observian-security-B)"
            strokeDasharray="50 4"
            strokeLinecap="round"
            strokeWidth="4"
            d="M30.4658203,54.9900689 C38.7500916,58.2765566 45.4658203,54.2250525 45.4658203,45.9407812 C45.4658203,37.65651 38.7500916,28.2765566 30.4658203,24.9900689 C22.1815491,21.7035813 15.4658203,25.7550854 15.4658203,34.0393566 C15.4658203,42.3236279 22.1815491,51.7035813 30.4658203,54.9900689 Z"
            filter="url(#observian-security-C)"
            opacity=".825"
          />
          <path
            stroke="url(#observian-security-B)"
            strokeDasharray="50 4"
            strokeLinecap="round"
            strokeWidth="4"
            d="M26.4658203,57.4032123 C34.7500916,60.6897 41.4658203,56.6381959 41.4658203,48.3539246 C41.4658203,40.0696534 34.7500916,30.6897 26.4658203,27.4032123 C18.1815491,24.1167247 11.4658203,28.1682288 11.4658203,36.4525 C11.4658203,44.7367712 18.1815491,54.1167247 26.4658203,57.4032123 Z"
          />
          <g transform="translate(106 62)">
            <polygon
              fill="url(#observian-security-A)"
              points=".031 41.71 58.988 75.716 58.988 34.043 .031 .038"
            />
            <polygon
              fill="#607791"
              points="64.411 37.161 64.411 41.17 97.519 60.343 97.519 56.334"
            />
            <polygon
              fill="#607791"
              points="64.411 49.26 97.519 68.433 97.519 64.424 64.411 45.251"
            />
            <polygon
              fill="#607791"
              points="64.411 57.35 97.519 76.523 97.519 72.514 64.411 53.341"
            />
            <polygon
              fill="#607791"
              points="64.411 65.44 84.271 76.941 84.271 72.932 64.411 61.431"
            />
            <path
              stroke="url(#observian-security-D)"
              strokeDasharray="44 30"
              strokeLinecap="round"
              strokeWidth="4"
              d="M29,54.9976469 C37.2842712,57.1156503 44,52.1169025 44,43.8326313 C44,35.54836 37.2842712,27.1156503 29,24.9976469 C20.7157288,22.8796435 14,27.8783913 14,36.1626625 C14,44.4469337 20.7157288,52.8796435 29,54.9976469 Z"
              filter="url(#observian-security-E)"
              opacity=".825"
            />
            <path
              stroke="url(#observian-security-D)"
              strokeDasharray="44 30"
              strokeLinecap="round"
              strokeWidth="4"
              d="M25,57.9749844 C33.2842712,60.0929878 40,55.09424 40,46.8099687 C40,38.5256975 33.2842712,30.0929878 25,27.9749844 C16.7157288,25.856981 10,30.8557288 10,39.14 C10,47.4242712 16.7157288,55.856981 25,57.9749844 Z"
            />
          </g>
          <g transform="translate(211 122)">
            <polygon
              fill="url(#observian-security-A)"
              points=".785 42.661 59.742 76.667 59.742 34.994 .785 .989"
            />
            <polygon
              fill="#607791"
              points="65.165 38.113 65.165 42.121 98.273 61.294 98.273 57.286"
            />
            <polygon
              fill="#607791"
              points="65.165 50.211 98.273 69.384 98.273 65.376 65.165 46.203"
            />
            <polygon
              fill="#607791"
              points="65.165 58.301 98.273 77.474 98.273 73.466 65.165 54.293"
            />
            <polygon
              fill="#607791"
              points="65.165 66.391 85.025 77.892 85.025 73.884 65.165 62.383"
            />
            <path
              stroke="url(#observian-security-D)"
              strokeDasharray="44 40"
              strokeLinecap="round"
              strokeWidth="4"
              d="M32,54.9976469 C40.2842712,57.1156503 47,52.1169025 47,43.8326313 C47,35.54836 40.2842712,27.1156503 32,24.9976469 C23.7157288,22.8796435 17,27.8783913 17,36.1626625 C17,44.4469337 23.7157288,52.8796435 32,54.9976469 Z"
              filter="url(#observian-security-F)"
              opacity=".825"
            />
            <path
              stroke="url(#observian-security-D)"
              strokeDasharray="44 40"
              strokeLinecap="round"
              strokeWidth="4"
              d="M28,57.9749844 C36.2842712,60.0929878 43,55.09424 43,46.8099688 C43,38.5256975 36.2842712,30.0929878 28,27.9749844 C19.7157288,25.856981 13,30.8557288 13,39.14 C13,47.4242712 19.7157288,55.856981 28,57.9749844 Z"
            />
          </g>
          <g transform="translate(0 52)">
            <polygon
              fill="url(#observian-security-G)"
              points=".277 42.422 59.234 76.427 59.234 34.755 .277 .75"
            />
            <polygon
              fill="#607791"
              points="64.657 37.873 64.657 41.881 97.765 61.055 97.765 57.046"
            />
            <polygon
              fill="#607791"
              points="64.657 49.972 97.765 69.145 97.765 65.136 64.657 45.963"
            />
            <polygon
              fill="#607791"
              points="64.657 58.062 97.765 77.235 97.765 73.226 64.657 54.053"
            />
            <polygon
              fill="#607791"
              points="64.657 66.151 84.517 77.651 84.517 73.644 64.657 62.142"
            />
            <path
              stroke="url(#observian-security-H)"
              strokeDasharray="50 4"
              strokeLinecap="round"
              strokeWidth="4"
              d="M28.4658203,56.2358755 C36.7500916,59.1288031 43.4658203,54.7582556 43.4658203,46.4739844 C43.4658203,38.1897131 36.7500916,29.1288031 28.4658203,26.2358755 C20.1815491,23.3429478 13.4658203,27.7134953 13.4658203,35.9977665 C13.4658203,44.2820378 20.1815491,53.3429478 28.4658203,56.2358755 Z"
              filter="url(#observian-security-I)"
              opacity=".825"
            />
            <path
              stroke="url(#observian-security-H)"
              strokeDasharray="50 4"
              strokeLinecap="round"
              strokeWidth="4"
              d="M24.4658203,58.8390464 C32.7500916,61.7319741 39.4658203,57.3614266 39.4658203,49.0771553 C39.4658203,40.7928841 32.7500916,31.7319741 24.4658203,28.8390464 C16.1815491,25.9461187 9.46582031,30.3166663 9.46582031,38.6009375 C9.46582031,46.8852087 16.1815491,55.9461187 24.4658203,58.8390464 Z"
            />
          </g>
          <g transform="translate(106 113)">
            <polygon
              fill="url(#observian-security-A)"
              points=".031 42.461 58.988 76.467 58.988 34.795 .031 .789"
            />
            <polygon
              fill="#607791"
              points="64.411 37.913 64.411 41.921 97.519 61.094 97.519 57.086"
            />
            <polygon
              fill="#607791"
              points="64.411 50.011 97.519 69.184 97.519 65.176 64.411 46.003"
            />
            <polygon
              fill="#607791"
              points="64.411 58.101 97.519 77.274 97.519 73.266 64.411 54.093"
            />
            <polygon
              fill="#607791"
              points="64.411 66.191 84.271 77.692 84.271 73.683 64.411 62.182"
            />
            <path
              stroke="url(#observian-security-B)"
              strokeDasharray="50 4"
              strokeLinecap="round"
              strokeWidth="4"
              d="M28.8857422,52.9803033 C37.1700134,56.266791 43.8857422,52.2152869 43.8857422,43.9310156 C43.8857422,35.6467444 37.1700134,26.266791 28.8857422,22.9803033 C20.6014709,19.6938156 13.8857422,23.7453197 13.8857422,32.029591 C13.8857422,40.3138622 20.6014709,49.6938156 28.8857422,52.9803033 Z"
              filter="url(#observian-security-J)"
              opacity=".825"
            />
            <path
              stroke="url(#observian-security-B)"
              strokeDasharray="50 4"
              strokeLinecap="round"
              strokeWidth="4"
              d="M24.8857422,55.3934467 C33.1700134,58.6799344 39.8857422,54.6284303 39.8857422,46.344159 C39.8857422,38.0598878 33.1700134,28.6799344 24.8857422,25.3934467 C16.6014709,22.106959 9.88574219,26.1584631 9.88574219,34.4427344 C9.88574219,42.7270056 16.6014709,52.106959 24.8857422,55.3934467 Z"
            />
          </g>
          <g transform="translate(211 174)">
            <polygon
              fill="url(#observian-security-A)"
              points=".785 42.413 59.742 76.419 59.742 34.746 .785 .741"
            />
            <polygon
              fill="#607791"
              points="65.165 37.864 65.165 41.873 98.273 61.046 98.273 57.037"
            />
            <polygon
              fill="#607791"
              points="65.165 49.963 98.273 69.136 98.273 65.127 65.165 45.954"
            />
            <polygon
              fill="#607791"
              points="65.165 58.053 98.273 77.226 98.273 73.217 65.165 54.044"
            />
            <polygon
              fill="#607791"
              points="65.165 66.143 85.025 77.644 85.025 73.635 65.165 62.134"
            />
            <path
              stroke="url(#observian-security-B)"
              strokeDasharray="50 4"
              strokeLinecap="round"
              strokeWidth="4"
              d="M29.8857422,51.9803033 C38.1700134,55.266791 44.8857422,51.2152869 44.8857422,42.9310156 C44.8857422,34.6467444 38.1700134,25.266791 29.8857422,21.9803033 C21.6014709,18.6938156 14.8857422,22.7453197 14.8857422,31.029591 C14.8857422,39.3138622 21.6014709,48.6938156 29.8857422,51.9803033 Z"
              filter="url(#observian-security-K)"
              opacity=".825"
            />
            <path
              stroke="url(#observian-security-B)"
              strokeDasharray="50 4"
              strokeLinecap="round"
              strokeWidth="4"
              d="M25.8857422,54.3934467 C34.1700134,57.6799344 40.8857422,53.6284303 40.8857422,45.344159 C40.8857422,37.0598878 34.1700134,27.6799344 25.8857422,24.3934467 C17.6014709,21.106959 10.8857422,25.1584631 10.8857422,33.4427344 C10.8857422,41.7270056 17.6014709,51.106959 25.8857422,54.3934467 Z"
            />
          </g>
          <g transform="translate(333 204)">
            <path
              fill="url(#observian-security-L)"
              d="M8.8846,9.0177 C19.0776,10.3357 27.1666,14.8537 28.1926,15.4457 C28.2666,15.4887 28.3166,15.5177 28.3446,15.5347 L28.5136,15.6387 L28.7016,15.7417 C28.7296,15.7557 28.7796,15.7847 28.8546,15.8277 C30.2376,16.6257 42.3056,23.8527 56.6056,40.7017 L56.6056,73.7837 C56.5726,74.7257 56.2876,80.0927 53.7786,85.0697 C50.6196,91.3337 45.4316,94.2517 37.4516,94.2517 C37.1556,94.2517 36.8546,94.2467 36.5486,94.2397 C30.7106,87.8307 25.6756,81.0717 21.5546,74.1087 C17.8566,67.8587 14.8776,61.4247 12.7036,54.9857 C9.2116,44.6447 8.9096,37.5067 8.8846,36.4437 L8.8846,9.0177 Z M0.3806,0.2537 L0.3806,36.5337 C0.4106,38.1747 0.9846,69.3707 32.6106,102.4817 C34.3126,102.6667 35.9236,102.7547 37.4516,102.7547 C64.3996,102.7547 65.0716,75.4187 65.1086,73.9037 L65.1086,37.6247 C49.2516,18.2307 35.3946,9.7857 33.1056,8.4627 C32.8876,8.3377 32.7806,8.2797 32.7756,8.2767 C32.7696,8.2737 32.6616,8.2067 32.4446,8.0807 C30.3046,6.8467 18.0406,0.1547 3.4376,0.1547 C2.4286,0.1547 1.4086,0.1867 0.3806,0.2537 L0.3806,0.2537 Z"
            />
            <path
              fill="url(#observian-security-M)"
              d="M33.5057,32.0206 C37.6437,34.4096 40.9967,40.2196 40.9837377,44.9726 L40.9827,45.4326 L40.9727,48.8386 L40.9647,51.6126 L25.9617,42.9506 L25.9697,40.1766 L25.9797,36.7706 L25.9807,36.3106 C25.9937,31.5576 29.3687,29.6316 33.5057,32.0206 L33.5057,32.0206 Z M32.3747,55.6866 C31.7357,54.8936 31.3067,53.8526 31.3096844,52.9456 C31.3137,51.5896 32.2717,51.0456 33.4497,51.7256 C34.6287,52.4066 35.5797,54.0536 35.5757126,55.4086 C35.5727,56.3166 35.1407,56.8586 34.5007,56.9146 L34.4807,64.1546 C34.4787,64.8286 34.0007,65.1006 33.4127,64.7606 C32.8257,64.4216 32.3527,63.6006 32.3547,62.9266 L32.3747,55.6866 Z M22.6887,34.4106 L22.6697,41.0496 L21.9667,40.6436 C21.4157,40.3256 20.9657,40.5816 20.9637,41.2156 L20.9106933,59.8496 C20.9087,60.4856 21.3567,61.2576 21.9077,61.5766 L44.8997,74.8506 C45.4507,75.1686 45.8997,74.9136 45.9017,74.2786 L45.9537067,55.6436 C45.9557,55.0086 45.5097,54.2366 44.9587,53.9176 L44.2567,53.5126 L44.2757,46.8736 C44.2947,40.0336 39.4697,31.6746 33.5167,28.2376 C27.5657,24.8016 22.7077,27.5696 22.6887,34.4106 L22.6887,34.4106 Z"
            />
          </g>
          <g transform="translate(409 247)">
            <path
              fill="url(#observian-security-N)"
              d="M8.7177,9.8517 C18.9107,11.1687 26.9997,15.6867 28.0257,16.2787 C28.0997,16.3217 28.1507,16.3507 28.1777,16.3687 L28.3467,16.4717 L28.5357,16.5747 C28.5627,16.5887 28.6127,16.6177 28.6877,16.6617 C30.0707,17.4587 42.1387,24.6867 56.4387,41.5357 L56.4387,74.6167 C56.4067,75.5587 56.1207,80.9257 53.6127,85.9027 C50.4527,92.1667 45.2657,95.0847 37.2847,95.0847 C36.9887,95.0847 36.6877,95.0807 36.3827,95.0727 C30.5437,88.6647 25.5087,81.9047 21.3877,74.9417 C17.6897,68.6917 14.7117,62.2577 12.5367,55.8197 C9.0447,45.4777 8.7427,38.3407 8.7177,37.2767 L8.7177,9.8517 Z M0.2147,1.0877 L0.2147,37.3667 C0.2437,39.0087 0.8177,70.2037 32.4447,103.3157 C34.1457,103.4997 35.7567,103.5877 37.2847,103.5877 C64.2327,103.5877 64.9047,76.2527 64.9417,74.7377 L64.9417,38.4577 C49.0847,19.0637 35.2277,10.6187 32.9397,9.2967 C32.7217,9.1707 32.6137,9.1137 32.6087,9.1097 C32.6027,9.1067 32.4947,9.0397 32.2777,8.9147 C30.1387,7.6797 17.8737,0.9887 3.2707,0.9887 C2.2617,0.9887 1.2417,1.0197 0.2147,1.0877 L0.2147,1.0877 Z"
            />
            <path
              fill="url(#observian-security-O)"
              d="M33.3387,32.8536 C37.4767,35.2426 40.8307,41.0526 40.8177377,45.8056 L40.8157,46.2656 L40.8057,49.6716 L40.7977,52.4456 L25.7947,43.7836 L25.8027,41.0096 L25.8127,37.6036 L25.8137,37.1436 C25.8267,32.3906 29.2027,30.4656 33.3387,32.8536 L33.3387,32.8536 Z M32.2077,56.5196 C31.5687,55.7266 31.1407,54.6856 31.142693,53.7786 C31.1467,52.4236 32.1057,51.8786 33.2827,52.5586 C34.4627,53.2396 35.4127,54.8866 35.4087126,56.2426 C35.4067,57.1496 34.9737,57.6926 34.3337,57.7476 L34.3137,64.9876 C34.3117,65.6616 33.8337,65.9336 33.2457,65.5936 C32.6597,65.2546 32.1857,64.4346 32.1877,63.7596 L32.2077,56.5196 Z M22.5217,35.2436 L22.5027,41.8826 L21.8007,41.4776 C21.2497,41.1596 20.7997,41.4146 20.7977,42.0486 L20.7446933,60.6836 C20.7427,61.3186 21.1907,62.0916 21.7407,62.4096 L44.7337,75.6836 C45.2837,76.0016 45.7337,75.7466 45.7347,75.1116 L45.7877067,56.4766 C45.7897,55.8416 45.3437,55.0696 44.7927,54.7516 L44.0897,54.3456 L44.1097,47.7076 C44.1287,40.8666 39.3027,32.5076 33.3497,29.0716 C27.3987,25.6346 22.5407,28.4036 22.5217,35.2436 L22.5217,35.2436 Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

ObservianSecurity.defaultProps = {
  className: '',
}

ObservianSecurity.propTypes = {
  className: PropTypes.string,
}

export default ObservianSecurity
