import React from 'react'
import cn from 'clsx'
import map from 'lodash/map'
import { gsap, Power0, Elastic } from 'gsap'
import { ScrollixGSAPAnimations } from 'observian-ui/lib/scrollix'
import Divider from 'observian-ui/lib/divider'

import Gears from 'components/SVG/gears'
import Particles from 'components/SVG/particles'
import GraphOutline from 'components/SVG/graphOutline'
import WhatWeDoLaser from 'components/SVG/lasers/whatWeDo'

import style from './whatWeDo.module.css'

const cardData = [
  {
    title: 'Cloud Migration',
    description:
      "Moving to the cloud isn't easy, even if you've been there before. This is where we live. We know how to help your company adopt new solutions, and we move you faster, more efficiently, and with a structure that scales right along with your highest ambitions.",
  },
  {
    title: 'Disaster Recovery',
    description:
      'When cyber-space and human-space collide, things happen. Disaster recovery in the cloud turns what would have been a crisis into a tiny inconvenience.',
  },
  {
    title: 'DevOps Automation',
    description:
      'Let your engineers focus on solving business problems rather than focusing on delivering code. With Continuous Integration and Continuous Delivery solutions, deployment problems become a distant memory.',
  },
  {
    title: 'Security & Compliance',
    description:
      'As your digital footprint grows...security, privacy, and compliance become more important than ever. We help you check all the right boxes to entertain auditors with confidence.',
  },
  {
    title: 'Big Data',
    description:
      "You've already hired smart people. Have you thought about hiring smart machines? Learn what your large data sets can teach you with machine-learning and map-reducing techniques.",
  },
  {
    title: 'Optimization',
    description:
      "The cloud is constantly changing and offering new and better solutions to common problems. We're constantly staying up to date to make sure you’re using the most cutting edge and efficient tools available.",
  },
  {
    title: 'Professional Services',
    description:
      'Augment your existing talent with our engineers and architects to meet business-critical deadlines. We offer professional cloud services in Utah and nationwide.',
  },
]

class WhatWeDo extends React.PureComponent {
  constructor(props) {
    super(props)

    this.observers = []
  }

  componentDidMount() {
    gsap.to(`.${style.topGear}`, {
      rotation: 360,
      ease: Power0.easeNone,
      transformOrigin: '50% 50%',
      repeat: -1,
      duration: 10,
    })
    gsap.to(`.${style.bottomGear}`, {
      rotation: -360,
      ease: Power0.easeNone,
      transformOrigin: '50% 50%',
      repeat: -1,
      duration: 6,
    })

    const tlCards = gsap.timeline()
    tlCards.from(`.${style.card0}`, { x: '-=100', opacity: 0, duration: 2.8 }, 0)
    tlCards.from(`.${style.card1}`, { x: '-=100', y: '+=100', opacity: 0, duration: 2.8 }, 0.25)
    tlCards.from(
      `.${style.card2}`,
      { opacity: 0, scale: 0.8, ease: Elastic.easeOut.config(1, 0.8), duration: 2.8 },
      0.35,
    )
    tlCards.from(`.${style.card3}`, { x: '+=100', y: '-=100', opacity: 0, duration: 2.8 }, 0.45)
    tlCards.from(`.${style.card4}`, { y: '+=150', opacity: 0, duration: 2.8 }, 0.55)
    tlCards.from(`.${style.card5}`, { x: '+=100', y: '+=150', opacity: 0, duration: 2.8 }, 0.65)
    tlCards.from(
      `.${style.card6}`,
      { opacity: 0, scale: 0.8, ease: Elastic.easeOut.config(1, 0.8), duration: 2.8 },
      1.2,
    )

    const tlParticles = gsap.timeline()
    tlParticles.fromTo(
      `.${style.particle}`,
      { rotation: 0, y: '+=35', x: '+=45' },
      { rotation: 70, y: 0, x: 0, transformOrigin: '50% 50%', duration: 5 },
      0,
    )
    tlParticles.fromTo(
      `.${style.particle1}`,
      { rotation: 0, y: '-=25', x: '-=5' },
      { rotation: 70, y: '-=50', x: 0, transformOrigin: '50% 50%', duration: 5 },
      0,
    )
    tlParticles.fromTo(
      `.${style.particle2}`,
      { rotation: 0, y: 0, x: '-=35' },
      { rotation: 70, y: '-=45', x: 0, transformOrigin: '50% 50%', duration: 5 },
      0,
    )
    tlParticles.fromTo(
      `.${style.particle3}`,
      { rotation: 0, y: '+=35', x: '-=35' },
      { rotation: 70, y: 0, x: '-=0', transformOrigin: '50% 50%', duration: 5 },
      0,
    )
    tlParticles.fromTo(
      `.${style.particle4}`,
      { rotation: 0, y: '+=15', x: '+=75' },
      { rotation: 0, y: '-=15', x: 0, transformOrigin: '50% 50%', duration: 5 },
      0,
    )
    tlParticles.fromTo(
      `.${style.particle5}`,
      { rotation: 0, y: '+=15', x: '-=25' },
      { rotation: 0, y: '-=15', x: 0, transformOrigin: '50% 50%', duration: 5 },
      0,
    )

    const orangeGlow = gsap.timeline()
    orangeGlow.to(`.${style.orangeGlow}`, {
      rotation: 4,
      y: '-=35',
      transformOrigin: '50% 50%',
      duration: 5,
    })

    this.observers = [
      new ScrollixGSAPAnimations({
        threshold: 1,
        targetElement: `.${style.header}`,
        targetTween: tlCards,
        infinite: true,
        onlyOnce: true,
      }),
      new ScrollixGSAPAnimations({
        threshold: 0.1,
        targetElement: `.${style.card6}`,
        duration: 1900,
        targetTween: tlParticles,
        scrollBased: true,
      }),
      new ScrollixGSAPAnimations({
        threshold: 0.1,
        targetElement: `.${style.whatWeDo}`,
        duration: 800,
        targetTween: orangeGlow,
        scrollBased: true,
      }),
    ]
  }

  componentWillUnmount() {
    if (this.observers && !!this.observers.length) {
      this.observers.map(observer => observer.destroy())
    }
  }

  render() {
    return (
      <section className={style.whatWeDo}>
        <Divider isReversed className={style.divider}>
          <WhatWeDoLaser
            type="top"
            className={style.laserTop}
            classNames={{ glow: style.orangeGlow }}
          />
        </Divider>
        <header className={style.header}>
          <h2 className={style.heading}>What We Do</h2>
        </header>
        <Gears
          className={style.gears}
          gearClassName={{ topGear: style.topGear, bottomGear: style.bottomGear }}
        />

        {map(cardData, (card, index) => (
          <div className={cn(style.card, style[`card${index}`])} key={`whatWeDo:card:${index}`}>
            <h2 className={style.title}>{card.title}</h2>
            <span className={style.description}>{card.description}</span>
          </div>
        ))}

        <Particles
          className={style.particles}
          type="small"
          particles={{
            particle: style.particle,
            particle1: style.particle1,
            particle2: style.particle2,
            particle3: style.particle3,
            particle4: style.particle4,
            particle5: style.particle5,
          }}
        />
        <GraphOutline className={style.backgroundOutline} />

        <Divider className={style.divider}>
          <WhatWeDoLaser className={style.laserBottom} />
        </Divider>
      </section>
    )
  }
}

export default WhatWeDo
