import React from 'react'
import { gsap } from 'gsap'

import Layout from 'components/Layout'
import AWSPageTemplate from 'templates/Page/AWS'
import MSOnAWS from 'components/SVG/clouds/microsoftOnAws'

class MicrosoftOnAWSPagge extends React.Component {
  componentDidMount() {
    const microsoft = gsap.timeline()
    microsoft.to('.logo1', { x: '+=10', y: '-=5', repeat: -1, yoyo: true, duration: 7 }, 0)
    microsoft.to('.logo2', { x: '-=5', y: '+=5', repeat: -1, yoyo: true, duration: 4 }, 0)
    microsoft.to('.logo3', { x: '+=5', y: '-=8', repeat: -1, yoyo: true, duration: 4 }, 0)
  }

  render() {
    return (
      <Layout
        title="Microsoft on AWS | AWS Services"
        description="For organizations looking to move their Windows workloads to the cloud, Observian offers services to help you run Microsoft on AWS. Learn more here!"
      >
        <AWSPageTemplate
          name="Microsoft on AWS"
          title="Run Microsoft Workloads on AWS"
          content="<p>
            Want to know how comprehensive Amazon Web Services is? AWS offers the largest set of global compute, database, application, and deployment services that use (or are designed to use) Microsoft technologies outside of, well, Microsoft themselves. For organizations looking to move their Windows workloads to the cloud, these services power a wide variety of workloads such as web and mobile applications, data processing, warehousing, and many more. And if you’re running Microsoft Windows, Exchange, SharePoint, SQL Server, System Center, or creating custom .NET applications, you can rely on the secure global infrastructure of AWS to easily run your Microsoft applications in the cloud.
            <br />
            <br />
            Observian excels at automating Microsoft deployments on to AWS, and we can even manage enterprise migrations from legacy infrastructures. If you are interested in a hybrid strategy, we can help you use AWS as an extension of your data center. We also partner with some of the best Hybrid Architecture companies, such as <a href='/partners/tonaquint'>Tonaquint</a> and <a href='/partners/turbonomic'>Turbonomic</a>.
            <br />
            <br />
            If you’re Microsoft-centric, and want to remove the complexities of managing and scaling your data center, or need to accelerate and automate your application deployments onto AWS, contact us today. All these services help you move faster, lower IT costs, and scale applications.
            </p>
            <br />
            <h3>Run Windows Server Applications on AWS</h3>
            <ul>
            <li>Automate and manage Windows Server workloads across large fleets of instances.</li>
            <br />
            <li>Ensure your data is protected with end-to-end encryption.</li>
            <br />
            <li>Pay only for the resources you use and scale up and down based on demand.</li>
            <br />
            <li>Work with the most experienced professionals in the industry at running Microsoft on AWS.</li>
            </ul>
            <br />
            <h3>Business-Ready Applications</h3>
            <ul>
            <li>Deploy and scale applications quickly and easily by using predefined software instances for common Microsoft Applications.</li>
            <br />
            <li>Purchase Microsoft software licenses, including Windows Server and SQL Server, directly through AWS.</li>
            <br />
            <li>Utilize Amazon’s highly scalable, easily managed, flexible compute infrastructure that supports all your Microsoft applications.</li>
            </ul>
            <h3>Develop and Deploy New Applications</h3>
            <ul>
            <li>Deploy and scale Microsoft applications on AWS.</li>
            <br />
            <li>Code new applications using .NET SDK and Visual Studio Toolkit.</li>
            <br />
            <li>Build a DevOps methodology with automated package installations, database setups, and <a href='/partners/chef'>Chef</a> code deployment.</li>
            </ul>"
          headerIcon={<MSOnAWS />}
          inquiryPlaceholder="Hi! We could use some help with using Microsoft on AWS..."
        />
      </Layout>
    )
  }
}

export default MicrosoftOnAWSPagge
