import React from 'react'
import { gsap, Power0 } from 'gsap'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'

import Layout from 'components/Layout'
import ServicesPageTemplate from 'templates/Page/Services'
import CostOptimization from 'components/SVG/clouds/costOptimization'

class CostOptimizationPage extends React.Component {
  componentDidMount() {
    gsap.registerPlugin(DrawSVGPlugin)
    const cost = gsap.timeline()
    const cost1 = gsap.timeline({ repeat: -1 })
    cost.to(
      '.line2',
      { drawSVG: '0', repeat: -1, yoyo: true, ease: Power0.easeNone, duration: 0.65 },
      0,
    )

    cost.from(
      '.line1',
      { drawSVG: '0', repeat: -1, yoyo: true, ease: Power0.easeNone, duration: 0.85 },
      0,
    )

    cost.to(
      '.dollar1',
      { x: '+=8', y: '-=10', repeat: -1, yoyo: true, ease: Power0.easeNone, duration: 4 },
      0,
    )

    cost.to(
      '.dollar2',
      { x: '-=5', y: '+=10', repeat: -1, yoyo: true, ease: Power0.easeNone, duration: 4 },
      0,
    )

    cost1.fromTo('.dollars', { opacity: 0.25 }, { opacity: 1, duration: 0.25, stagger: 0.25 })
  }

  render() {
    return (
      <Layout
        title="Cost Optimization | Cloud Cost Optimization"
        description="Observian specializes in cloud cost optimization to make sure companies are only paying for the cloud services they need. Schedule a conversation today!"
      >
        <ServicesPageTemplate
          name="Cost Optimization"
          title="Cloud Cost Optimization"
          content="
          <p>
          The most important aspect of your business is a healthy bottom line. There are two ways to accomplish this: generating revenue and reducing overhead costs. Cloud computing is a big overhead cost and requires continuous monitoring and adjustment to ensure resources are being used correctly and are not being wasted. Observian specializes in helping companies analyze their cloud services and pricing options to make sure they are only paying for what they need. Using tools such as Cloudability and CloudCheckr, we create visibility into your cloud computing spend.
          <br />
          <br />
          We are so confident we can save you money, and in some cases, drastically reduce your monthly costs, we’ll happily analyze your infrastructure for free. A quick conversation with one of our cloud cost optimization experts could immediately impact your cloud price and performance.
          When price decreases and performance improves, you help your bottom line.
          </p>
          <br />
          <br />
          <h3>Oversight</h3>
          <p>
          <i>Are you utilizing a Cloud Asset Management tool?</i>
          <br />
          Gain visibility by using monitoring tools to ensure you always know what you're spending, what you're using, and when things are changing.
          <br />
          <br />
          <i>Do you have a content distribution strategy?</i>
          <br />
          Understanding how and where you are distributing your content to consumers has a huge impact on storage and distribution costs.
          </p>
          <br />
          <br />
          <h3>Resources</h3>
          <p>
          <i>Are you using the most up-to-date services?</i>
          <br />
          Make sure you get the best performance at the lowest price by using Amazon’s continually upgraded services.
          <br />
          <br />
          <i>Do you have unused, idle, and/or misused resources?</i>
          <br />
          Identify all cloud computing resources to ensure they are not idle or no longer in use, but instead are correctly configured to deliver the performance and services you need.
          <br />
          <br />
          <i>Are you managing Reserved Instances (RI)?</i>
          <br />
          Use RIs to prepay for cloud resources at a lower price. Knowing how much you’ll need, and when you need them, gets you the best deal.
          <br />
          <br />
          <i>Are you utilizing Spot Instances?</i>
          <br />
          You can request unused instances at steep discounts. If you are flexible about when your applications run, Spot Instances are a very cost-effective option.
          </p>
          <br />
          <br />
          <h3>Storage</h3>
          <p>
          <i>Are you optimizing your storage services?</i>
          <br />
          Amazon offers many levels of storage services at different prices. The right storage strategy saves money and ensures availability.
          </p>"
          headerIcon={<CostOptimization />}
          inquiryPlaceholder="Hi! We could use some help utilizing our cloud resources more efficiently and reducing costs..."
        />
      </Layout>
    )
  }
}

export default CostOptimizationPage
