import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Layout from 'components/Layout'
import SearchResults from 'components/Page/Blog/Search'

const BlogSearch = ({ data, location }) => {
  const {
    observian: {
      allBlogPosts: { posts },
      allTopics: { topics },
      allAuthors: { authors },
    },
    allEmployees: { members },
  } = data

  const { searchQuery } = (location && location.state) || { searchQuery: '' }

  return (
    <Layout title="Blog Search | Cloud Management Utah" description="" keywords="">
      <SearchResults
        topics={topics}
        members={members}
        posts={posts}
        authors={authors}
        searchQuery={searchQuery}
      />
    </Layout>
  )
}

BlogSearch.defaultProps = {
  location: {},
}

BlogSearch.propTypes = {
  location: PropTypes.object,
  data: PropTypes.shape({
    observian: PropTypes.shape({
      allBlogPosts: PropTypes.shape({
        posts: PropTypes.arrayOf(PropTypes.object),
      }),
      allTopics: PropTypes.shape({
        topics: PropTypes.arrayOf(PropTypes.object),
      }),
      allAuthors: PropTypes.shape({
        authors: PropTypes.arrayOf(PropTypes.object),
      }),
    }),
    allEmployees: PropTypes.shape({
      members: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
}

const GET_BLOG_POST_TOPICS = graphql`
  query GET_BLOG_POST_TOPICS {
    observian {
      allBlogPosts: blogPosts(limit: 100, state: PUBLISHED) {
        posts {
          postId
          slug
          postBody
          name
          topicIds
          metaDescription
          featuredImage
          publishDate
          blogAuthor {
            authorId
            avatar
            fullName
            email
          }
        }
      }
      allAuthors: blogPostAuthors {
        authors {
          authorId
          avatar
          email
          fullName
          slug
          displayName
        }
      }
      allTopics: blogPostTopics {
        topics {
          topicId
          name
          slug
        }
      }
      allBigMarkerWebinars: webinars {
        conferenceId
        title
        startTime
        purpose
      }
    }
    allEmployees: allContentfulEmployee {
      members: nodes {
        id
        employeeId
        firstName
        lastName
        fullName
        title
        company
        photos {
          profile {
            fixed(width: 100, quality: 100) {
              src
            }
          }
        }
        emails {
          primary
          alias
        }
      }
    }
  }
`

export const BlogSearchFilter = props => (
  <StaticQuery
    query={GET_BLOG_POST_TOPICS}
    render={data => <BlogSearch data={data} {...props} />}
  />
)

export default BlogSearch
