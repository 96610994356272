import React from 'react'
import PropTypes from 'prop-types'
import Button from 'observian-ui/lib/button'
import Logo from 'observian-ui/lib/logo'
import ContactIcon from 'observian-ui/lib/svg/icons/contact'
import { Form as FinalForm, Field } from 'react-final-form'
import clsx from 'clsx'
import AddToCalendar from '@culturehq/add-to-calendar'
import moment from 'moment'

import { mutateQL, queryQL } from 'api/graphql'
import HubspotTracking from 'components/Hubspot/Tracking'
import { isRequired, isEmail, isPhoneNumber } from 'utils/validation'

import { submitHubspotFormMutation, submitWebinarFormMutation } from './mutateQuery'
import queryWebinar from './query'
import style from './hubspotForm.module.css'
import './addToCalendar.css'

class Form extends React.Component {
  state = {
    isSuccessful: false,
    webinarRegistrationSuccessful: false,
    webinarCalendarData: {
      title: '',
      purpose: '',
      startTime: '',
      duration: 0,
      conferenceAddress: '',
    },
  }

  componentDidMount = async () => {
    const { webinarId } = this.props

    if (webinarId) {
      const webinarResponse = await queryQL({ query: queryWebinar({ webinarId }) })
      const { data } = webinarResponse
      const { webinar: webData } = data.data

      this.setState({
        webinarCalendarData: {
          title: webData.title,
          purpose: webData.purpose,
          startTime: webData.startTime,
          duration: webData.duration,
          conferenceAddress: webData.conferenceAddress,
        },
      })
    }
  }

  handleOnSubmit = async event => {
    const { inquiryPlaceholder, ctaIntentSuffix, formId } = this.props
    const { webinarId, firstName, lastName, email, phone, company, type, context } = event
    let webinarData = {}

    const inquiry = event && event.inquiry ? inquiryPlaceholder : event.inquiry
    const ctaIntent = ctaIntentSuffix.length
      ? event.ctaIntent
      : `${event.ctaIntent}-${ctaIntentSuffix}`

    const hubspotFormData = {
      type,
      firstName,
      lastName,
      email,
      phone,
      company,
      formId,
      inquiry,
      ctaIntent,
      context,
    }

    if (webinarId) {
      webinarData = {
        webinarId,
        firstName,
        lastName,
        email,
      }
    }

    try {
      let webinarResponseData = {}
      const hubspotResponse = await mutateQL({
        query: submitHubspotFormMutation,
        inputKey: 'form',
        inputData: hubspotFormData,
      })

      if (webinarId) {
        const webinarResponse = await mutateQL({
          query: submitWebinarFormMutation,
          inputKey: 'form',
          inputData: webinarData,
        })

        if (200 === webinarResponse.status) {
          webinarResponseData = webinarResponse
        }
      }

      if (200 === hubspotResponse.status) {
        if (200 === webinarResponseData.status)
          this.setState({ webinarRegistrationSuccessful: true })
        return this.renderSubmission()
      }

      return console.error(
        { hubspot: hubspotResponse.data.message, webinarJam: webinarResponseData.data.message } ||
          'Unknown Error.',
      )
    } catch (error) {
      return console.error(error)
    }
  }

  handleOnDismiss = () => {
    const { onDismiss } = this.props

    if (onDismiss) {
      this.renderSubmission(false)

      return onDismiss()
    }

    return this.renderSubmission(false)
  }

  renderSubmission = (value = true) => {
    this.setState({
      isSuccessful: value,
    })
  }

  render() {
    const {
      props: {
        buttonLabel,
        isSuccessfulMessage,
        title,
        ctaIntent,
        showLogo,
        type,
        webinarId,
        showDoneButton,
      },
      state: { isSuccessful, webinarRegistrationSuccessful, webinarCalendarData },
    } = this
    const isSuccessfulMessageWebinar =
      'Registration has been confirmed, you should receive a confirmation email shortly.'

    let startDate = null
    let endDate = null
    if (webinarCalendarData && webinarCalendarData.startTime) {
      startDate = moment(webinarCalendarData.startTime).toISOString()
      endDate = moment(startDate).add(webinarCalendarData.duration, 'minutes').toISOString()
    }

    return (
      <HubspotTracking>
        {({ context }) => (
          <>
            {!isSuccessful ? (
              <FinalForm
                validateOnBlur
                onSubmit={e => this.handleOnSubmit({ ...e, context })}
                initialValues={{
                  ctaIntent,
                  type,
                  webinarId,
                  schedule: webinarCalendarData.scheduleId,
                  leadSource: 'webinar',
                }}
              >
                {({ handleSubmit, pristine, submitting }) => (
                  <form className={style.form} onSubmit={handleSubmit}>
                    <header className={style.header}>
                      <h6>{title}</h6>
                      {showLogo ? (
                        <Logo
                          darkLogo
                          showText
                          darkText
                          className={style.logo}
                          classNames={{ logo: style.icon, text: style.iconText }}
                        />
                      ) : null}
                    </header>
                    <div className={style.name}>
                      <Field name="firstName" placeholder="First Name" validate={isRequired}>
                        {({ input, meta, placeholder }) => (
                          <div className={style.singleRow}>
                            <ContactIcon type="person" className={style.contactIcon} />
                            <input {...input} placeholder={placeholder} id={input.name} />
                            {meta.error && meta.touched && (
                              <span
                                data-testid="form-firstName-error"
                                className={style.validationError}
                              >
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="lastName" placeholder="Last Name" validate={isRequired}>
                        {({ input, meta, placeholder }) => (
                          <div className={style.singleRow}>
                            <input {...input} placeholder={placeholder} id={input.name} />
                            {meta.error && meta.touched && (
                              <span
                                data-testid="form-lastName-error"
                                className={style.validationError}
                              >
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <Field name="company" placeholder="Company Name">
                      {({ input, placeholder }) => (
                        <div className={style.inputContainer}>
                          <ContactIcon type="building" className={style.contactIcon} />
                          <input {...input} placeholder={placeholder} id={input.name} />
                        </div>
                      )}
                    </Field>
                    <Field name="jobtitle" placeholder="Job Title">
                      {({ input, placeholder }) => (
                        <div className={style.inputContainer}>
                          <input {...input} placeholder={placeholder} id={input.name} />
                        </div>
                      )}
                    </Field>
                    <div className={style.contact}>
                      <Field name="email" placeholder="Email" validate={isEmail}>
                        {({ input, meta, placeholder }) => (
                          <div className={clsx(style.inputContainer, style.singleRow)}>
                            <ContactIcon type="email" className={style.contactIcon} />
                            <input
                              {...input}
                              placeholder={placeholder}
                              id={input.name}
                              type="email"
                            />
                            {meta.error && meta.touched && (
                              <span
                                data-testid="form-email-error"
                                className={style.validationError}
                              >
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="phone" placeholder="Phone Number" validate={isPhoneNumber}>
                        {({ input, meta, placeholder }) => (
                          <div className={clsx(style.inputContainer, style.singleRow)}>
                            <ContactIcon
                              type="phone"
                              className={clsx(style.contactIcon, style.phoneNumber)}
                            />
                            <input
                              {...input}
                              placeholder={placeholder}
                              id={input.name}
                              type="tel"
                            />
                            {meta.error && meta.dirty && meta.touched && (
                              <span
                                data-testid="form-phoneNumber-error"
                                className={style.validationError}
                              >
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <Field name="ctaIntent" type="hidden">
                      {({ input }) => <input {...input} type="hidden" />}
                    </Field>
                    <Field name="leadSource" type="hidden">
                      {({ input }) => <input {...input} type="hidden" />}
                    </Field>
                    <Field name="type" type="hidden">
                      {({ input }) => <input {...input} type="hidden" />}
                    </Field>
                    {webinarId ? (
                      <>
                        <Field name="webinarId" type="hidden">
                          {({ input }) => <input {...input} type="hidden" />}
                        </Field>
                        <Field name="schedule" type="hidden">
                          {({ input }) => <input {...input} type="hidden" />}
                        </Field>
                      </>
                    ) : null}
                    <div className={style.buttonContainer}>
                      <Button
                        variant="primary"
                        label={buttonLabel}
                        className={style.buttonCTA}
                        disabled={pristine || submitting}
                      />
                    </div>
                  </form>
                )}
              </FinalForm>
            ) : (
              <div className={style.success}>
                <span data-testid="form-success-message">
                  {webinarRegistrationSuccessful ? isSuccessfulMessageWebinar : isSuccessfulMessage}
                </span>
                {webinarId ? (
                  <div
                    className={clsx(
                      style.addToCalendar,
                      showDoneButton && style.addToCalendarMargin,
                    )}
                  >
                    <AddToCalendar
                      event={{
                        name: webinarCalendarData.title,
                        location: null,
                        details: `${webinarCalendarData.purpose} ${webinarCalendarData.conferenceAddress}`,
                        startsAt: startDate,
                        endsAt: endDate,
                      }}
                    />
                  </div>
                ) : null}
                {showDoneButton ? (
                  <Button
                    variant="cancel"
                    label="Done"
                    onClick={this.handleOnDismiss}
                    className={style.successButtonCTA}
                  />
                ) : null}
              </div>
            )}
          </>
        )}
      </HubspotTracking>
    )
  }
}

Form.defaultProps = {
  inquiryPlaceholder:
    'Hi! We are currently in the cloud and would like to talk about how we can optimize our implementation.',
  buttonLabel: 'Send',
  onDismiss: () => {},
  isSuccessfulMessage: "Your inquiry has been sent, we'll be in touch soon!",
  ctaIntent: 'contact-form',
  ctaIntentSuffix: '',
  title: 'Contact Form',
  inquiryTitle: 'How can we help you?',
  showLogo: true,
  webinarId: '',
  showDoneButton: false,
}

Form.propTypes = {
  formId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  inquiryPlaceholder: PropTypes.string,
  buttonLabel: PropTypes.string,
  onDismiss: PropTypes.func,
  isSuccessfulMessage: PropTypes.string,
  ctaIntent: PropTypes.string,
  ctaIntentSuffix: PropTypes.string,
  title: PropTypes.string,
  inquiryTitle: PropTypes.string,
  showLogo: PropTypes.bool,
  webinarId: PropTypes.string,
  showDoneButton: PropTypes.bool,
}

export default Form
