import React, { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import Logo from 'observian-ui/lib/logo'
import { Social } from 'observian-ui/lib/svg/icons'
import Button from 'observian-ui/lib/button'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import Divider from 'observian-ui/lib/divider'
import { Form as FinalForm, Field } from 'react-final-form'
import { Link } from 'gatsby'

import { isEmail } from 'utils/validation'
import HubspotTracking from 'components/Hubspot/Tracking'
import newsletter from 'api/newsletter'
import Beam from 'components/SVG/beam'
import FooterLaser from 'components/SVG/lasers/footer'
import canUseDOM from 'utils/canUseDOM'

import style from './footer.module.css'

const DynamicTwitter = lazy(() => import('components/Twitter'))

class Footer extends React.Component {
  observer = null

  container = null

  state = {
    isSuccessful: false,
    isVisible: false,
  }

  componentDidMount() {
    if (canUseDOM) {
      this.observer = new IntersectionObserver(
        entry => this.setState({ isVisible: entry[0].isIntersecting }),
        {
          rootMargin: '0px 0px 400px 0px',
          threshold: [0.25],
        },
      )

      this.observer.observe(this.container)
    }
  }

  componentWillUnmount = () => {
    if (!isEmpty(this.observer)) {
      this.observer.disconnect()
    }
  }

  handleOnSubmit = async event => {
    try {
      const res = await newsletter({ ...event })

      if (200 === res.status) {
        return this.renderSubmission()
      }

      return console.error(res.data.message || 'Unknown Error.')
    } catch (error) {
      return console.error(error)
    }
  }

  renderSubmission = () => {
    this.setState({
      isSuccessful: true,
    })
  }

  handleTwitter = () => {
    const { isVisible } = this.state

    if (isVisible && canUseDOM) {
      if (!this.observer) return null

      this.observer.disconnect()
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <DynamicTwitter />
        </Suspense>
      )
    }

    return null
  }

  render() {
    const {
      state: { isSuccessful },
      props: { darkBackground, social, ctaIntent },
    } = this

    return (
      <footer className={style.footer}>
        <Divider isReversed isDark={darkBackground}>
          <FooterLaser className={style.laser} />
        </Divider>
        <div className={style.links}>
          <Beam className={style.linksBeam} />
          <ul className={style.navLinks}>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/partners">Partners</Link>
            </li>
            <li>
              <Link to="/aws">AWS Services</Link>
            </li>
            <li>
              <Link to="/tools/secret-awsome/">Tools</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.linkedin.com/jobs/search/?f_C=11369737&locationId=OTHERS.worldwide"
              >
                Careers
              </a>
            </li>
          </ul>
        </div>
        <div className={style.newsletter}>
          <h3>Get the latest news</h3>
          {isSuccessful ? (
            <div data-testid="footer-success-message" className={style.thankYou}>
              Thanks for signing up for our newsletter!
            </div>
          ) : (
            <HubspotTracking>
              {({ hsContext }) => (
                <FinalForm
                  validateOnBlur
                  onSubmit={e => this.handleOnSubmit({ ...e, hsContext })}
                  initialValues={{ ctaIntent }}
                >
                  {({ handleSubmit, pristine, submitting }) => (
                    <form className={style.signupForm} onSubmit={handleSubmit}>
                      <Field name="email" placeholder="E-mail Address" validate={isEmail}>
                        {({ input, meta, placeholder }) => (
                          <div className={style.inputContainer}>
                            <input
                              {...input}
                              placeholder={placeholder}
                              id={`${input.name}-footer`}
                              type="email"
                              className={style.emailAddress}
                              aria-label="Enter email to subscribe to newsletter"
                            />
                            {meta.error && meta.touched && (
                              <span
                                data-testid="footer-email-error"
                                className={style.validationError}
                              >
                                {meta.error}
                              </span>
                            )}
                            <Button
                              variant="secondary"
                              shadow={false}
                              label="Join"
                              className={style.buttonCTA}
                              disabled={pristine || submitting}
                            />
                          </div>
                        )}
                      </Field>
                      <Field name="ctaIntent" type="hidden">
                        {({ input }) => <input {...input} type="hidden" />}
                      </Field>
                    </form>
                  )}
                </FinalForm>
              )}
            </HubspotTracking>
          )}
          <p className={style.contactInfo}>
            <a
              rel="noopener noreferrer"
              href="mailto:info@observian.com"
              aria-label="Send email to Observian"
            >
              info@observian.com
            </a>
            <a
              className={style.phone}
              rel="noopener noreferrer"
              href="tel:801-895-3219"
              aria-label="Call Observian"
            >
              801-895-3219
            </a>
          </p>
          <a
            className={style.address}
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.google.com/maps/place/Observian,+Inc./@40.7400222,-111.9863894,17z/data=!3m1!4b1!4m5!3m4!1s0x8752f3110eb2c4db:0x744729f4723d085e!8m2!3d40.7400182!4d-111.9842007"
            aria-label="Open Observian maps location in new tab"
          >
            1338 South Gustin Road
            <br />
            Salt Lake City, UT 84104
          </a>
        </div>

        <div
          className={style.twitter}
          ref={twitter => {
            this.container = twitter
          }}
        >
          {this.handleTwitter()}
        </div>

        <div className={style.social}>
          <div className={style.socialLogos}>
            <a
              rel="noopener noreferrer"
              href="https://www.observian.com"
              aria-label="Observian logo link"
            >
              <Logo
                showText
                colorLogo
                className={style.logoContainer}
                classNames={{ logo: style.logo, text: style.text }}
              />
            </a>
            <div className={style.icons}>
              {map(social, (url, type) => (
                <Social
                  key={`social:${type}`}
                  type={type}
                  url={url}
                  className={style.icon}
                  color="#F6FBFA"
                />
              ))}
            </div>
          </div>
          <p className={style.socialInfo}>
            <a
              className={style.socialLink}
              rel="noopener noreferrer"
              href="https://www.observian.com"
            >
              Observian Inc.
            </a>{' '}
            is an{' '}
            <a
              className={style.socialLink}
              rel="noopener noreferrer"
              target="_blank"
              href="https://aws.amazon.com/partners/find/partnerdetails/?n=Observian%2C%20Inc.&id=0010L00001kY4bTQAS"
            >
              Advanced AWS Partner
            </a>{' '}
            based out of Salt Lake City, Utah.
          </p>
          <div className={style.legal}>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <div>|</div>
            <Link to="/terms-of-service">Terms of Service</Link>
          </div>
          <Logo colorLogo className={style.backgroundLogo} classNames={{ logo: style.bgLogo }} />
        </div>
      </footer>
    )
  }
}

Footer.defaultProps = {
  darkBackground: false,
  social: {
    instagram: 'https://www.instagram.com/observianhq',
    facebook: 'https://www.facebook.com/observian',
    twitter: 'https://twitter.com/observianhq',
  },
  ctaIntent: 'footer-newsletter',
}

Footer.propTypes = {
  darkBackground: PropTypes.bool,
  social: PropTypes.shape({
    instagram: PropTypes.string,
    facebook: PropTypes.string,
    twitter: PropTypes.string,
  }),
  ctaIntent: PropTypes.string,
}

export default Footer
