import React from 'react'
import { gsap, Power0 } from 'gsap'

import Layout from 'components/Layout'
import AWSPageTemplate from 'templates/Page/AWS'
import DevOps from 'components/SVG/clouds/devOps'

class DevOpsPage extends React.Component {
  componentDidMount() {
    const devOps = gsap.timeline()
    devOps.to('.cog1', {
      rotation: 360,
      transformOrigin: '50% 50%',
      repeat: -1,
      ease: Power0.easeNone,
      duration: 6,
    })

    devOps.to(
      '.cog2',
      { rotation: 360, transformOrigin: '50% 50%', repeat: -1, ease: Power0.easeNone, duration: 5 },
      0,
    )

    devOps.to(
      '.cog3',
      {
        rotation: -360,
        transformOrigin: '50% 50%',
        repeat: -1,
        ease: Power0.easeNone,
        duration: 3,
      },
      0,
    )

    devOps.to(
      '.cog4',
      {
        rotation: -360,
        transformOrigin: '50% 50%',
        repeat: -1,
        ease: Power0.easeNone,
        duration: 2,
      },
      0,
    )
  }

  render() {
    return (
      <Layout
        title="DevOps Services Utah | AWS Services"
        description="Observian helps companies adopt new strategies that allow their Software Engineers to focus on problem-solving rather than delivering code. Learn more!"
      >
        <AWSPageTemplate
          name="AWS DevOps Services"
          title="Automation Through AWS DevOps"
          content="
            <p>
            Technology is constantly evolving. One area of evolution is in DevOps, where small teams of engineers have become critically important to how well a variety of companies compete. Observian helps companies adopt new strategies that allow their Software Engineering teams to focus on solving business problems rather than delivering code.
            </p>
            <br />
            <p>
            Downtime and repetition cost money. The solution: Automation through Continuous Integration (CI) and Continuous Delivery (CD).
            </p>
            <br />
            <p>
            Continuous integration (CI) is used to establish an automated way of building, packaging, and testing applications. This means a build is automatically triggered when a developer checks in code, leading to more frequent code changes. The principle behind this is to help boost better collaboration and quality of product.
            </p>
            <br />
            <p>
            Continuous delivery (CD) takes this idea further: after a build and automated tests are successful, the application is automatically deployed to an environment where more in-depth testing is done. This automation helps the process become more efficient and allows teams to be more confident in product delivery.
            </p>
            <br />
            <p>
            Using AWS DevOps, we’ll help you minimize the cost of maintaining a test environment because you only pay for the environment resources if you're using them. Your CD process can set up the test environment when you need it, and you can take down the environment when you're done.
            </p>
            <br />
            <p>
          Observian partners with Orchestration companies, such as <a href='/partners/docker'>Docker</a>, whose tools allow you automate tasks within a cohesive workflow, follow permissions and policy enforcement, and meet critical team goals.
            </p>
            <br />
            <ul>
              <li>Continuous integration, delivery, automation, testing, monitoring, and collaboration.</li>
              <li>Increase collaboration, flexibility, and productivity between IT Teams.</li>
              <li>Speed up fixes and go-to-market processes.</li>
              <li>Shorten testing and delivery cycles.</li>
              <li>Streamline operations, better allocate resources, and save money.</li>
            </ul>
            <br />
            <p>
            Traditional development is a complicated process. The larger a project is, the more difficult and complex these processes can be. Observian provides expert AWS DevOps consulting services to allow your team to more efficiently automate their workflow. Speak with one of our consultants today to see how you can simplify your development process!
            </p>"
          headerIcon={<DevOps />}
          inquiryPlaceholder="Hi! We could use some help with integrating DevOps into our organization..."
        />
      </Layout>
    )
  }
}

export default DevOpsPage
