import axios from 'axios'

import { env, BASE_ENV_URL, headers } from './helper'

const formId =
  'prod' === env() ? '6d764ca9-7445-4c76-9ac5-6a83d1fd31b8' : '10cdad2a-1f10-4cb2-801f-74e71ae2e313'

const subscribe = async data => {
  try {
    return await axios({
      method: 'POST',
      url: `${BASE_ENV_URL}/newsletter`,
      headers: headers({
        'Content-Type': 'application/json',
      }),
      data: { ...data, formId, env: env() },
    })
  } catch (error) {
    return console.error(error.message || 'Unable to create subscription')
  }
}

export default subscribe
