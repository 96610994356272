import axios from 'axios'

import { env, headers, params } from './helper'

const BASE_URL = `https://api.observian.com/${env()}/blog`

export const getPosts = async (slug, state = 'PUBLISHED') => {
  try {
    return await axios({
      method: 'GET',
      headers: headers(),
      url: `${BASE_URL}/posts`,
      params: params({ slug, state }),
    })
  } catch (error) {
    return new Error(error.message || 'Unable to fetch posts')
  }
}

export const getAuthors = async authorId => {
  try {
    return await axios({
      method: 'GET',
      headers: headers(),
      url: `${BASE_URL}/authors`,
      params: params({ authorId }),
    })
  } catch (error) {
    return new Error(error.message || 'Unable to fetch authors')
  }
}

export const getTopics = async () => {
  try {
    return await axios({
      method: 'GET',
      headers: headers(),
      url: `${BASE_URL}/topics`,
      params: params({}),
    })
  } catch (error) {
    return new Error(error.message || 'Unable to fetch topics')
  }
}

export const getComments = async ({ postId, state = 'APPROVED', reverse = true }) => {
  try {
    return await axios({
      method: 'GET',
      headers: headers(),
      url: `${BASE_URL}/comments`,
      params: params({ postId, state, reverse }),
    })
  } catch (error) {
    return new Error(error.message || 'Unable to fetch comments')
  }
}

export const createComment = async data => {
  try {
    return await axios({
      method: 'POST',
      url: `${BASE_URL}/comments`,
      headers: headers({
        'Content-Type': 'application/json',
      }),
      data: {
        env: env(),
        comment: data.comment,
        contentId: data.postId,
        collectionId: data.blogId,
        contentAuthorEmail: data.authorEmail,
        contentAuthorName: data.authorName,
        contentPermalink: data.blogUrl,
        contentTitle: data.postTitle,
        userEmail: data.email,
        userName: data.fullName,
        replyingTo: data.threadId,
      },
    })
  } catch (error) {
    return new Error(error.message || 'Unable to create comment')
  }
}

export default getPosts
