import React from 'react'
import Button from 'observian-ui/lib/button'
import ContactModal from 'observian-ui/lib/modal/contact'
import Modal from 'observian-ui/lib/modal'

import ContactForm from 'components/Form/Contact'
import TextUnderline from 'components/SVG/textUnderline'

import style from './whyGoogle.module.css'
import products from './googleProducts'

class WhyGoogle extends React.Component {
  state = {
    showModal: false,
    showVideo: false,
    isSuccessful: false,
  }

  handleIsSuccessful = value => {
    this.setState({ isSuccessful: value })
  }

  handleModal = key => {
    this.setState(state => ({
      [key]: !state[key],
    }))
  }

  render() {
    const { showModal, isSuccessful, showVideo } = this.state

    return (
      <section className={style.container}>
        <div className={style.content}>
          <header className={style.header}>
            <h2>Why G Suite Products?</h2>
            <TextUnderline />
          </header>
          <img
            role="presentation"
            className={style.videoImage}
            src="/images/partners/google-partners-video.png"
            alt="Google Partners"
            onClick={() => this.handleModal('showVideo')}
          />
          {showVideo ? (
            <Modal
              isOpen={showVideo}
              onClose={() => this.handleModal('showVideo')}
              onDismiss={() => this.handleModal('showVideo')}
            >
              <iframe
                allowFullScreen
                title="Google Partners"
                className={style.video}
                width="560"
                height="315"
                src="https://www.youtube.com/embed/ZLPmgcpi0wI?autoplay=1"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              />
            </Modal>
          ) : null}
          <p className={style.description}>
            Streamlining your business practices and productivity is simple with Observian’s G Suite
            migration. With a wide variety of helpful tools, G Suite products can help in almost
            every aspect of your business. From compiling data to creating vibrant presentations, G
            Suite has it all. Check out the useful tools that Observian can offer as a Google cloud
            partner.
          </p>
          <Button
            className={style.button}
            label="Ready to be more productive?"
            onClick={() => this.handleModal('showModal')}
          />
        </div>

        {showModal ? (
          <ContactModal
            showClose
            shouldCloseOnOverlayClick
            onClose={() => this.handleModal('showModal')}
            onDismiss={() => this.handleModal('showModal')}
            isOpen={showModal}
            closeButtonPosition="right"
            ariaHideApp={false}
            success={isSuccessful}
          >
            <ContactForm
              showLogo
              showContactInfo
              headerTitle="Contact Us"
              buttonLabel="Send"
              ctaIntentSuffix="modal-gsuite"
              inquiryPlaceholder="I'd love to learn more about this G Suite and Observian offer."
              isSuccessfulCallback={v => this.handleIsSuccessful(v)}
            />
          </ContactModal>
        ) : null}

        <div className={style.products}>
          {products.map(product => (
            <div className={style.product} key={`product:${product.name}`}>
              <img className={style.icon} src={product.icon} alt={product.name} />
              <div className={style.name}>{product.name}</div>
              <div className={style.productDescription}>{product.description}</div>
            </div>
          ))}
        </div>
      </section>
    )
  }
}

export default WhyGoogle
