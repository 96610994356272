import React from 'react'
import { gsap, Elastic } from 'gsap'
import { SplitText } from 'gsap/SplitText'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
import { Draggable } from 'gsap/Draggable'
import clsx from 'clsx'
import Divider from 'observian-ui/lib/divider'

import canUseDOM from 'utils/canUseDOM'

import style from './officeMigration.module.css'

const getRandom = () => Math.floor(Math.random() * 201) - 100
const calcPercent = ({ xPos, width }) => parseFloat((xPos / width).toFixed(2))
const calcSavings = ({ xPerc, maxUsers = 10000, savingsPer = 23 }) => maxUsers * xPerc * savingsPer
const getNumber = ({ minX, maxX }) => Math.floor(Math.random() * (maxX - minX)) + minX
const splitTextAnimation = element => {
  const numberX = element.chars.map(_char => getRandom()) // eslint-disable-line
  const numberY = element.chars.map(_char => getRandom()) // eslint-disable-line
  gsap.to(`.${style.savings}`, { opacity: 1, duration: 1.5 })
  gsap.to(element.chars, {
    opacity: 0,
    x: gsap.utils.wrap(numberX),
    y: gsap.utils.wrap(numberY),
    duration: 0.45,
    stagger: 0.02,
  })
}

class OfficeMigration extends React.Component {
  static splitText = () => {
    if (canUseDOM) {
      return new SplitText(`.${style.savings}`, { type: 'chars' }) // eslint-disable-line
    }

    return null
  }

  state = {
    isVisible: false,
  }

  componentDidMount = () => {
    if (canUseDOM) {
      gsap.registerPlugin(SplitText, DrawSVGPlugin, Draggable)

      const drag = document.getElementsByClassName(style.content)[0]
      this.observer = new IntersectionObserver(
        entry => this.setState({ isVisible: entry[0].isIntersecting }),
        {
          rootMargin: '0px 0px 0px 0px',
          threshold: [1],
        },
      )

      this.observer.observe(drag)

      gsap.set(`.${style.draggable}`, { overflow: 'visible' })
      gsap.set(`.${style.dragger}`, { x: '+=210' })
      gsap.set(`.${style.tooltip}`, { x: '+=210' })
      gsap.set(`.${style.activeLine}, .${style.activeLineGradient}`, { drawSVG: '36%' })

      const { width } = document.getElementsByClassName(style.line)[0].getBBox()

      const scopedThis = this

      /* eslint-disable-next-line */
      this.draggable = Draggable.create(`.${style.dragger}`, {
        type: 'x',
        bounds: { minX: 5, maxX: width },
        onDragStart() {
          const dir = this.getDirection()
          gsap.to(`.${style.tooltip}`, {
            rotation: 'left' === dir ? '-10' : '10',
            transformOrigin: '50% 50%',
            duration: 0.2,
          })

          const split = OfficeMigration.splitText()
          splitTextAnimation(split)
        },
        onDragEnd() {
          const percent = calcPercent({ xPos: this.endX, width })
          gsap.to(`.${style.tooltip}`, {
            rotation: '0',
            transformOrigin: '50% 50%',
            ease: Elastic.easeOut.config(1, 0.3),
            duration: 1.4,
          })

          gsap.to(`.${style.savings}`, { opacity: 1, duration: 0.5 })
          gsap.to(`.${style.activeLine}, .${style.activeLineGradient}`, {
            drawSVG: `${percent * 100}%`,
            duration: 0.5,
          })
          scopedThis.setSavings(calcSavings({ xPerc: percent }))
        },
        onDrag() {
          const users = parseInt((calcPercent({ xPos: this.x, width }) * 10000).toFixed(0), 10)
          document.getElementsByClassName(style.totalUsers)[0].innerHTML = `${users} `
          gsap.set(`.${style.tooltip}`, { x: this.x })
        },
      })[0]
    }
  }

  componentWillUnmount = () => {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    if (this.observer) {
      this.observer.disconnect()
    }
  }

  setSavings = (savings = 92000) => {
    const formattedSavings = savings
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    if (canUseDOM()) {
      this.timeout = setTimeout(() => {
        if (OfficeMigration.splitText()) {
          OfficeMigration.splitText().revert()
        }
        document.getElementsByClassName(style.savings)[0].innerHTML = `$${formattedSavings}`
        gsap.fromTo(
          `.${style.savings}`,
          { scale: 0.5 },
          { scale: 1, ease: Elastic.easeOut.config(1, 0.3), duration: 1.1 },
        )
      }, 250)
    }
  }

  handleSliderChange = () => {
    const { minX, maxX } = this.draggable.vars.bounds
    const newX = getNumber({ minX, maxX })
    const newPercent = calcPercent({ xPos: newX, width: maxX })

    const users = (newPercent * 10000).toFixed(0)
    document.getElementsByClassName(style.totalUsers)[0].innerHTML = `${users} `
    gsap.to(`.${style.tooltip}`, { x: newX, duration: 1 })
    gsap.to(`.${style.dragger}`, { x: `${newX}`, duration: 1 })
    gsap.to(`.${style.activeLine}, .${style.activeLineGradient}`, {
      drawSVG: `${newPercent * 100}%`,
      duration: 1,
    })

    this.setSavings(calcSavings({ xPerc: newPercent }))

    splitTextAnimation(OfficeMigration.splitText())

    gsap.to(`.${style.savings}`, { opacity: 1, onComplete: this.draggable.update, duration: 1 })
  }

  handleSlider = () => {
    this.setState({
      isVisible: false,
    })
  }

  render() {
    const { isVisible } = this.state

    if (isVisible && this.draggable) {
      this.handleSliderChange()
    }

    return (
      <section className={style.container}>
        <div className={style.mainWrapper}>
          <div className={style.sliderContentContainer}>
            <header className={style.header}>
              <h2 className={style.title}>Switching to G Suite Products from Office 365?</h2>
              <span className={style.description}>
                Use the slider to see how much you can save...
              </span>
            </header>
            <div
              className={style.content}
              role="presentation"
              onMouseOver={this.handleSlider}
              onFocus={this.handleSlider}
              onTouchStart={this.handleSlider}
            >
              <span className={style.userCount}>USER COUNT</span>
              <div className={style.drag}>
                <svg
                  className={style.draggable}
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="668"
                  height="131"
                  viewBox="0 0 668 131"
                >
                  <defs>
                    <linearGradient
                      x1="12.453%"
                      y1="15.924%"
                      x2="87.895%"
                      y2="16.33%"
                      id="slider-a1"
                    >
                      <stop stopColor="#1D8DBA" offset="0%" />
                      <stop stopColor="#03ECAE" offset="100%" />
                    </linearGradient>
                    <linearGradient
                      id="slider-a"
                      x1="12.453%"
                      x2="87.895%"
                      y1="84.924%"
                      y2="16.33%"
                    >
                      <stop offset="0%" stopColor="#1D8DBA" />
                      <stop offset="100%" stopColor="#03ECAE" />
                    </linearGradient>
                    <filter
                      id="slider-b"
                      width="111.9%"
                      height="3500%"
                      x="-5.9%"
                      y="-1700%"
                      filterUnits="objectBoundingBox"
                    >
                      <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
                    </filter>
                    <filter
                      id="slider-c"
                      width="422.6%"
                      height="422.6%"
                      x="-161.3%"
                      y="-122.6%"
                      filterUnits="objectBoundingBox"
                    >
                      <feMorphology
                        in="SourceAlpha"
                        operator="dilate"
                        radius="4"
                        result="shadowSpreadOuter1"
                      />
                      <feOffset dy="12" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
                      <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="10"
                      />
                      <feComposite
                        in="shadowBlurOuter1"
                        in2="SourceAlpha"
                        operator="out"
                        result="shadowBlurOuter1"
                      />
                      <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.213937953 0"
                      />
                    </filter>
                  </defs>
                  <g fill="none" transform="translate(-19 22)" className={style.draggerTotalUsers}>
                    <g transform="translate(78 13)" className={style.line}>
                      <path
                        className={style.steps}
                        stroke="#F6FBFA"
                        strokeDasharray="40 20"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                        strokeWidth="15"
                        d="M2.2772999,1.41407999 L583.265807,1.41407999"
                        opacity="0.119"
                      />
                      <path
                        className={style.activeLine}
                        stroke="url(#slider-a1)"
                        strokeLinecap="round"
                        strokeWidth="10"
                        d="M0.381299212,1.41407999 L584.621103,1.78105834"
                      />
                      <path
                        className={style.activeLineGradient}
                        fill="url(#slider-a)"
                        stroke="url(#slider-a1)"
                        strokeLinecap="round"
                        strokeWidth="10"
                        d="M0.381299212,12.375 L581.663498,12.375"
                        filter="url(#slider-b)"
                        opacity="0.509"
                      />
                    </g>
                    <g transform="translate(0 31)" className={style.tooltip}>
                      <path
                        fill="#131111"
                        fillOpacity=".074"
                        d="M104,0 C-9.33333333,52 -9.33333333,78 104,78 C217.333333,78 217.333333,52 104,0 Z"
                      />
                      <text
                        fill="#FFF"
                        fillOpacity="0.9"
                        fontFamily="Geomanist"
                        fontSize="21"
                        letterSpacing="0.8"
                      >
                        <tspan x="47.953" y="54.442" className={style.totalUsers}>
                          4000{' '}
                        </tspan>
                        <tspan>Users </tspan>
                      </text>
                    </g>
                    <g className={style.dragger}>
                      <circle
                        cx="103.5"
                        cy="15.5"
                        r="17.5"
                        fill="url(#slider-a)"
                        stroke="#1DECB6"
                        strokeWidth="4"
                      />
                      <circle
                        cx="103.5"
                        cy="15.5"
                        r="22.5"
                        stroke="#1DECB6"
                        strokeOpacity="0.218"
                        strokeWidth="14"
                      />
                      <circle
                        cx="103.5"
                        cy="15.5"
                        r="19.5"
                        stroke="#1DECB6"
                        strokeOpacity="0.178"
                        strokeWidth="8"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>

          <div className={style.savingsContainer}>
            <div className={style.savingsContent}>
              <span className={clsx(style.text, style.save)}>Save Up To</span>
              <span className={style.savings}>$92,000</span>
              <span className={style.spinner} />
              <span className={clsx(style.text, style.perYear)}>Per Year*</span>
            </div>
            <div className={style.info}>
              *Based on Microsoft Office 365 pricing of $35 per user and Google pricing of $12 per
              user.
            </div>
          </div>
        </div>
        <Divider />
      </section>
    )
  }
}

OfficeMigration.defaultProps = {}

OfficeMigration.propTypes = {}

export default OfficeMigration
