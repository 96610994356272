import React from 'react'

import Layout from 'components/Layout'
import {
  Comparison,
  CurrentCustomer,
  Hero,
  OfficeMigration,
  WhyGoogle,
} from 'components/Page/Google/GSuite'

export default () => (
  <Layout
    title="G Suite"
    description="Observian has partnered with Google to bring our customers best in class productivity and collaboration with G Suite. See how these amazing tools from Google paired with our expertise can help transform your business."
    keywords="google,google partner,gsuite,g suite,google cloud partner,gsuite products,g suite products,gsuite migration,g suite migration"
  >
    <Hero />
    <WhyGoogle />
    <CurrentCustomer />
    <OfficeMigration />
    <Comparison />
  </Layout>
)
