import React from 'react'
import PropTypes from 'prop-types'
import PostCard from 'observian-ui/lib/post-card'

import style from './otherPosts.module.css'

const OtherPosts = ({ post, posts, topics }) => {
  const allOtherPosts = posts.filter(currentPost => currentPost.slug !== post.slug)

  return (
    <div className={style.otherPosts}>
      <h2 className={style.header}>Other Articles</h2>
      <div className={style.content}>
        {allOtherPosts.slice(0, 3).map(currentPost => {
          const {
            featuredImage: image,
            name: title,
            metaDescription: description,
            slug,
          } = currentPost
          return (
            <PostCard
              type="blog"
              key={`blog:post:card:${currentPost.postId}`}
              image={image}
              title={title}
              slug={slug}
              description={description}
              topics={topics}
              avatarProps={{ forceAspectRatio: currentPost.blogAuthor.isExternalAuthor }}
            />
          )
        })}
      </div>
    </div>
  )
}

OtherPosts.defaultProps = {}

OtherPosts.propTypes = {
  post: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  posts: PropTypes.arrayOf(PropTypes.object).isRequired,
  topics: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default OtherPosts
