import React from 'react'
import PropTypes from 'prop-types'

const Blog = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="780"
    height="103"
    viewBox="0 0 780 103"
  >
    <defs>
      <linearGradient id="blog-laser-a" x1="15.436%" x2="114.319%" y1="90.518%" y2="21.413%">
        <stop offset="0%" stopColor="#FFC42A" />
        <stop offset="100%" stopColor="#FF0061" stopOpacity="0.946" />
      </linearGradient>
      <filter
        id="blog-laser-b"
        width="107.8%"
        height="200%"
        x="-3.9%"
        y="-50%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
    </defs>
    <g fill="url(#blog-laser-a)" fillRule="evenodd" transform="translate(7)">
      <polygon
        points="1.462 51.947 340.327 65.785 772.462 20.722 280.56 80.722"
        filter="url(#blog-laser-b)"
        opacity="0.418"
        transform="rotate(1 386.962 50.722)"
      />
      <polygon points="1 31.226 339.865 45.063 772 0 280.098 60" />
    </g>
  </svg>
)

Blog.defaultProps = {
  className: '',
}

Blog.propTypes = {
  className: PropTypes.string,
}

export default Blog
