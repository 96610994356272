import React from 'react'
import PropTypes from 'prop-types'

const BigData = ({ className, outline, textClassName, particleClassName }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="1069"
    height="559"
    viewBox="0 0 1069 559"
  >
    <defs>
      <linearGradient id="bigdatabg-a" x1="12.453%" x2="87.895%" y1="84.924%" y2="16.33%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(-1 -33)">
      <g fill="#DCE1E0" opacity="0.48" transform="translate(0 46)">
        <polygon
          className={outline.topLine}
          points=".926 202.699 82.41 34.57 262 0 90.309 44.711"
          opacity="0.5"
        />
        <polygon
          className={outline.bottomLine}
          points="618.965 545.648 875.656 472.637 1069.609 290.465 893.801 483.828"
          opacity="0.5"
        />
      </g>
      <text
        className={textClassName.big}
        fill="#DCE1E0"
        fontFamily="GeomanistUltra, Geomanist"
        fontSize="250"
        fontWeight="600"
        opacity="0.5"
      >
        <tspan x="161.073" y="330">
          BIG
        </tspan>
      </text>
      <text
        className={textClassName.data}
        fill="#DCE1E0"
        fontFamily="GeomanistUltra, Geomanist"
        fontSize="250"
        fontWeight="600"
        opacity="0.5"
      >
        <tspan x="161.073" y="530">
          DATA
        </tspan>
      </text>
      <g
        fill="url(#bigdatabg-a)"
        stroke="url(#bigdatabg-a)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        opacity="0.25"
        transform="rotate(-18 457.738 71.55)"
      >
        <polygon
          className={particleClassName.part}
          points="97.064 62.41 106.027 76.54 88.102 76.54"
          transform="rotate(-13 97.064 69.475)"
        />
        <polygon
          className={particleClassName.part1}
          points="457.995 39.866 469.07 57.667 446.921 57.667"
          transform="rotate(-95 457.995 48.766)"
        />
        <polygon
          className={particleClassName.part2}
          points="4.641 246.938 8.302 252.965 .98 252.965"
          transform="rotate(-58 4.641 249.952)"
        />
        <polygon
          className={particleClassName.part3}
          points="366.619 2.472 371.961 11.265 361.276 11.265"
          transform="rotate(-26 366.619 6.868)"
        />
      </g>
    </g>
  </svg>
)

BigData.defaultProps = {
  className: '',
  outline: {
    topLine: 'topLine',
    bottomLine: 'bottomLine',
  },
  textClassName: {
    big: 'big',
    data: 'data',
  },
  particleClassName: {
    part: 'part',
    part1: 'part1',
    part2: 'part2',
    part3: 'part3',
  },
}

BigData.propTypes = {
  className: PropTypes.string,
  outline: PropTypes.shape({
    topLine: PropTypes.string,
    bottomLine: PropTypes.string,
  }),
  textClassName: PropTypes.shape({
    big: PropTypes.string,
    data: PropTypes.string,
  }),
  particleClassName: PropTypes.shape({
    part: PropTypes.string,
    part1: PropTypes.string,
    part2: PropTypes.string,
    part3: PropTypes.string,
  }),
}

export default BigData
