import React from 'react'
import PropTypes from 'prop-types'

const CVELogo = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="126"
    height="59"
    viewBox="0 0 126 59"
  >
    <g fill="#79242F">
      <path d="M52.2385043,10.9676754 C47.2127478,14.4062174 41.8911652,17.0438318 36.7054435,20.3224673 C35.6766261,20.1118318 34.9228174,19.1656262 33.6266609,18.773028 C25.9910609,16.4571402 19.205687,21.8432336 18.5143304,28.0685607 C17.7166957,35.236785 23.2935652,40.5953084 30.1282435,40.181757 C35.5670609,39.8509159 38.9427652,37.0497944 41.8824,33.8439439 C44.8614783,39.0613084 47.7737217,44.3448411 50.6980174,49.6140374 C48.5724522,53.1893271 45.2493391,55.0773271 41.6041043,56.6565421 C37.7989043,58.3041308 33.3341217,59.2205607 27.7506783,58.9095701 C12.5057739,58.0593084 -0.349513043,45.317514 0.043826087,28.9133084 C0.357182609,15.8450841 8.04208696,7.18807477 17.2554261,2.71620561 C20.4700696,1.15463551 24.7847478,0.114691589 29.4292174,0.0397009346 C40.32,-0.133439252 47.1557739,4.96511463 52.2385043,10.9676754 Z" />
      <path d="M90.1601217,2.1537757 L125.56393,2.1537757 L125.56393,18.773028 L101.079391,18.773028 C100.099878,19.5714579 99.5137043,20.7657944 98.9812174,22.0130654 L125.987948,22.0130654 L125.987948,36.6605047 L91.2798783,36.6605047 C90.3069391,37.5581869 89.7624,38.889271 89.0403652,40.0405981 L125.98247,40.0405981 L125.98247,57.6468598 L58.1155826,57.6468598 C51.3137739,46.1368972 44.8910609,34.2431589 38.3850783,22.4354393 C43.5598435,19.5681495 48.5242435,16.4902243 53.6376522,13.5622804 C58.8233739,21.3921869 63.5982261,29.6411589 68.4705913,37.7875701 C75.9089739,26.1188037 82.8050087,13.9052523 90.1601217,2.1537757 Z" />
    </g>
  </svg>
)

CVELogo.defaultProps = {
  className: '',
}

CVELogo.propTypes = {
  className: PropTypes.string,
}

export default CVELogo
