const getEnv = () =>
  (process && process.env && process.env.GATSBY_ACTIVE_ENV) || process.env.NODE_ENV || 'development'

const isDev = () => 'development' === getEnv()
const isTest = () => 'test' === getEnv()
const isProd = () => 'production' === getEnv()
const isStaging = () => 'staging' === getEnv()
const isBeta = () => 'beta' === getEnv()

export default getEnv
export { isDev, isTest, isProd, isStaging, isBeta }
