import React from 'react'
import PropTypes from 'prop-types'

const TextUnderline = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="169"
    height="8"
    viewBox="0 0 169 8"
  >
    <defs>
      <linearGradient id="text-underline-a" x1="-5.302%" x2="52.302%" y1="178.507%" y2="-48.719%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
    </defs>
    <polygon
      fill="url(#text-underline-a)"
      fillRule="evenodd"
      points="0 0 73.547 2.313 168.91 6.156 73.953 5.345 5.153 7.655"
    />
  </svg>
)

TextUnderline.defaultProps = {
  className: '',
}

TextUnderline.propTypes = {
  className: PropTypes.string,
}

export default TextUnderline
