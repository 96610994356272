import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'clsx'
import Contact from 'observian-ui/lib/svg/icons/contact'
import Button from 'observian-ui/lib/button'
import Logo from 'observian-ui/lib/logo'
import { Form as FinalForm, Field } from 'react-final-form'
import omit from 'lodash/omit'

import { submitAppDForm } from 'api/form'
import HubspotTracking from 'components/Hubspot/Tracking'
import { isEmail, isRequired } from 'utils/validation'

import style from './contactFormAppD.module.css'

const ContactFormAppD = ({
  className,
  headerTitle,
  showLogo,
  buttonLabel,
  ctaIntent,
  isSuccessfulMessage,
  ctaIntentSuffix,
  isSuccessfulCallback,
}) => {
  const [isSuccessful, setIsSuccessful] = useState(false)

  const handleOnSubmit = async event => {
    const [firstName, lastName = ''] = event.fullName.split(' ')
    const ctaIntentValue =
      0 === ctaIntentSuffix.length ? event.ctaIntent : `${event.ctaIntent}-${ctaIntentSuffix}`

    const validEvent = omit(event, 'fullName')
    const allFormData = {
      ...validEvent,
      firstName,
      lastName,
      ctaIntent: ctaIntentValue,
      formId: '7b0df8b9-9e64-4a4e-a720-c5fc1db581db',
    }

    try {
      const res = await submitAppDForm({ ...allFormData })

      if (200 === res.status) {
        return renderSubmission()
      }

      return console.error(res.data.message || 'Unknown Error.')
    } catch (error) {
      return console.error(error)
    }
  }

  const renderSubmission = (value = true) => {
    handleCallback(value)
    setIsSuccessful(value)
  }

  const handleOnDismiss = () => {
    return renderSubmission(false)
  }

  const handleCallback = (value = false) => {
    if (isSuccessfulCallback) {
      return isSuccessfulCallback(value)
    }

    return null
  }

  return (
    <HubspotTracking>
      {({ hsContext }) => (
        <>
          {!isSuccessful ? (
            <FinalForm
              validateOnBlur
              onSubmit={e => handleOnSubmit({ ...e, hsContext })}
              initialValues={{ ctaIntent }}
            >
              {({ handleSubmit, pristine, submitting }) => (
                <form className={cn(style.form, className)} onSubmit={handleSubmit}>
                  <header className={style.header}>
                    <h3>{headerTitle}</h3>
                    {showLogo ? (
                      <Logo colorLogo className={style.logo} data-testid="form-logo" />
                    ) : null}
                  </header>
                  <div className={style.contactForm}>
                    <Field name="fullName" placeholder="Full Name" validate={isRequired}>
                      {({ input, meta, placeholder }) => (
                        <div className={style.inputContainer}>
                          <Contact type="person" className={style.inputIcon} />
                          <input {...input} placeholder={placeholder} id={input.name} />
                          {meta.error && meta.touched && (
                            <span
                              data-testid="form-fullName-error"
                              className={style.validationError}
                            >
                              {meta.error}
                            </span>
                          )}
                        </div>
                      )}
                    </Field>
                    <Field name="email" placeholder="email@example.com" validate={isEmail}>
                      {({ input, meta, placeholder }) => (
                        <div className={style.inputContainer}>
                          <Contact type="email-alt" className={style.inputIcon} />
                          <input
                            {...input}
                            placeholder={placeholder}
                            id={input.name}
                            type="email"
                          />
                          {meta.error && meta.touched && (
                            <span data-testid="form-email-error" className={style.validationError}>
                              {meta.error}
                            </span>
                          )}
                        </div>
                      )}
                    </Field>
                    <Field name="company" placeholder="Company Name" validate={isRequired}>
                      {({ input, meta, placeholder }) => (
                        <div className={style.inputContainer}>
                          <Contact type="building" className={style.inputIcon} />
                          <input {...input} placeholder={placeholder} id={input.name} />
                          {meta.error && meta.touched && (
                            <span
                              data-testid="form-company-error"
                              className={style.validationError}
                            >
                              {meta.error}
                            </span>
                          )}
                        </div>
                      )}
                    </Field>
                    <Field name="ctaIntent" type="hidden">
                      {({ input }) => <input {...input} type="hidden" />}
                    </Field>
                  </div>
                  <div className={style.actions}>
                    <Button
                      type="primary"
                      label={buttonLabel}
                      className={style.buttonCTA}
                      disabled={pristine || submitting}
                    />
                  </div>
                </form>
              )}
            </FinalForm>
          ) : (
            <div className={style.success}>
              <span data-testid="form-success-message">{isSuccessfulMessage}</span>
              <Button
                type="cancel"
                label="Done"
                onClick={() => handleOnDismiss()}
                className={style.successButtonCTA}
              />
            </div>
          )}
        </>
      )}
    </HubspotTracking>
  )
}

ContactFormAppD.defaultProps = {
  className: '',
  headerTitle: 'Offer Registration',
  showLogo: false,
  buttonLabel: 'Get Free Licenses',
  isSuccessfulMessage: 'Thank you for registering, you will recieve an email confirmation shortly.',
  ctaIntent: 'contact-appd-form',
  ctaIntentSuffix: '',
  isSuccessfulCallback: () => {},
}

ContactFormAppD.propTypes = {
  className: PropTypes.string,
  headerTitle: PropTypes.string,
  showLogo: PropTypes.bool,
  buttonLabel: PropTypes.string,
  isSuccessfulMessage: PropTypes.string,
  ctaIntent: PropTypes.string,
  ctaIntentSuffix: PropTypes.string,
  isSuccessfulCallback: PropTypes.func,
}

export default ContactFormAppD
