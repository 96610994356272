import React from 'react'
import cn from 'clsx'
import { gsap, Elastic } from 'gsap'
import { Physics2DPlugin } from 'gsap/Physics2DPlugin'
import debounce from 'lodash/debounce'
import times from 'lodash/times'
import Logo from 'observian-ui/lib/logo'
import Button from 'observian-ui/lib/button'
import Divider from 'observian-ui/lib/divider'
import ContactModal from 'observian-ui/lib/modal/contact'

import GoogleForm from 'components/Form/Google'
import canUseDOM from 'utils/canUseDOM'

import style from './hero.module.css'

class Hero extends React.Component {
  state = {
    showModal: false,
  }

  handleIsSuccessful = value => {
    this.setState({ isSuccessful: value })
  }

  handleModal = () => {
    this.setState(state => ({
      showModal: !state.showModal,
      isSuccessful: false,
    }))
  }

  componentDidMount = () => {
    if (canUseDOM) {
      gsap.registerPlugin(Physics2DPlugin)
      const tl = gsap.timeline()
      const confettiElement = document.querySelector(`.${style.confetti}`)
      const confettiContainer = document.createElement('div')
      confettiContainer.className = style.confettiContainer
      confettiElement.appendChild(confettiContainer)

      const clearItems = () => {
        while (confettiContainer.firstChild) {
          confettiContainer.removeChild(confettiContainer.firstChild)
        }
      }
      gsap.set(`.${style.image}`, { perspective: 400 })

      this.timeout = setTimeout(() => {
        gsap.set(`.${style.image}`, { opacity: 1 })
        gsap.from(`.${style.image}`, {
          x: '-=550',
          y: '-=120',
          rotationX: -90,
          rotationY: 20,
          opacity: 0,
          ease: Elastic.easeOut.config(1, 1),
          duration: 3,
          stagger: 0.07,
        })
      }, 1900)

      const clearConfetti = debounce(clearItems, 3000, { leading: false, trailing: true })

      const confetti = () => {
        const dots = gsap.timeline({ onStart: clearConfetti })

        const colors = ['#04EAAE', '#1B92B9', '#1D354F', '#2EFFF8', '#F7FBFB']

        times(45, () => {
          const dot = document.createElement('div')
          dot.className = style.dot
          confettiContainer.appendChild(dot)
          const color = colors[(Math.random() * colors.length) | 0] // eslint-disable-line

          gsap.set(dot, {
            backgroundColor: color,
            rotation: Math.floor(Math.random() * 160) + 1,
            width: Math.floor(Math.random() * 16) + 4,
            height: Math.floor(Math.random() * 16) + 4,
            x: Math.floor(Math.random() * 16),
            y: '+=40',
          })

          dots.to(
            dot,
            {
              rotation: Math.floor(Math.random() * 360) + 1,
              physics2D: {
                velocity: Math.floor(Math.random() * 500) + 100,
                angle: Math.floor(Math.random() * 360) + 1,
                gravity: 700,
              },
              opacity: 0,
              duration: 5,
            },
            0.05,
          )
        })
        return dots
      }

      tl.fromTo(`.${style.contentItem}`, { opacity: 0 }, { opacity: 1, duration: 1, stagger: 0.4 })
      tl.fromTo(
        `.${style.confetti}, .${style.thumb}`,
        { scale: 0, transformOrigin: '50% 50%', opacity: 0 },
        {
          opacity: 1,
          scale: 1,
          ease: Elastic.easeOut.config(1, 0.7),
          onStart: confetti,
          duration: 1.25,
        },
        '-=1',
      )
      tl.to(`.${style.title}, .${style.description}, .${style.button}`, { opacity: 1, duration: 1 })
    }
  }

  componentWillUnmount = () => {
    if (this.timeout) {
      clearTimeout(this.timeout.id)
    }
  }

  render() {
    const { showModal, isSuccessful } = this.state

    return (
      <section className={style.hero}>
        <div className={style.container}>
          <div className={style.content}>
            <header className={style.header}>
              <img
                className={cn(style.contentItem, style.gsuite)}
                src="/images/logos/google/gsuite/gsuite.png"
                alt="GSuite"
              />
              <span className={cn(style.contentItem, style.add)}>+</span>
              <Logo className={cn(style.contentItem, style.logo)} />
              <span className={cn(style.contentItem, style.equals)}> = </span>
              <div className={style.contentItem}>
                <div className={style.confetti} />
                <img className={style.thumb} alt="Thumb Emoji" src="/images/thumb.png" />
                <svg
                  className={style.thumbBG}
                  width="116px"
                  height="123px"
                  viewBox="0 0 116 123"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <defs>
                    <linearGradient
                      x1="12.4534884%"
                      y1="84.9243045%"
                      x2="87.8953488%"
                      y2="16.3295925%"
                      id="thumbEmojiBG"
                    >
                      <stop stopColor="#1D8DBA" offset="0%" />
                      <stop stopColor="#03ECAE" offset="100%" />
                    </linearGradient>
                  </defs>
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    opacity="0.289248512"
                  >
                    <g
                      transform="translate(-471.000000, -265.000000)"
                      fill="url(#thumbEmojiBG)"
                      fillRule="nonzero"
                    >
                      <g transform="translate(471.000000, 265.000000)">
                        <polygon points="37.5605469 41.7484304 12.2792969 41.7484304 24.9199219 60.1510051 0.91015625 84.213855 32.8730469 88.882459 32.8730469 123.023553 76.3339844 96.6850861 111.083984 96.6850861 72.4667969 72.2844662 115.433594 39.7129531 72.4667969 43.4166951 76.3339844 0.310770615" />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </header>
            <h1 className={style.title}>Observian is a Google Partner</h1>
            <p className={style.description}>
              Observian has partnered with Google to bring our customers best in class productivity
              with G Suite at a lower rate. Observian also provides customers with free training and
              support from G Suite experts. Submit for a free quote today!
            </p>
            <Button className={style.button} label="Get a Quote" onClick={this.handleModal} />
            {showModal ? (
              <ContactModal
                showClose
                shouldCloseOnOverlayClick
                onClose={this.handleModal}
                onDismiss={this.handleModal}
                isOpen={showModal}
                closeButtonPosition="right"
                ariaHideApp={false}
                success={isSuccessful}
              >
                <GoogleForm />
              </ContactModal>
            ) : null}
          </div>
          <div className={style.images}>
            <img
              alt="admin"
              src="/images/logos/google/gsuite/admin.png"
              className={cn(style.image, style.admin)}
            />
            <img
              alt="calendar"
              src="/images/logos/google/gsuite/calendar85.png"
              className={cn(style.image, style.calendar)}
            />
            <img
              alt="contacts"
              src="/images/logos/google/gsuite/contacts65.png"
              className={cn(style.image, style.contacts)}
            />
            <img
              alt="docs"
              src="/images/logos/google/gsuite/docs.png"
              className={cn(style.image, style.docs)}
            />
            <img
              alt="drive"
              src="/images/logos/google/gsuite/drive.png"
              className={cn(style.image, style.drive)}
            />
            <img
              alt="forms"
              src="/images/logos/google/gsuite/forms60.png"
              className={cn(style.image, style.forms)}
            />
            <img
              alt="gmail"
              src="/images/logos/google/gsuite/gmail.png"
              className={cn(style.image, style.gmail)}
            />
            <img
              alt="groups"
              src="/images/logos/google/gsuite/groups40.png"
              className={cn(style.image, style.groups)}
            />
            <img
              alt="hangouts"
              src="/images/logos/google/gsuite/hangouts.png"
              className={cn(style.image, style.hangouts)}
            />
            <img
              alt="keep"
              src="/images/logos/google/gsuite/keep30.png"
              className={cn(style.image, style.keep)}
            />
            <img
              alt="meet"
              src="/images/logos/google/gsuite/meet50.png"
              className={cn(style.image, style.meet)}
            />
            <img
              alt="sheets"
              src="/images/logos/google/gsuite/sheets.png"
              className={cn(style.image, style.sheets)}
            />
            <img
              alt="slides"
              src="/images/logos/google/gsuite/slides.png"
              className={cn(style.image, style.slides)}
            />
            <img
              alt="vault"
              src="/images/logos/google/gsuite/vault35.png"
              className={cn(style.image, style.vault)}
            />
          </div>
        </div>
        <Divider containerClassName={style.laser}>
          <svg xmlns="http://www.w3.org/2000/svg" width="1409" height="183" viewBox="0 0 1409 183">
            <defs>
              <linearGradient
                id="divider-laser-a"
                x1="15.436%"
                x2="114.319%"
                y1="90.518%"
                y2="21.413%"
              >
                <stop offset="0%" stopColor="#FFC42A" />
                <stop offset="100%" stopColor="#FF0061" stopOpacity=".946" />
              </linearGradient>
              <filter
                id="divider-laser-b"
                width="104.3%"
                height="174.2%"
                x="-2.1%"
                y="-37.1%"
                filterUnits="objectBoundingBox"
              >
                <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
              </filter>
            </defs>
            <g fill="url(#divider-laser-a)" transform="translate(0 12)">
              <polygon
                points="1.155 117.506 509.459 36.651 912.705 79.695 1071.78 78.401 1403.889 44.82 923.461 117.084 715.999 68.87 498.537 50.657"
                filter="url(#divider-laser-b)"
                opacity=".8"
                transform="rotate(-3 702.522 77.079)"
              />
              <polygon points="1.155 117.506 509.459 36.651 912.705 79.695 1071.78 78.401 1403.889 44.82 923.461 117.084 715.999 68.87 498.537 50.657" />
            </g>
          </svg>
        </Divider>
      </section>
    )
  }
}

export default Hero
