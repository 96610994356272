import { isProd, isStaging, isTest, isDev, isBeta } from 'utils/env'

export const env = () =>
  isProd() ? (isStaging() ? 'staging' : isBeta() ? 'beta' : 'prod') : isTest() ? 'test' : 'staging'

export const BASE_URL = isDev() ? 'http://localhost:4000' : 'https://api.observian.com'
export const BASE_ENV_URL = `${BASE_URL}${isDev() ? '' : '/'}${isDev() ? '/development' : env()}`

export const headers = (recievedHeaders = {}) => ({
  ...recievedHeaders,
})

export const params = (recievedParams = {}) => ({
  ...recievedParams,
  env: env(),
})
