import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Card from 'observian-ui/lib/card'
import SVGInput from 'observian-ui/lib/input/svgInput'
import Button from 'observian-ui/lib/button'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import sortBy from 'lodash/sortBy'
import { useDebounce } from 'react-use'
import { Link } from 'gatsby'
import Divider from 'observian-ui/lib/divider'

import TechLines from 'components/SVG/techLines'
import Cloud from 'components/SVG/cloud'
import BlogPagination from 'components/Page/Blog/Pagination'

import style from './search.module.css'

const SearchResults = ({ topics, authors, members, searchQuery }) => {
  const [inputValue, setInputValue] = useState('')
  const [topicFilterListId, setTopicFilterListId] = useState([])
  const [topicFilters, setTopicFilters] = useState({})
  const [debouncedValue, setDebouncedValue] = useState('')

  useEffect(() => {
    if (searchQuery) {
      return setInputValue(searchQuery)
    }

    return () => {}
  }, [searchQuery])

  // eslint-disable-next-line no-unused-vars
  const [isReady, cancel] = useDebounce(
    () => {
      setDebouncedValue(inputValue)
    },
    500,
    [inputValue],
  )

  const handleOnTopicFilter = event => {
    const { name, id, checked } = event.target

    if (name) {
      return setTopicFilters({ ...topicFilters, [name]: checked ? id : null })
    }

    return setTopicFilters({ ...topicFilters })
  }

  const handleApplyFilter = () => {
    const topicFilterList = Object.keys(topicFilters)
      .reduce((previous, topicName) => [...previous, topicFilters[topicName]], [])
      .filter(Boolean)

    const sortedTopicFilterList = sortBy(topicFilterList, topic => topic.name)

    setTopicFilterListId(sortedTopicFilterList)
  }

  return (
    <div className={style.search}>
      <div className={style.hero}>
        <span role="presentation" alt="Blog Hero Background Left" className={style.topLeftBG} />
        <TechLines className={style.leftTechLines} />
        <Cloud withLasers type="outline" className={style.cloud} />
        <TechLines className={style.rightTechLines} />
        <Divider className={style.divider} />
      </div>
      <div className={style.contentContainer}>
        <div className={style.content}>
          <Link className={style.returnLink} to="/blog">
            &larr; Back to blog home
          </Link>
          <Card isElevated className={style.searchFilter}>
            <div className={style.inputContainer}>
              <SVGInput
                className={style.input}
                placeholder="Search Content..."
                value={inputValue}
                onChange={({ currentTarget }) => setInputValue(currentTarget.value)}
              />
            </div>
            <FormControl className={style.filters} component="fieldset">
              <FormLabel className={style.topicText} component="legend">
                Topics
              </FormLabel>
              <FormGroup className={style.checkboxes}>
                {sortBy(topics, topic => topic.name).map(topic => (
                  <FormControlLabel
                    key={`checkbox:${topic.topicId}`}
                    control={
                      <Checkbox
                        onChange={handleOnTopicFilter}
                        name={topic.slug}
                        id={topic.topicId}
                      />
                    }
                    label={topic.name}
                  />
                ))}
              </FormGroup>
            </FormControl>
            <Button className={style.button} label="Apply Filter" onClick={handleApplyFilter} />
          </Card>
          <div className={style.searchResults}>
            <h1 className={clsx(style.title, debouncedValue && style.searchWordVisible)}>
              Search Results
            </h1>
            {debouncedValue ? (
              <div className={style.searchWord}>&quot;{debouncedValue}&quot;</div>
            ) : null}
          </div>
          <BlogPagination
            darkText
            className={clsx(style.blogPagination, debouncedValue && style.searchWordVisible)}
            topicFilters={topicFilterListId}
            authors={authors}
            members={members}
            search={debouncedValue}
          />
        </div>
      </div>
    </div>
  )
}

SearchResults.defaultProps = {
  topics: [],
  searchQuery: '',
}

SearchResults.propTypes = {
  topics: PropTypes.array,
  members: PropTypes.array.isRequired,
  authors: PropTypes.array.isRequired,
  searchQuery: PropTypes.string,
}

export default SearchResults
