import React from 'react'
import PropTypes from 'prop-types'
import Tag from 'observian-ui/lib/tag'
import BlogAuthorCard from 'observian-ui/lib/blog-author-card'
import Loading from 'observian-ui/lib/loading'
import isEmpty from 'lodash/isEmpty'
import Prism from 'prismjs'
import Divider from 'observian-ui/lib/divider'
import { Link } from 'gatsby'

import canUseDOM from 'utils/canUseDOM'
import TechLines from 'components/SVG/techLines'
import BlogLaser from 'components/SVG/lasers/blog'

import OtherPosts from '../OtherPosts'

import Comments from './Comments'
import style from './post.module.css'
import 'styles/prism-okaidia.css'

class Post extends React.Component {
  componentDidMount() {
    const { post } = this.props

    if (canUseDOM() && post && post.postBody) {
      Prism.highlightAll(post.postBody)
    }
  }

  componentDidUpdate(prevProps) {
    const { post } = this.props

    if (canUseDOM() && post && post.postBody) {
      if (post.postBody !== prevProps.post.postBody && !isEmpty(post.postBody)) {
        Prism.highlightAll(post.postBody)
      }
    }
  }

  render() {
    const { post, topics, posts } = this.props

    const postBody = !isEmpty(post.postBody)
      ? post.postBody
      : '<h2>There is no content to display.</h2>'

    const avatar = (post.blogAuthor && post.blogAuthor.avatar) || '/images/default/avatar.png'

    return (
      <section className={style.post}>
        <header className={style.hero}>
          <span
            role="presentation"
            alt="Blog Post Hero Background Left"
            className={style.topLeftBG}
          />
          <span className={style.allThings}>ALL THINGS</span>
          <TechLines className={style.leftTechLines} />
          <h1>{post.name}</h1>
          {/* prettier-ignore */}
          <div className={style.topics}>
            {post.topicIds && !!post.topicIds.length
              ? post.topicIds.map(topicId =>
                topics.map(
                  topic =>
                    topic.topicId === topicId ? (
                      <Tag key={`otherPost:tag:topic:${topicId}`} name={topic.name} />
                    ) : null,
                ))
              : null}
          </div>
          <TechLines className={style.middleTechLines} />
          <TechLines className={style.rightTechLines} />
          <Divider>
            <BlogLaser className={style.laser} />
          </Divider>
        </header>
        <article className={style.content}>
          <BlogAuthorCard
            className={style.blogAuthorCard}
            image={avatar}
            fullName={(post.blogAuthor && post.blogAuthor.fullName) || null}
            title={(post.blogAuthor && post.blogAuthor.title) || null}
            avatarProps={{
              forceAspectRatio: post.blogAuthor.isExternalAuthor,
              classNames: { image: style.image, svg: style.svg },
            }}
          />
          {post.isLoading || isEmpty(post.slug) ? (
            <Loading darkLogo className={style.postContent} />
          ) : (
            <div className={style.postContent}>
              <Link to="/blog">&larr; Back to all blog posts</Link>
              <div className={style.postBody} dangerouslySetInnerHTML={{ __html: postBody }} />
            </div>
          )}
        </article>
        <Comments post={post} />
        <OtherPosts post={post} posts={posts} topics={topics} />
      </section>
    )
  }
}

Post.defaultProps = {
  post: {
    postBody: '<h1>Default Props</h1>',
    name: 'Default Props Page Title',
    blogAuthor: {
      avatar: '',
      fullName: '',
      title: '',
      email: '',
    },
    topics: [],
    isLoading: false,
    topicIds: [],
    slug: '',
  },
  posts: [],
  topics: [],
}

Post.propTypes = {
  post: PropTypes.shape({
    postBody: PropTypes.string,
    name: PropTypes.string,
    blogAuthor: PropTypes.shape({
      avatar: PropTypes.string,
      fullName: PropTypes.string,
      title: PropTypes.string,
      email: PropTypes.string,
      isExternalAuthor: PropTypes.bool,
    }),
    topics: PropTypes.arrayOf(PropTypes.number),
    isLoading: PropTypes.bool,
    topicIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    slug: PropTypes.string,
  }),
  posts: PropTypes.arrayOf(PropTypes.object),
  topics: PropTypes.arrayOf(PropTypes.object),
}

export default Post
