import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import style from './quote.module.css'

const PureQuote = ({ data }) => (
  <section className={style.section}>
    <Img fluid={data.file.childImageSharp.fluid} />
  </section>
)

PureQuote.defaultProps = {
  data: {},
}

PureQuote.propTypes = {
  data: PropTypes.object,
}

const query = graphql`
  query {
    file(relativePath: { eq: "services/google/google-services-quote.png" }) {
      childImageSharp {
        fluid(maxWidth: 2658) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export const Quote = props => (
  <StaticQuery query={query} render={data => <PureQuote data={data} {...props} />} />
)

export default Quote
