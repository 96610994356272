// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-appd-js": () => import("./../src/pages/appd.js" /* webpackChunkName: "component---src-pages-appd-js" */),
  "component---src-pages-aws-big-data-analytics-js": () => import("./../src/pages/aws/big-data-analytics.js" /* webpackChunkName: "component---src-pages-aws-big-data-analytics-js" */),
  "component---src-pages-aws-cloud-migration-js": () => import("./../src/pages/aws/cloud-migration.js" /* webpackChunkName: "component---src-pages-aws-cloud-migration-js" */),
  "component---src-pages-aws-cost-optimization-js": () => import("./../src/pages/aws/cost-optimization.js" /* webpackChunkName: "component---src-pages-aws-cost-optimization-js" */),
  "component---src-pages-aws-devops-js": () => import("./../src/pages/aws/devops.js" /* webpackChunkName: "component---src-pages-aws-devops-js" */),
  "component---src-pages-aws-js": () => import("./../src/pages/aws.js" /* webpackChunkName: "component---src-pages-aws-js" */),
  "component---src-pages-aws-microsoft-on-aws-js": () => import("./../src/pages/aws/microsoft-on-aws.js" /* webpackChunkName: "component---src-pages-aws-microsoft-on-aws-js" */),
  "component---src-pages-aws-security-compliance-js": () => import("./../src/pages/aws/security-compliance.js" /* webpackChunkName: "component---src-pages-aws-security-compliance-js" */),
  "component---src-pages-big-data-analytics-js": () => import("./../src/pages/big-data-analytics.js" /* webpackChunkName: "component---src-pages-big-data-analytics-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-search-js": () => import("./../src/pages/blog/search.js" /* webpackChunkName: "component---src-pages-blog-search-js" */),
  "component---src-pages-cloud-migration-js": () => import("./../src/pages/cloud-migration.js" /* webpackChunkName: "component---src-pages-cloud-migration-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-containers-js": () => import("./../src/pages/containers.js" /* webpackChunkName: "component---src-pages-containers-js" */),
  "component---src-pages-cost-optimization-js": () => import("./../src/pages/cost-optimization.js" /* webpackChunkName: "component---src-pages-cost-optimization-js" */),
  "component---src-pages-devops-js": () => import("./../src/pages/devops.js" /* webpackChunkName: "component---src-pages-devops-js" */),
  "component---src-pages-google-js": () => import("./../src/pages/google.js" /* webpackChunkName: "component---src-pages-google-js" */),
  "component---src-pages-gsuite-js": () => import("./../src/pages/gsuite.js" /* webpackChunkName: "component---src-pages-gsuite-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-js": () => import("./../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-security-compliance-js": () => import("./../src/pages/security-compliance.js" /* webpackChunkName: "component---src-pages-security-compliance-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-tools-secret-awsome-js": () => import("./../src/pages/tools/secret-awsome.js" /* webpackChunkName: "component---src-pages-tools-secret-awsome-js" */),
  "component---src-pages-training-workshops-js": () => import("./../src/pages/training-workshops.js" /* webpackChunkName: "component---src-pages-training-workshops-js" */),
  "component---src-templates-blog-post-index-js": () => import("./../src/templates/Blog/Post/index.js" /* webpackChunkName: "component---src-templates-blog-post-index-js" */),
  "component---src-templates-events-index-js": () => import("./../src/templates/Events/index.js" /* webpackChunkName: "component---src-templates-events-index-js" */),
  "component---src-templates-partners-index-js": () => import("./../src/templates/Partners/index.js" /* webpackChunkName: "component---src-templates-partners-index-js" */),
  "component---src-templates-webinar-index-js": () => import("./../src/templates/Webinar/index.js" /* webpackChunkName: "component---src-templates-webinar-index-js" */)
}

