import React from 'react'
import PropTypes from 'prop-types'

import Layout from 'components/Layout'
import { isStaging, isProd } from 'utils/env'
import SponsorPrizes, { SponsorPrizesMobile } from 'components/SponsorPrizes'

import Hero from './Hero'
import Sponsors from './Sponsors'
import style from './webinar.module.css'

const testFormId = 'f07883f5-f2be-42d4-837d-f777e043e9c1'
const renderDate = (timestamp, options) => {
  return new Date(timestamp).toLocaleString('en-US', options)
}

const WebinarTemplate = ({ pageContext }) => {
  const formId = isProd() ? (!isStaging() ? pageContext.form.formId : testFormId) : testFormId
  const eventDate = renderDate(pageContext.date, {
    timeZone: 'America/Denver',
    month: 'long',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    weekday: 'long',
  })

  return (
    <Layout
      title={`${pageContext.page.title} | Webinar`}
      description={pageContext.page.description}
    >
      <Hero
        heading={pageContext.heading}
        title={pageContext.title}
        date={eventDate}
        description={pageContext.description.description}
        presenters={pageContext.presenters}
        formTitle={pageContext.form.title}
        formCTA={pageContext.form.cta}
        formId={formId}
        webinarId={pageContext.webinarId}
      />
      <SponsorPrizes className={style.sponsorPrizes} prizes={pageContext.sponsorPrize} />
      <SponsorPrizesMobile
        className={style.sponsorPrizesMobile}
        prizes={pageContext.sponsorPrize}
      />
      <Sponsors sponsors={pageContext.sponsors} />
    </Layout>
  )
}

WebinarTemplate.defaultProps = {}

WebinarTemplate.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export default WebinarTemplate
