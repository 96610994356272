import React from 'react'
import Button from 'observian-ui/lib/button'
import Modal from 'observian-ui/lib/modal'

import HubspotMeeting from 'components/Hubspot/MeetingPlugin'

import style from './comparison.module.css'

class Comparison extends React.Component {
  state = {
    showModal: false,
  }

  handleModal = () => {
    this.setState(state => ({
      showModal: !state.showModal,
    }))
  }

  render() {
    const { showModal } = this.state

    return (
      <div className={style.container}>
        <h3 className={style.header}>Check Out How G Suite Compares</h3>
        <svg
          className={style.comparisonTable}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="997"
          height="1134"
          viewBox="0 0 997 1134"
        >
          <defs>
            <rect id="comparison-table-a" width="877" height="995.277" x=".5" y=".647" rx="24" />
            <filter
              id="comparison-table-b"
              width="116%"
              height="114.1%"
              x="-8%"
              y="-5%"
              filterUnits="objectBoundingBox"
            >
              <feOffset dy="20" in="SourceAlpha" result="shadowOffsetOuter1" />
              <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="20" />
              <feColorMatrix
                in="shadowBlurOuter1"
                values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.104308197 0"
              />
            </filter>
            <linearGradient
              id="comparison-table-d"
              x1="12.453%"
              x2="87.895%"
              y1="84.924%"
              y2="16.33%"
            >
              <stop offset="0%" stopColor="#1D8DBA" />
              <stop offset="100%" stopColor="#03ECAE" />
            </linearGradient>
            <filter
              id="comparison-table-e"
              width="136.9%"
              height="124%"
              x="-18.4%"
              y="-12%"
              filterUnits="objectBoundingBox"
            >
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
            <filter
              id="comparison-table-f"
              width="110.7%"
              height="116.5%"
              x="-5.3%"
              y="-8.2%"
              filterUnits="objectBoundingBox"
            >
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
          </defs>
          <g fill="none" transform="translate(7 -11)">
            <g transform="translate(73 85)">
              <mask id="comparison-table-c" fill="#fff">
                <use xlinkHref="#comparison-table-a" />
              </mask>
              <g>
                <use
                  fill="#000"
                  filter="url(#comparison-table-b)"
                  xlinkHref="#comparison-table-a"
                />
                <use fill="#F6FBFA" xlinkHref="#comparison-table-a" />
              </g>
              <text
                fill="#1D354F"
                fillOpacity="0.9"
                fontFamily="GeomanistUltra, Geomanist"
                fontSize="75"
                fontWeight="600"
                mask="url(#comparison-table-c)"
                opacity="0.04"
              >
                <tspan x="360.708" y="79.159">
                  COMPARISON
                </tspan>
              </text>
            </g>
            <path
              fill="#172A3F"
              d="M97,89.6725721 L408,89.6725721 L408,1076.54564 L97,1076.54564 C85.954305,1076.54564 77,1067.59134 77,1056.54564 L77,109.672572 C77,98.6268771 85.954305,89.6725721 97,89.6725721 Z"
              opacity="0.047"
            />
            <polygon
              fill="url(#comparison-table-d)"
              points="85.399 892.745 20.81 974.868 183.425 1142.795 61.077 974.868 73.547 966.868"
              filter="url(#comparison-table-e)"
              opacity=".375"
              transform="rotate(-9 102.117 1017.77)"
            />
            <polygon
              fill="url(#comparison-table-d)"
              points="73.4 890.88 20.663 974.88 183.278 1142.806 60.931 974.88 73.4 966.88"
            />
            <polygon
              fill="url(#comparison-table-d)"
              points="48.735 397 20.663 165.41 289.301 33.109 582.974 78.275 273.594 68.275 42.735 167.093"
              filter="url(#comparison-table-f)"
              opacity=".274"
              transform="rotate(-7 301.818 215.055)"
            />
            <polygon
              fill="url(#comparison-table-d)"
              points="48.735 397 20.663 165.41 289.301 33.109 582.974 78.275 273.594 68.275 42.735 167.093"
            />
            <text
              fill="#1D354F"
              fillOpacity="0.9"
              fontFamily="GeomanistBold, Geomanist"
              fontSize="27"
              fontWeight="bold"
            >
              <tspan x="702" y="173">
                Office 365
              </tspan>
            </text>
            <text
              fill="#1D354F"
              fillOpacity="0.9"
              fontFamily="GeomanistBold, Geomanist"
              fontSize="27"
              fontWeight="bold"
            >
              <tspan x="443" y="172.5">
                GSuite
              </tspan>
            </text>
            <path
              stroke="#1A2F46"
              strokeLinecap="square"
              d="M666.392578,187 L660.954535,1032"
              opacity="0.056"
            />
            <path
              stroke="#EBF1F1"
              strokeLinecap="square"
              d="M134 264.5L910.5 264.5M134 403.5L910.5 403.5M134 601.5L910.5 601.5M134 679.5L910.5 679.5M134 787.5L910.5 787.5M134 894.5L910.5 894.5M134 973.5L910.5 973.5"
            />
            <g
              fill="#1D354F"
              fillOpacity="0.9"
              fontFamily="GeomanistBook, Geomanist"
              fontSize="21"
              fontWeight="300"
              transform="translate(133 207)"
            >
              <text>
                <tspan x="0" y="20">
                  Content Management
                </tspan>
              </text>
              <text>
                <tspan x="0" y="98">
                  Document Editing
                </tspan>
              </text>
              <text>
                <tspan x="0" y="236">
                  Document Sharing
                </tspan>
              </text>
              <text>
                <tspan x="0" y="434">
                  Document Storage &amp; Sync
                </tspan>
              </text>
              <text>
                <tspan x="0" y="512">
                  Tasks
                </tspan>
              </text>
              <text>
                <tspan x="0" y="620">
                  Groups &amp; Communities
                </tspan>
              </text>
              <text>
                <tspan x="0" y="728">
                  Video &amp; Voice
                </tspan>
              </text>
              <text>
                <tspan x="0" y="806">
                  Price Per User
                </tspan>
              </text>
            </g>
            <g
              fill="#1D354F"
              fillOpacity="0.9"
              fontFamily="GeomanistLight, Geomanist"
              fontSize="18"
              fontWeight="300"
              transform="translate(443 208)"
            >
              <text>
                <tspan x="0" y="18">
                  Google Sites
                </tspan>
              </text>
              <text>
                <tspan x="260" y="18">
                  Sites / Sharepoint
                </tspan>
              </text>
              <text>
                <tspan x="0" y="96">
                  Google Docs
                </tspan>
                <tspan x="0" y="127.5">
                  Google Slides
                </tspan>
                <tspan x="0" y="159">
                  Google Sheets
                </tspan>
              </text>
              <text>
                <tspan x="260" y="96">
                  Word
                </tspan>
                <tspan x="260" y="127.5">
                  PowerPoint
                </tspan>
                <tspan x="260" y="159">
                  Excel
                </tspan>
              </text>
              <text>
                <tspan x="0" y="234">
                  Hangouts
                </tspan>
                <tspan x="0" y="265.5">
                  Google Groups
                </tspan>
                <tspan x="0" y="297">
                  Google Sites
                </tspan>
                <tspan x="0" y="328.5">
                  Google Drive
                </tspan>
              </text>
              <text>
                <tspan x="260" y="234">
                  Skype for Business
                </tspan>
                <tspan x="260" y="265.5">
                  Sharepoint Document{' '}
                </tspan>
                <tspan x="260" y="297">
                  Libraries
                </tspan>
                <tspan x="260" y="328.5">
                  OneDrive for Business
                </tspan>
                <tspan x="260" y="360">
                  Yammer
                </tspan>
              </text>
              <text>
                <tspan x="0" y="432">
                  Google Drive
                </tspan>
              </text>
              <text>
                <tspan x="260" y="432">
                  OneDrive
                </tspan>
              </text>
              <text>
                <tspan x="0" y="510">
                  Gmail Tasks
                </tspan>
                <tspan x="0" y="541.5">
                  Google Keep
                </tspan>
              </text>
              <text>
                <tspan x="260" y="510">
                  Outlook Tasks
                </tspan>
                <tspan x="260" y="541.5">
                  Planner
                </tspan>
              </text>
              <text>
                <tspan x="0" y="618">
                  Google Groups
                </tspan>
              </text>
              <text>
                <tspan x="260" y="618">
                  Outlook Groups
                </tspan>
                <tspan x="260" y="649.5">
                  Microsoft Teams
                </tspan>
              </text>
              <text>
                <tspan x="0" y="726">
                  Hangouts
                </tspan>
              </text>
              <text>
                <tspan x="260" y="726">
                  Skype for Business
                </tspan>
              </text>
              <text
                fill="#1D354F"
                fillOpacity="0.9"
                fontFamily="GeomanistBold, Geomanist"
                fontSize="20"
                fontWeight="bold"
              >
                <tspan x="0" y="804">
                  $6/$12/$25
                </tspan>
              </text>
              <text
                fill="#1D354F"
                fillOpacity="0.9"
                fontFamily="GeomanistBold, Geomanist"
                fontSize="20"
                fontWeight="bold"
              >
                <tspan x="260" y="804">
                  $25/$35
                </tspan>
              </text>
            </g>
          </g>
        </svg>
        <div className={style.content}>
          <h2 className={style.title}>What Does Observian Provide as a Google Cloud Partner?</h2>
          <p className={style.description}>
            As a G Suite partner, we provide technical support for G Suite products and
            administration at
            <svg xmlns="http://www.w3.org/2000/svg" width="86" height="15" viewBox="0 0 74 15">
              <text
                fill="#1D354F"
                fillOpacity="0.9"
                fontFamily="GeomanistBold, Geomanist"
                fontSize="18"
                fontWeight="bold"
              >
                <tspan x="-0.94" y="14">
                  NO COST
                </tspan>
              </text>
            </svg>
            to you. With our built-in G Suite migration, switching from Office 365 is simple, and
            Observian is here to help with every step along the way. Our goal is to make your use of
            Google products as easy and seamless as possible.
          </p>
          <h3 className={style.title}>Start Your G Suite Migration Today!</h3>
          <p className={style.description}>
            Get the most from G Suite and Observian all in one. We’re happy to help you get started.
          </p>
          <Button
            label="Schedule a Conversation"
            className={style.button}
            onClick={this.handleModal}
          />
        </div>
        {showModal ? (
          <Modal isTransparent isOpen={showModal} onClose={this.handleModal}>
            <HubspotMeeting />
          </Modal>
        ) : null}
      </div>
    )
  }
}

Comparison.defaultProps = {}

Comparison.propTypes = {}

export default Comparison
