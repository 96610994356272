import React from 'react'

import { Hero, OurApproach, WhatWeDo, MyCompany, Offerings, CVE } from 'components/Page/Home'
import Layout from 'components/Layout'

const Index = () => (
  <Layout
    title="Do Software Delivery & Cloud Better | Cloud Services Utah"
    description="Observian offers cloud services in Utah such as Cloud Migration, Disaster Recovery, Security, Compliance, Big Data, DevOps, Cost Optimization, and more!"
  >
    <Hero />
    <OurApproach />
    <WhatWeDo />
    <MyCompany />
    <Offerings />
    <CVE />
  </Layout>
)

export default Index
