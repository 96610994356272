import React from 'react'
import PropTypes from 'prop-types'

const PartnersBoxes = ({ attrClassName, className }) => (
  <svg
    className={className}
    width="653px"
    height="495px"
    viewBox="0 0 653 495"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <rect id="partners-boxes-path-1" x="73.1914062" y="0" width="154" height="57" rx="4" />
      <filter
        x="-45.5%"
        y="-87.7%"
        width="190.9%"
        height="345.6%"
        filterUnits="objectBoundingBox"
        id="partners-boxes-filter-2"
      >
        <feOffset dx="0" dy="20" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="20" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.516023597   0 0 0 0 0.516023597   0 0 0 0 0.516023597  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect id="partners-boxes-path-3" x="216" y="324" width="127" height="39" rx="4" />
      <filter
        x="-55.1%"
        y="-128.2%"
        width="210.2%"
        height="459.0%"
        filterUnits="objectBoundingBox"
        id="partners-boxes-filter-4"
      >
        <feOffset dx="0" dy="20" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="20" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.516023597   0 0 0 0 0.516023597   0 0 0 0 0.516023597  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect id="partners-boxes-path-5" x="440" y="43" width="154" height="57" rx="4" />
      <filter
        x="-45.5%"
        y="-87.7%"
        width="190.9%"
        height="345.6%"
        filterUnits="objectBoundingBox"
        id="partners-boxes-filter-6"
      >
        <feOffset dx="0" dy="20" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="20" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.516023597   0 0 0 0 0.516023597   0 0 0 0 0.516023597  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect
        id="partners-boxes-path-7"
        x="374.452242"
        y="324"
        width="106.547758"
        height="57"
        rx="4"
      />
      <filter
        x="-65.7%"
        y="-87.7%"
        width="231.4%"
        height="345.6%"
        filterUnits="objectBoundingBox"
        id="partners-boxes-filter-8"
      >
        <feOffset dx="0" dy="20" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="20" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.516023597   0 0 0 0 0.516023597   0 0 0 0 0.516023597  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect
        id="partners-boxes-path-9"
        x="456.547758"
        y="279"
        width="106.547758"
        height="57"
        rx="4"
      />
      <filter
        x="-65.7%"
        y="-87.7%"
        width="231.4%"
        height="345.6%"
        filterUnits="objectBoundingBox"
        id="partners-boxes-filter-10"
      >
        <feOffset dx="0" dy="20" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="20" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.516023597   0 0 0 0 0.516023597   0 0 0 0 0.516023597  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect
        id="partners-boxes-path-11"
        x="61.5477582"
        y="128"
        width="77.4522418"
        height="57"
        rx="4"
      />
      <filter
        x="-90.4%"
        y="-87.7%"
        width="280.8%"
        height="345.6%"
        filterUnits="objectBoundingBox"
        id="partners-boxes-filter-12"
      >
        <feOffset dx="0" dy="20" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="20" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.516023597   0 0 0 0 0.516023597   0 0 0 0 0.516023597  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect
        id="partners-boxes-path-13"
        x="92.4522418"
        y="308"
        width="106.547758"
        height="39"
        rx="4"
      />
      <filter
        x="-65.7%"
        y="-128.2%"
        width="231.4%"
        height="459.0%"
        filterUnits="objectBoundingBox"
        id="partners-boxes-filter-14"
      >
        <feOffset dx="0" dy="20" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="20" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.516023597   0 0 0 0 0.516023597   0 0 0 0 0.516023597  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect
        id="partners-boxes-path-15"
        x="266.547758"
        y="219"
        width="106.547758"
        height="57"
        rx="4"
      />
      <filter
        x="-65.7%"
        y="-87.7%"
        width="231.4%"
        height="345.6%"
        filterUnits="objectBoundingBox"
        id="partners-boxes-filter-16"
      >
        <feOffset dx="0" dy="20" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="20" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.516023597   0 0 0 0 0.516023597   0 0 0 0 0.516023597  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect
        id="partners-boxes-path-17"
        x="396.547758"
        y="139"
        width="106.547758"
        height="57"
        rx="4"
      />
      <filter
        x="-65.7%"
        y="-87.7%"
        width="231.4%"
        height="345.6%"
        filterUnits="objectBoundingBox"
        id="partners-boxes-filter-18"
      >
        <feOffset dx="0" dy="20" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="20" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.516023597   0 0 0 0 0.516023597   0 0 0 0 0.516023597  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <linearGradient x1="0%" y1="0%" x2="101.999998%" y2="100.999999%" id="linearGradient-19">
        <stop stopColor="#FFC42A" offset="0%" />
        <stop stopColor="#FF0061" stopOpacity="0.946473053" offset="100%" />
      </linearGradient>
      <rect
        id="partners-boxes-path-20"
        x="0.246955423"
        y="0.5"
        width="151.753045"
        height="76"
        rx="4"
      />
      <filter
        x="-46.1%"
        y="-65.8%"
        width="192.3%"
        height="284.2%"
        filterUnits="objectBoundingBox"
        id="partners-boxes-filter-21"
      >
        <feOffset dx="0" dy="20" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="20" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.516023597   0 0 0 0 0.516023597   0 0 0 0 0.516023597  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect
        id="partners-boxes-path-22"
        x="0.05078125"
        y="0.924429228"
        width="192.46875"
        height="71"
        rx="4"
      />
      <filter
        x="-36.4%"
        y="-70.4%"
        width="172.7%"
        height="297.2%"
        filterUnits="objectBoundingBox"
        id="partners-boxes-filter-23"
      >
        <feOffset dx="0" dy="20" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="20" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.516023597   0 0 0 0 0.516023597   0 0 0 0 0.516023597  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect id="partners-boxes-path-24" x="0" y="0" width="227" height="71" rx="4" />
      <filter
        x="-15.4%"
        y="-35.2%"
        width="130.8%"
        height="198.6%"
        filterUnits="objectBoundingBox"
        id="partners-boxes-filter-25"
      >
        <feOffset dx="0" dy="10" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.304740646   0 0 0 0 0.297249586   0 0 0 0 0.297249586  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect id="partners-boxes-path-26" x="0" y="0" width="209" height="57" rx="4" />
      <filter
        x="-33.5%"
        y="-87.7%"
        width="167.0%"
        height="345.6%"
        filterUnits="objectBoundingBox"
        id="partners-boxes-filter-27"
      >
        <feOffset dx="0" dy="20" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="20" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.516023597   0 0 0 0 0.516023597   0 0 0 0 0.516023597  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect
        id="partners-boxes-path-28"
        x="0.547758215"
        y="0.606103756"
        width="180"
        height="75"
        rx="4"
      />
      <filter
        x="-38.9%"
        y="-66.7%"
        width="177.8%"
        height="286.7%"
        filterUnits="objectBoundingBox"
        id="partners-boxes-filter-29"
      >
        <feOffset dx="0" dy="20" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="20" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.516023597   0 0 0 0 0.516023597   0 0 0 0 0.516023597  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect
        id="partners-boxes-path-30"
        x="0.130859375"
        y="0.106103756"
        width="182.261719"
        height="71"
        rx="4"
      />
      <filter
        x="-38.4%"
        y="-70.4%"
        width="176.8%"
        height="297.2%"
        filterUnits="objectBoundingBox"
        id="partners-boxes-filter-31"
      >
        <feOffset dx="0" dy="20" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="20" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.516023597   0 0 0 0 0.516023597   0 0 0 0 0.516023597  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect
        id="partners-boxes-path-32"
        x="0.821637322"
        y="0"
        width="243.753045"
        height="71"
        rx="4"
      />
      <filter
        x="-28.7%"
        y="-70.4%"
        width="157.4%"
        height="297.2%"
        filterUnits="objectBoundingBox"
        id="partners-boxes-filter-33"
      >
        <feOffset dx="0" dy="20" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="20" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.516023597   0 0 0 0 0.516023597   0 0 0 0 0.516023597  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect id="partners-boxes-path-34" x="0" y="0" width="268" height="71" rx="4" />
      <filter
        x="-26.1%"
        y="-70.4%"
        width="152.2%"
        height="297.2%"
        filterUnits="objectBoundingBox"
        id="partners-boxes-filter-35"
      >
        <feOffset dx="0" dy="20" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="20" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.516023597   0 0 0 0 0.516023597   0 0 0 0 0.516023597  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <rect id="partners-boxes-path-36" x="0" y="0" width="243.753045" height="71" rx="4" />
      <filter
        x="-28.7%"
        y="-70.4%"
        width="157.4%"
        height="297.2%"
        filterUnits="objectBoundingBox"
        id="partners-boxes-filter-37"
      >
        <feOffset dx="0" dy="20" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="20" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.516023597   0 0 0 0 0.516023597   0 0 0 0 0.516023597  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="partners-boxes" transform="translate(19.000000, 20.000000)">
        <rect id="partners-boxes-container" x="0" y="0" width="613" height="470" />
        <g id="box8" opacity="0.16649683">
          <use
            fill="black"
            fillOpacity="1"
            filter="url(#partners-boxes-filter-2)"
            xlinkHref="#partners-boxes-path-1"
          />
          <use fill="#F6FBFA" fillRule="evenodd" xlinkHref="#partners-boxes-path-1" />
        </g>
        <g id="box7" opacity="0.16649683">
          <use
            fill="black"
            fillOpacity="1"
            filter="url(#partners-boxes-filter-4)"
            xlinkHref="#partners-boxes-path-3"
          />
          <use fill="#F6FBFA" fillRule="evenodd" xlinkHref="#partners-boxes-path-3" />
        </g>
        <g id="box6" opacity="0.24">
          <use
            fill="black"
            fillOpacity="1"
            filter="url(#partners-boxes-filter-6)"
            xlinkHref="#partners-boxes-path-5"
          />
          <use fill="#F6FBFA" fillRule="evenodd" xlinkHref="#partners-boxes-path-5" />
        </g>
        <g id="box5" opacity="0.16649683">
          <use
            fill="black"
            fillOpacity="1"
            filter="url(#partners-boxes-filter-8)"
            xlinkHref="#partners-boxes-path-7"
          />
          <use fill="#F6FBFA" fillRule="evenodd" xlinkHref="#partners-boxes-path-7" />
        </g>
        <g id="box4" opacity="0.32">
          <use
            fill="black"
            fillOpacity="1"
            filter="url(#partners-boxes-filter-10)"
            xlinkHref="#partners-boxes-path-9"
          />
          <use fill="#F6FBFA" fillRule="evenodd" xlinkHref="#partners-boxes-path-9" />
        </g>
        <g id="box3" opacity="0.32">
          <use
            fill="black"
            fillOpacity="1"
            filter="url(#partners-boxes-filter-12)"
            xlinkHref="#partners-boxes-path-11"
          />
          <use fill="#F6FBFA" fillRule="evenodd" xlinkHref="#partners-boxes-path-11" />
        </g>
        <g id="box2" opacity="0.32">
          <use
            fill="black"
            fillOpacity="1"
            filter="url(#partners-boxes-filter-14)"
            xlinkHref="#partners-boxes-path-13"
          />
          <use fill="#F6FBFA" fillRule="evenodd" xlinkHref="#partners-boxes-path-13" />
        </g>
        <g id="box1" opacity="0.32">
          <use
            fill="black"
            fillOpacity="1"
            filter="url(#partners-boxes-filter-16)"
            xlinkHref="#partners-boxes-path-15"
          />
          <use fill="#F6FBFA" fillRule="evenodd" xlinkHref="#partners-boxes-path-15" />
        </g>
        <g id="box" opacity="0.32">
          <use
            fill="black"
            fillOpacity="1"
            filter="url(#partners-boxes-filter-18)"
            xlinkHref="#partners-boxes-path-17"
          />
          <use fill="#F6FBFA" fillRule="evenodd" xlinkHref="#partners-boxes-path-17" />
        </g>
        <g
          id="particles"
          opacity="0.255151721"
          transform="translate(11.000000, 77.000000)"
          fill="url(#linearGradient-19)"
        >
          <polygon
            className={attrClassName.tri}
            stroke="url(#linearGradient-19)"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(114.064195, 9.474883) rotate(-13.000000) translate(-114.064195, -9.474883) "
            points="114.064195 2.40961873 123.02657 16.5401469 105.101821 16.5401469"
          />
          <polygon
            className={attrClassName.tri4}
            stroke="url(#linearGradient-19)"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(220.305436, 165.732565) rotate(20.000000) translate(-220.305436, -165.732565) "
            points="220.305436 160.033707 227.230209 171.431424 213.380663 171.431424"
          />
          <polygon
            className={attrClassName.tri3}
            stroke="url(#linearGradient-19)"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(581.543590, 118.109875) rotate(625.000000) translate(-581.543590, -118.109875) "
            points="581.54359 109.379406 592.618297 126.840344 570.468883 126.840344"
          />
          <polygon
            className={attrClassName.tri2}
            stroke="url(#linearGradient-19)"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(5.269588, 263.287841) rotate(302.000000) translate(-5.269588, -263.287841) "
            points="5.26958789 260.274696 8.93090793 266.300986 1.60826785 266.300986"
          />
          <polygon
            className={attrClassName.tri1}
            stroke="url(#linearGradient-19)"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(424.618708, 88.868189) rotate(334.000000) translate(-424.618708, -88.868189) "
            points="424.618708 84.4715881 429.961087 93.2647895 419.276329 93.2647895"
          />
          <circle className={attrClassName.oval} cx="415.6625" cy="242.310059" r="2.68994141" />
        </g>
        <g id="dynatrace" transform="translate(100.000000, 286.000000)">
          <g id="dynatrace-box">
            <use
              fill="black"
              fillOpacity="1"
              filter="url(#partners-boxes-filter-21)"
              xlinkHref="#partners-boxes-path-20"
            />
            <use fill="#F6FBFA" fillRule="evenodd" xlinkHref="#partners-boxes-path-20" />
          </g>
          <image x="12" y="11" width="125" height="52" xlinkHref="/images/partners/dynatrace.png" />
        </g>
        <g id="sumo-logic" transform="translate(20.000000, 200.000000)">
          <g id="sumo-logic-box">
            <use
              fill="black"
              fillOpacity="1"
              filter="url(#partners-boxes-filter-23)"
              xlinkHref="#partners-boxes-path-22"
            />
            <use fill="#F6FBFA" fillRule="evenodd" xlinkHref="#partners-boxes-path-22" />
          </g>
          <image
            x="20.8"
            y="24"
            width="153.4"
            height="28"
            xlinkHref="/images/partners/sumo_logic.png"
          />
        </g>
        <g id="docker" transform="translate(333.000000, 82.000000)">
          <g id="docker-box">
            <use
              fill="black"
              fillOpacity="1"
              filter="url(#partners-boxes-filter-25)"
              xlinkHref="#partners-boxes-path-24"
            />
            <use fill="#F6FBFA" fillRule="evenodd" xlinkHref="#partners-boxes-path-24" />
          </g>
          <image
            x="11.2"
            y="11.05"
            width="204.6"
            height="48.9"
            xlinkHref="/images/partners/docker.png"
          />
        </g>
        <g id="appdynamics" transform="translate(97.000000, 105.000000)">
          <g id="appdynamics-box" opacity="0.917991419">
            <use
              fill="black"
              fillOpacity="1"
              filter="url(#partners-boxes-filter-27)"
              xlinkHref="#partners-boxes-path-26"
            />
            <use fill="#F6FBFA" fillRule="evenodd" xlinkHref="#partners-boxes-path-26" />
          </g>
          <image
            x="20"
            y="-4"
            width="172"
            height="64"
            xlinkHref="/images/partners/appdynamics.png"
          />
        </g>
        <g id="splunk" transform="translate(364.000000, 178.000000)">
          <g id="splunk-box">
            <use
              fill="black"
              fillOpacity="1"
              filter="url(#partners-boxes-filter-29)"
              xlinkHref="#partners-boxes-path-28"
            />
            <use fill="#F6FBFA" fillRule="evenodd" xlinkHref="#partners-boxes-path-28" />
          </g>
          <image
            x="22.2977582"
            y="10.7936038"
            width="140.5"
            height="54.625"
            xlinkHref="/images/partners/splunk.png"
          />
        </g>
        <g id="cloudability" transform="translate(186.000000, 156.000000)">
          <g id="cloudability-box">
            <use
              fill="black"
              fillOpacity="1"
              filter="url(#partners-boxes-filter-31)"
              xlinkHref="#partners-boxes-path-30"
            />
            <use fill="#F6FBFA" fillRule="evenodd" xlinkHref="#partners-boxes-path-30" />
          </g>
          <image
            x="56.2617188"
            y="5.5"
            width="70"
            height="70"
            xlinkHref="/images/partners/cloudability.png"
          />
        </g>
        <g id="cloudendure" transform="translate(316.000000, 250.000000)">
          <g id="cloudendure-box">
            <use
              fill="black"
              fillOpacity="1"
              filter="url(#partners-boxes-filter-33)"
              xlinkHref="#partners-boxes-path-32"
            />
            <use fill="#F6FBFA" fillRule="evenodd" xlinkHref="#partners-boxes-path-32" />
          </g>
          <image
            x="9.31415961"
            y="15.66"
            width="226.768"
            height="39.68"
            xlinkHref="/images/partners/cloudendure.png"
          />
        </g>
        <g id="alert-logic" transform="translate(172.000000, 16.000000)">
          <g id="alert-logic-box">
            <use
              fill="black"
              fillOpacity="1"
              filter="url(#partners-boxes-filter-35)"
              xlinkHref="#partners-boxes-path-34"
            />
            <use fill="#F6FBFA" fillRule="evenodd" xlinkHref="#partners-boxes-path-34" />
          </g>
          <image
            x="29.92"
            y="15.46"
            width="208.16"
            height="40.08"
            xlinkHref="/images/partners/alert_logic.png"
          />
        </g>
        <g id="cloudcheckr" transform="translate(267.000000, 344.000000)">
          <g id="cloudcheckr-box">
            <use
              fill="black"
              fillOpacity="1"
              filter="url(#partners-boxes-filter-37)"
              xlinkHref="#partners-boxes-path-36"
            />
            <use fill="#F6FBFA" fillRule="evenodd" xlinkHref="#partners-boxes-path-36" />
          </g>
          <image
            x="17.6765223"
            y="19.9751019"
            width="208.4"
            height="32.1285141"
            xlinkHref="/images/partners/cloudcheckr.png"
          />
        </g>
      </g>
    </g>
  </svg>
)

PartnersBoxes.defaultProps = {
  className: '',
  attrClassName: {
    tri: 'tri',
    tri1: 'tri1',
    tri2: 'tri2',
    tri3: 'tri3',
    tri4: 'tri4',
    oval: 'oval',
  },
}

PartnersBoxes.propTypes = {
  className: PropTypes.string,
  attrClassName: PropTypes.shape({
    tri: PropTypes.string,
    tri1: PropTypes.string,
    tri2: PropTypes.string,
    tri3: PropTypes.string,
    tri4: PropTypes.string,
    oval: PropTypes.string,
  }),
}

export default PartnersBoxes
