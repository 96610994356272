import React from 'react'
import PropTypes from 'prop-types'

import GoogleCloudLogo from '../../../../static/images/clouds/google-cloud-logo.png'
import GoogleCloudImage from '../../../../static/images/clouds/google-cloud.png'

const GoogleCloud = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1519"
    height="373"
    viewBox="0 0 1519 373"
  >
    <defs>
      <linearGradient id="googlecloud-a" x1="12.453%" x2="87.895%" y1="50.566%" y2="49.454%">
        <stop offset="0%" stopColor="#EC3E2B" />
        <stop offset="100%" stopColor="#F94E3C" />
      </linearGradient>
      <filter
        id="googlecloud-b"
        width="112.8%"
        height="200.4%"
        x="-6.4%"
        y="-50.2%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
      <linearGradient id="googlecloud-c" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#4086F7" />
        <stop offset="100%" stopColor="#5A99FF" />
      </linearGradient>
      <filter
        id="googlecloud-d"
        width="114.4%"
        height="226.7%"
        x="-7.2%"
        y="-63.3%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
      <linearGradient id="googlecloud-e" x1="69.006%" x2="40.874%" y1="48.349%" y2="49.564%">
        <stop offset="0%" stopColor="#5ED488" />
        <stop offset="100%" stopColor="#30A950" />
      </linearGradient>
      <filter
        id="googlecloud-f"
        width="110.8%"
        height="154.2%"
        x="-5.4%"
        y="-27.1%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
      <linearGradient id="googlecloud-g" x1="12.453%" x2="87.895%" y1="63.456%" y2="37.027%">
        <stop offset="0%" stopColor="#FCBB00" />
        <stop offset="98.375%" stopColor="#F1BC24" />
        <stop offset="100%" stopColor="#FFC671" />
      </linearGradient>
      <filter id="googlecloud-h" height="132.3%" y="-16.1%" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
      <filter
        id="googlecloud-i"
        width="112.8%"
        height="200.4%"
        x="-6.4%"
        y="-50.2%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
      <filter
        id="googlecloud-j"
        width="114.4%"
        height="226.7%"
        x="-7.2%"
        y="-63.3%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
      <filter
        id="googlecloud-k"
        width="110.8%"
        height="154.2%"
        x="-5.4%"
        y="-27.1%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
      <filter id="googlecloud-l" height="132.3%" y="-16.1%" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
      <linearGradient id="googlecloud-m" x1="7.122%" x2="46.66%" y1="50.335%" y2="50.646%">
        <stop offset="0%" stopColor="#3065BB" />
        <stop offset="100%" stopColor="#5A99FF" />
      </linearGradient>
      <linearGradient id="googlecloud-n" x1="12.453%" x2="87.895%" y1="56.915%" y2="43.333%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <linearGradient id="googlecloud-o" x1="30.314%" x2="69.869%" y1="84.924%" y2="16.33%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <linearGradient id="googlecloud-p" x1="12.453%" x2="87.895%" y1="53.321%" y2="46.798%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <linearGradient id="googlecloud-q" x1="7.122%" x2="46.66%" y1="51.664%" y2="53.205%">
        <stop offset="0%" stopColor="#3065BB" />
        <stop offset="100%" stopColor="#5A99FF" />
      </linearGradient>
      <filter
        id="googlecloud-r"
        width="107.3%"
        height="128.8%"
        x="-3.6%"
        y="-14.4%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="2" />
      </filter>
      <linearGradient id="googlecloud-s" x1="12.453%" x2="87.895%" y1="52.105%" y2="47.971%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <linearGradient id="googlecloud-t" x1="12.453%" x2="87.895%" y1="51.044%" y2="48.993%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <linearGradient id="googlecloud-u" x1="12.453%" x2="87.895%" y1="51.582%" y2="48.474%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <linearGradient id="googlecloud-v" x1="12.453%" x2="87.895%" y1="52.238%" y2="47.843%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <linearGradient id="googlecloud-w" x1="12.453%" x2="87.895%" y1="50.224%" y2="49.784%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(0 9)">
      <g opacity=".358" transform="rotate(-5 2382.667 57.385)">
        <polygon
          fill="url(#googlecloud-a)"
          points=".989 59.768 318.698 43.903 470.262 103.652 284.22 63.337"
          filter="url(#googlecloud-b)"
          transform="rotate(2 235.625 73.778)"
        />
        <polygon
          fill="url(#googlecloud-c)"
          points="485.226 131.992 836.823 94.722 668.925 84.62 468.529 117.381 420.017 106.843"
          filter="url(#googlecloud-d)"
          transform="rotate(2 628.42 108.306)"
        />
        <polygon
          fill="url(#googlecloud-e)"
          points="668.636 95.745 1021.945 66.81 1200.905 147.887 1225.236 177.458 986.172 95.745 830.584 105.847"
          filter="url(#googlecloud-f)"
          transform="rotate(2 946.936 122.134)"
        />
        <polygon
          fill="url(#googlecloud-g)"
          points="1226.302 191.568 1201.971 161.996 1392.747 42.068 1501.319 5.755 1381.79 70.97"
          filter="url(#googlecloud-h)"
          transform="rotate(2 1351.645 98.661)"
        />
      </g>
      <g opacity=".297" transform="rotate(-11 1494.351 118.642)">
        <polygon
          fill="url(#googlecloud-a)"
          points=".989 59.768 318.698 43.903 470.262 103.652 284.22 63.337"
          filter="url(#googlecloud-i)"
          opacity=".358"
          transform="rotate(2 235.625 73.778)"
        />
        <polygon
          fill="url(#googlecloud-c)"
          points="485.226 131.992 836.823 94.722 668.925 84.62 468.529 117.381 420.017 106.843"
          filter="url(#googlecloud-j)"
          opacity=".358"
          transform="rotate(2 628.42 108.306)"
        />
        <polygon
          fill="url(#googlecloud-e)"
          points="668.636 95.745 1021.945 66.81 1200.905 147.887 1225.236 177.458 986.172 95.745 830.584 105.847"
          filter="url(#googlecloud-k)"
          opacity=".358"
          transform="rotate(2 946.936 122.134)"
        />
        <polygon
          fill="url(#googlecloud-g)"
          points="1226.302 191.568 1201.971 161.996 1392.747 42.068 1501.319 5.755 1381.79 70.97"
          filter="url(#googlecloud-l)"
          opacity=".358"
          transform="rotate(2 1351.645 98.661)"
        />
      </g>
      <g transform="translate(0 117)">
        <polygon
          fill="url(#googlecloud-a)"
          points="0 92.978 317.71 77.113 469.273 136.861 283.232 96.547"
        />
        <polygon
          fill="url(#googlecloud-m)"
          points="485.203 151.472 836.801 114.202 668.902 104.1 468.507 136.861 419.994 126.323"
        />
        <polygon
          fill="url(#googlecloud-e)"
          points="668.902 104.1 1022.211 75.165 1201.171 156.242 1225.502 185.814 986.437 104.1 830.849 114.202"
        />
        <polygon
          fill="url(#googlecloud-g)"
          points="1225.502 185.814 1201.171 156.242 1391.947 36.313 1500.519 0 1380.99 65.215"
        />
      </g>
      <image width="475.78" height="262.131" x="544.64" y="101.707" xlinkHref={GoogleCloudImage} />
      <g transform="translate(169.751 95.355)">
        <image width="153.901" height="98" x="545.389" y="46.295" xlinkHref={GoogleCloudLogo} />
        <polygon
          fill="url(#googlecloud-n)"
          points="455.988 101.999 476.968 137.566 598.061 165.22 492.591 133.213"
        />
        <polygon
          fill="url(#googlecloud-o)"
          points="693.78 34.532 736.216 83.755 714.998 153.661 750.206 72.571 706.468 34.532 652.036 18.085"
        />
        <polygon
          fill="url(#googlecloud-p)"
          points="633.159 194.318 733.397 178.187 792.755 145.105 753.214 181.016"
        />
        <polygon
          fill="url(#googlecloud-q)"
          points="543.021 14 480.482 14.565 378.384 55.68 468.646 28.369 531.576 27.812"
          filter="url(#googlecloud-r)"
          opacity=".409"
          transform="rotate(10 460.703 34.84)"
        />
        <polygon
          fill="url(#googlecloud-s)"
          points="545.787 28.611 480.411 14.807 378.313 55.922 468.575 28.611 531.507 40.387"
        />
        <polygon fill="url(#googlecloud-t)" points="0 136.212 109.285 112.906 134.784 119.515" />
        <polygon
          fill="url(#googlecloud-u)"
          points="1053.547 117.389 1168.027 88.739 1188.142 99.361"
        />
        <polygon
          fill="url(#googlecloud-v)"
          points="971.744 200.745 1080.388 220.511 1129.035 197.767 1072.142 237.58"
        />
        <polygon
          fill="url(#googlecloud-w)"
          points="289.707 143.329 345.629 135.966 393.797 144.295 342.185 139.472"
        />
      </g>
    </g>
  </svg>
)

GoogleCloud.defaultProps = {
  className: '',
}

GoogleCloud.propTypes = {
  className: PropTypes.string,
}

export default GoogleCloud
