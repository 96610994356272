import React, { useState } from 'react'
import Modal from 'observian-ui/lib/modal'
import Button from 'observian-ui/lib/button'

import ContactForm from 'components/Form/ContactAppD'
import HubspotMeeting from 'components/Hubspot/MeetingPlugin'

import style from './contact.module.css'

const ContactInfo = () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <section className={style.container}>
        <div className={style.contact}>
          <img
            className={style.contactTitle}
            src="/images/partners/appdynamics-part-of-cisco.png"
            alt="AppDynamics Part of Cisco"
          />
          <svg
            className={style.topLaser}
            xmlns="http://www.w3.org/2000/svg"
            width="543"
            height="153"
            viewBox="0 0 543 153"
          >
            <defs>
              <linearGradient
                id="top-form-laser-a"
                x1="12.453%"
                x2="87.895%"
                y1="84.924%"
                y2="16.33%"
              >
                <stop offset="0%" stopColor="#1D8DBA" />
                <stop offset="100%" stopColor="#03ECAE" />
              </linearGradient>
              <filter
                id="top-form-laser-b"
                width="111.6%"
                height="173.1%"
                x="-5.8%"
                y="-36.6%"
                filterUnits="objectBoundingBox"
              >
                <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
              </filter>
            </defs>
            <g fill="url(#top-form-laser-a)" transform="translate(4 16)">
              <polygon
                points="521.165 78.973 416.054 18 2.23 100.047 370.516 49.801 415.775 71.99"
                filter="url(#top-form-laser-b)"
                opacity=".323"
                transform="rotate(-4 261.698 59.023)"
              />
              <polygon points="521.932 67.973 416.053 17.973 2.229 100.02 370.515 49.774 416.053 67.973" />
            </g>
          </svg>
          <ContactForm
            className={style.form}
            ctaIntentSuffix="page"
            headerTitle="Offer Registration"
            buttonLabel="Get Free Licenses"
          />
          <div className={style.contactInfo}>
            <img
              className={style.appdLogo}
              src="/images/partners/appdynamics-logo.png"
              alt="AppDynamics"
            />
            <h3 className={style.title}>Get free access to AppDynamics</h3>
            <div className={style.description}>
              <p>
                We’re giving you the application performance monitoring solution you need to manage
                mission-critical operations during these challenging times. Sign up and:
              </p>
              <p className={style.bold}>Get access to the #1 APM solution on the market</p>
              <p className={style.bold}>
                Find and fix application performance problems in real-time
              </p>
              <p className={style.bold}>Secure up to 100 free licenses</p>
            </div>
          </div>
          <svg
            className={style.bottomEdge}
            xmlns="http://www.w3.org/2000/svg"
            width="624"
            height="66"
            viewBox="0 0 624 66"
          >
            <defs>
              <linearGradient id="bottomedge" x1="100%" x2="0%" y1="50%" y2="50%">
                <stop offset="0%" stopColor="#F6FBFA" />
                <stop offset="50%" stopColor="#F6FBFA" />
                <stop offset="50%" stopColor="#FFFFFF" />
                <stop offset="100%" stopColor="#FFFFFF" />
              </linearGradient>
            </defs>
            <path
              fill="url(#bottomedge)"
              d="M-1.54098956e-13,0 L624,0 L624,53.0333555 C624,54.6902098 622.656854,56.0333555 621,56.0333555 C620.876131,56.0333555 620.75238,56.0256837 620.629459,56.0103842 L504.479385,41.5535796 L332.239491,38.8944625 L104.379582,66 L1.74140405,18.56354 C0.679723123,18.0728612 -1.54098956e-13,17.0099047 -1.54098956e-13,15.8403184 L-1.54098956e-13,0 Z"
            />
          </svg>
          <svg
            className={style.bottomLaser}
            xmlns="http://www.w3.org/2000/svg"
            width="465"
            height="138"
            viewBox="0 0 465 138"
          >
            <defs>
              <linearGradient
                id="bottom-form-laser-a"
                x1="12.453%"
                x2="87.895%"
                y1="84.924%"
                y2="16.33%"
              >
                <stop offset="0%" stopColor="#1D8DBA" />
                <stop offset="100%" stopColor="#03ECAE" />
              </linearGradient>
              <filter
                id="bottom-form-laser-b"
                width="110%"
                height="146.1%"
                x="-5%"
                y="-23%"
                filterUnits="objectBoundingBox"
              >
                <feGaussianBlur in="SourceGraphic" stdDeviation="7.5" />
              </filter>
            </defs>
            <g fill="url(#bottom-form-laser-a)" transform="translate(6 3)">
              <polygon
                points="3.557 76.454 215.565 117.347 451.588 19.653 215.565 90.532"
                filter="url(#bottom-form-laser-b)"
                opacity=".5"
                transform="rotate(-2 227.572 68.5)"
              />
              <polygon
                points="3.557 76.454 215.565 117.347 451.588 19.653 215.565 90.532"
                transform="rotate(-5 227.572 68.5)"
              />
            </g>
          </svg>
        </div>
      </section>
      <section className={style.moreInfo}>
        <div className={style.info}>
          <h3>Have a unique use case you’d like to discuss?</h3>
          <p>
            Our experts are happy to setup a phone call to discuss any unique applications you have
            questions on.
          </p>
        </div>
        <Button label="Schedule a Call" type="secondary" onClick={() => setShowModal(!showModal)} />
      </section>
      {showModal ? (
        <Modal isTransparent isOpen={showModal} onClose={() => setShowModal(!showModal)}>
          <HubspotMeeting />
        </Modal>
      ) : null}
    </>
  )
}

ContactInfo.defaultProps = {}

ContactInfo.propTypes = {}

export default ContactInfo
