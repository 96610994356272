import React from 'react'
import PropTypes from 'prop-types'

import BGLines from 'components/SVG/backgroundLines'

import style from './pageTemplate.module.css'

const PageTemplate = ({ children }) => (
  <div className={style.pageTemplate}>
    <BGLines className={style.lines} />
    <div className={style.background}>
      <div className={style.content}>{children}</div>
    </div>
  </div>
)

PageTemplate.defaultProps = {}

PageTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default PageTemplate
