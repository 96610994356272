import React from 'react'
import PropTypes from 'prop-types'

const NavigationDivider = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="133"
    height="303"
    viewBox="0 0 133 303"
  >
    <defs>
      <linearGradient id="navdivider-a" x1="49.264%" x2="50.743%" y1="84.924%" y2="16.33%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <filter
        id="navdivider-b"
        width="173.1%"
        height="110.2%"
        x="-36.5%"
        y="-5.1%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
      </filter>
      <filter
        id="navdivider-c"
        width="173.1%"
        height="110.2%"
        x="-36.5%"
        y="-5.1%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
      </filter>
    </defs>
    <g fill="url(#navdivider-a)" fillRule="evenodd" transform="translate(-9 4)">
      <polygon
        points="72.934 1.5 47 63.941 75.053 148.112 60.714 247.553 86.879 294.725 67.408 244.256 88.059 145.285 52.262 63.941"
        filter="url(#navdivider-b)"
        opacity=".1"
        transform="rotate(-19 67.53 148.112)"
      />
      <polygon
        points="72.934 1.5 47 63.941 75.053 148.112 60.714 247.553 86.879 294.725 67.408 244.256 88.059 145.285 52.262 63.941"
        filter="url(#navdivider-c)"
        opacity=".463"
        transform="rotate(-7 67.53 148.112)"
      />
      <polygon points="72.934 1.5 47 63.941 75.053 148.112 60.714 247.553 86.879 294.725 67.408 244.256 88.059 145.285 52.262 63.941" />
    </g>
  </svg>
)

NavigationDivider.defaultProps = {
  className: '',
}

NavigationDivider.propTypes = {
  className: PropTypes.string,
}

export default NavigationDivider
