import React from 'react'
import PropTypes from 'prop-types'
import Button from 'observian-ui/lib/button'
import Logo from 'observian-ui/lib/logo'
import ContactIcon from 'observian-ui/lib/svg/icons/contact'
import { Form as FinalForm, Field } from 'react-final-form'
import clsx from 'clsx'

import submitForm, { specialEventsForm, googleWorkshopForm } from 'api/form'
import HubspotTracking from 'components/Hubspot/Tracking'
import { isRequired, isEmail, isPhoneNumber } from 'utils/validation'

import style from './form.module.css'

class Form extends React.Component {
  state = {
    isSuccessful: false,
  }

  handleOnSubmit = async event => {
    const { inquiryPlaceholder, ctaIntentSuffix, isSpecialEvent, formId, isGoogleForm } = this.props

    const inquiry = undefined === event.inquiry ? inquiryPlaceholder : event.inquiry
    const ctaIntent =
      0 === ctaIntentSuffix.length ? event.ctaIntent : `${event.ctaIntent}-${ctaIntentSuffix}`

    const allFormData = {
      ...event,
      inquiry,
      ctaIntent,
    }

    try {
      let res = {}

      if (isGoogleForm) {
        res = await googleWorkshopForm({ ...allFormData, formId })
      }

      if (isSpecialEvent) {
        res = await specialEventsForm({ ...allFormData, formId })
      }

      if (!isSpecialEvent) {
        res = await submitForm({ ...allFormData })
      }

      if (200 === res.status) {
        return this.renderSubmission()
      }

      return console.error(res.data.message || 'Unknown Error.')
    } catch (error) {
      return console.error(error)
    }
  }

  handleOnDismiss = () => {
    const { onDismiss } = this.props

    if (onDismiss) {
      this.renderSubmission(false)

      return onDismiss()
    }

    return this.renderSubmission(false)
  }

  renderSubmission = (value = true) => {
    this.setState({
      isSuccessful: value,
    })
  }

  render() {
    const {
      props: {
        buttonLabel,
        inquiryPlaceholder,
        isSuccessfulMessage,
        title,
        ctaIntent,
        showLogo,
        isGoogleForm,
      },
      state: { isSuccessful },
    } = this

    return (
      <HubspotTracking>
        {({ hsContext }) => (
          <>
            {!isSuccessful ? (
              <FinalForm
                validateOnBlur
                onSubmit={e => this.handleOnSubmit({ ...e, hsContext })}
                initialValues={{ ctaIntent }}
              >
                {({ handleSubmit, pristine, submitting }) => (
                  <form className={style.form} onSubmit={handleSubmit}>
                    <header className={style.header}>
                      <h6>{title}</h6>
                      {showLogo ? (
                        <Logo
                          darkLogo
                          showText
                          darkText
                          className={style.logo}
                          classNames={{ logo: style.icon, text: style.iconText }}
                        />
                      ) : null}
                    </header>
                    <div className={style.name}>
                      <Field name="firstName" placeholder="First Name" validate={isRequired}>
                        {({ input, meta, placeholder }) => (
                          <div className={style.singleRow}>
                            <ContactIcon type="person" className={style.contactIcon} />
                            <input {...input} placeholder={placeholder} id={input.name} />
                            {meta.error && meta.touched && (
                              <span
                                data-testid="form-firstName-error"
                                className={style.validationError}
                              >
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="lastName" placeholder="Last Name" validate={isRequired}>
                        {({ input, meta, placeholder }) => (
                          <div className={style.singleRow}>
                            <input {...input} placeholder={placeholder} id={input.name} />
                            {meta.error && meta.touched && (
                              <span
                                data-testid="form-lastName-error"
                                className={style.validationError}
                              >
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <Field name="company" placeholder="Company Name">
                      {({ input, placeholder }) => (
                        <div className={style.inputContainer}>
                          <ContactIcon type="building" className={style.contactIcon} />
                          <input {...input} placeholder={placeholder} id={input.name} />
                        </div>
                      )}
                    </Field>
                    <div className={style.contact}>
                      <Field name="email" placeholder="Email" validate={isEmail}>
                        {({ input, meta, placeholder }) => (
                          <div className={clsx(style.inputContainer, style.singleRow)}>
                            <ContactIcon type="email" className={style.contactIcon} />
                            <input
                              {...input}
                              placeholder={placeholder}
                              id={input.name}
                              type="email"
                            />
                            {meta.error && meta.touched && (
                              <span
                                data-testid="form-email-error"
                                className={style.validationError}
                              >
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="phone" placeholder="Phone Number" validate={isPhoneNumber}>
                        {({ input, meta, placeholder }) => (
                          <div className={clsx(style.inputContainer, style.singleRow)}>
                            <ContactIcon
                              type="phone"
                              className={clsx(style.contactIcon, style.phoneNumber)}
                            />
                            <input
                              {...input}
                              placeholder={placeholder}
                              id={input.name}
                              type="tel"
                            />
                            {meta.error && meta.dirty && meta.touched && (
                              <span
                                data-testid="form-phoneNumber-error"
                                className={style.validationError}
                              >
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    {isGoogleForm ? (
                      <Field name="workshopTeamSize" placeholder="Workshop Team Size">
                        {({ input, meta, placeholder }) => (
                          <div className={style.inputContainer}>
                            <input
                              {...input}
                              placeholder={placeholder}
                              id={input.name}
                              type="number"
                            />
                            {meta.error && meta.dirty && meta.touched && (
                              <span
                                data-testid="form-teamSize-error"
                                className={style.validationError}
                              >
                                {meta.error}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    ) : null}
                    {isGoogleForm ? (
                      <Field name="workshopNeeds" placeholder={inquiryPlaceholder}>
                        {({ input, placeholder }) => (
                          <div className={style.inputContainer}>
                            <ContactIcon type="info" className={style.contactIcon} />
                            <textarea {...input} placeholder={placeholder} id={input.name} />
                          </div>
                        )}
                      </Field>
                    ) : (
                      <Field name="inquiry" placeholder={inquiryPlaceholder}>
                        {({ input, placeholder }) => (
                          <div className={style.inputContainer}>
                            <ContactIcon type="info" className={style.contactIcon} />
                            <textarea {...input} placeholder={placeholder} id={input.name} />
                          </div>
                        )}
                      </Field>
                    )}
                    <Field name="ctaIntent" type="hidden">
                      {({ input }) => <input {...input} type="hidden" />}
                    </Field>
                    <div className={style.buttonContainer}>
                      <Button
                        variant="primary"
                        label={buttonLabel}
                        className={style.buttonCTA}
                        disabled={pristine || submitting}
                      />
                    </div>
                  </form>
                )}
              </FinalForm>
            ) : (
              <div className={style.success}>
                <span data-testid="form-success-message">{isSuccessfulMessage}</span>
                <Button
                  variant="cancel"
                  label="Done"
                  onClick={this.handleOnDismiss}
                  className={style.successButtonCTA}
                />
              </div>
            )}
          </>
        )}
      </HubspotTracking>
    )
  }
}

Form.defaultProps = {
  inquiryPlaceholder:
    'Hi! We are currently in the cloud and would like to talk about how we can optimize our implementation.',
  buttonLabel: 'Send',
  onDismiss: () => {},
  isSuccessfulMessage: "Your inquiry has been sent, we'll be in touch soon!",
  ctaIntent: 'contact-form',
  ctaIntentSuffix: '',
  title: 'Contact Form',
  inquiryTitle: 'How can we help you?',
  isSpecialEvent: false,
  formId: null,
  showLogo: true,
  isGoogleForm: false,
}

Form.propTypes = {
  inquiryPlaceholder: PropTypes.string,
  buttonLabel: PropTypes.string,
  onDismiss: PropTypes.func,
  isSuccessfulMessage: PropTypes.string,
  ctaIntent: PropTypes.string,
  ctaIntentSuffix: PropTypes.string,
  title: PropTypes.string,
  inquiryTitle: PropTypes.string,
  isSpecialEvent: PropTypes.bool,
  formId: PropTypes.string,
  showLogo: PropTypes.bool,
  isGoogleForm: PropTypes.bool,
}

export default Form
