import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Img from 'gatsby-image'
import camelCase from 'lodash/camelCase'

import style from './sponsors.module.css'

const Sponsors = ({ sponsors }) => (
  <div className={style.container}>
    <div className={style.contentContainer}>
      <h3 className={style.title}>Event Sponsors</h3>
      <svg
        className={style.topRightEdgeLaser}
        xmlns="http://www.w3.org/2000/svg"
        width="291"
        height="101"
        viewBox="0 0 291 101"
      >
        <defs>
          <linearGradient
            id="top-right-edge-laser-a"
            x1="15.436%"
            x2="114.319%"
            y1="90.518%"
            y2="21.413%"
          >
            <stop offset="0%" stopColor="#172A3F" />
            <stop offset="100%" stopColor="#344960" />
          </linearGradient>
        </defs>
        <polygon
          fill="url(#top-right-edge-laser-a)"
          points="0 0 206.135 0 290.767 101.102 195.224 13.983"
          opacity=".138"
        />
      </svg>
      <div className={style.sponsorsContainer}>
        <div className={clsx(style.content, style[`length${sponsors.length}`])}>
          {sponsors.map(sponsor => (
            <div key={`sponsor:${sponsor.name}`} className={style.section}>
              <div className={clsx(style.image, style[camelCase(sponsor.name)])}>
                <Img className={style.logo} fluid={sponsor.image.fluid} />
              </div>
              <div className={style.description}>{sponsor.description.description}</div>
            </div>
          ))}
        </div>
      </div>
      <svg
        className={style.bottomLeftEdgeLaser}
        xmlns="http://www.w3.org/2000/svg"
        width="414"
        height="158"
        viewBox="0 0 414 158"
      >
        <defs>
          <linearGradient
            id="bottom-left-edge-laser-a"
            x1="15.436%"
            x2="114.319%"
            y1="90.518%"
            y2="21.413%"
          >
            <stop offset="0%" stopColor="#172A3F" />
            <stop offset="100%" stopColor="#344960" />
          </linearGradient>
        </defs>
        <polygon
          fill="url(#bottom-left-edge-laser-a)"
          points="13.954 75.767 101.95 136.222 414.312 90.094 77.757 157.358 0 66.975 11.497 0"
          opacity=".175"
        />
      </svg>
    </div>
  </div>
)

Sponsors.defaultProps = {
  sponsors: [],
}

Sponsors.propTypes = { sponsors: PropTypes.array }

export default Sponsors
