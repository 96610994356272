import React from 'react'
import { gsap, Elastic } from 'gsap'

import Cloud from 'components/SVG/cloud'
import CertCount from 'components/SVG/certificationCount'

import DoCloudBetter from './DoCloudBetter'
import style from './hero.module.css'

class Hero extends React.Component {
  componentDidMount() {
    const tl = gsap.timeline({ paused: false, repeat: -1 }) // eslint-disable-line
    const tl2 = gsap.timeline({ paused: false, repeat: -1, yoyo: true }) // eslint-disable-line

    tl.fromTo(
      `.${style.awsCount}`,
      { y: -20, opacity: 0, fillOpacity: 0 },
      { y: 0, opacity: 1, fillOpacity: 1, duration: 1 },
    )
    tl.fromTo(
      `.${style.awsTitle}`,
      { y: 20, opacity: 0, fillOpacity: 0 },
      { y: 0, opacity: 1, fillOpacity: 1, duration: 2 },
    )
    tl.delay(3)
    tl.to(`.${style.awsCount}, .${style.awsTitle}`, { opacity: 0, fillOpacity: 0, duration: 1 })

    tl.fromTo(
      `.${style.employeeCount}`,
      { y: -20, opacity: 0, fillOpacity: 0 },
      { y: 0, opacity: 1, fillOpacity: 1, duration: 1 },
    )
    tl.fromTo(
      `.${style.employeeTitle}`,
      { y: 20, opacity: 0, fillOpacity: 0 },
      { y: 0, opacity: 1, fillOpacity: 1, duration: 2 },
    )
    tl.delay(2)
    tl.to(`.${style.employeeCount}, .${style.employeeTitle}`, { opacity: 0, duration: 1 })

    gsap.from(`.${style.certCount}`, { y: 200, duration: 2 })

    tl2
      .to(`.${style.circle}`, {
        rotation: 360,
        transformOrigin: '50% 50%',
        ease: Elastic.easeInOut.config(1, 0.4),
        duration: 16,
      })
      .to(
        `.${style.circle1}`,
        {
          rotation: 360,
          transformOrigin: '50% 50%',
          ease: Elastic.easeInOut.config(1, 0.6),
          duration: 16,
        },
        '-=16',
      )

    gsap.from(`.${style.cloud}`, { x: 50, y: '-=20', duration: 5 })
  }

  render() {
    return (
      <div className={style.hero}>
        <div className={style.content}>
          <svg className={style.spokes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1111 1112">
            <path
              fill="none"
              stroke="#157494"
              strokeDasharray="2.656 39.848"
              strokeWidth="100"
              d="M505.57052,1011.14104 C784.789408,1011.14104 1011.14104,784.789408 1011.14104,505.57052 C1011.14104,226.351632 784.789408,0 505.57052,0 C226.351632,0 0,226.351632 0,505.57052 C0,784.789408 226.351632,1011.14104 505.57052,1011.14104 Z"
              opacity="0.1"
              transform="translate(50 50)"
            />
          </svg>
          <CertCount
            type="aws"
            className={style.certCount}
            attrClassNames={{
              awsCount: style.awsCount,
              awsTitle: style.awsTitle,
              employeeCount: style.employeeCount,
              employeeTitle: style.employeeTitle,
              circle: style.circle,
              circle1: style.circle1,
            }}
          />
          <Cloud type="outline" className={style.cloud} />
        </div>
        <DoCloudBetter />
      </div>
    )
  }
}

Hero.defaultProps = {}

Hero.propTypes = {}

export default Hero
