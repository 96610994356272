const queryWebinar = ({ webinarId }) => `
  query GetWebinar {
    webinar(conferenceId: "${webinarId}") {
      title
      purpose
      startTime
      duration
      conferenceAddress
    }
  }
`

export default queryWebinar
