import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FeaturedBlogPostCard } from 'observian-ui/lib/blog-post-card'
import { navigate } from 'gatsby'
import map from 'lodash/map'
import Divider from 'observian-ui/lib/divider'
import RecentWebinars from 'observian-ui/lib/recent-webinars'
import SVGInput from 'observian-ui/lib/input/svgInput'
import Dropdown from 'observian-ui/lib/dropdown'
import { Form as FinalForm, Field } from 'react-final-form'
import Contact from 'observian-ui/lib/svg/icons/contact'
import cx from 'clsx'
import Card from 'observian-ui/lib/card'
import Button from 'observian-ui/lib/button'
import sortBy from 'lodash/sortBy'

import BlogLaser from 'components/SVG/lasers/blog'
import TechLines from 'components/SVG/techLines'
import Cloud from 'components/SVG/cloud'
import { filterBlogPostsByTopic } from 'utils/blogTransform'
import BlogPagination from 'components/Page/Blog/Pagination'
import { isEmail } from 'utils/validation'
import newsletter from 'api/newsletter'
import HubspotTracking from 'components/Hubspot/Tracking'

import style from './blog.module.css'

const Hero = ({ posts, webinars, topics, members, ctaIntent, message }) => {
  const [inputValue, setInputValue] = useState('')
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [topicFilterId, setTopicFilterId] = useState([])

  const handleTopicFilter = (topic = {}) => {
    const topicIdFilter = (topic && [topic.topicId]) || []
    setTopicFilterId(topicIdFilter)
  }

  const handleOnSubmit = async event => {
    try {
      const res = await newsletter({ ...event })

      if (200 === res.status) {
        return setIsSuccessful(true)
      }

      return console.error(res.data.message || 'Unknown Error')
    } catch (error) {
      return console.error(error)
    }
  }

  const filterWebinars = filterBlogPostsByTopic({ posts, topics, topicName: 'webinar' })

  return (
    <section className={style.blog}>
      <div className={style.hero}>
        <span role="presentation" alt="Blog Hero Background Left" className={style.topLeftBG} />
        <TechLines className={style.leftTechLines} />
        <span className={style.allThings}>ALL THINGS</span>
        <Cloud withLasers type="outline" className={style.cloud} />
        <span className={style.cloudText}>CLOUD</span>
        <TechLines className={style.leftCenterTechLines} />
        <TechLines className={style.rightTechLines} />
        <TechLines className={style.middleTechLines} />
        <h1 style={{ display: 'none' }}>All Things Cloud</h1>
        <div className={style.content}>
          <div className={style.postContent}>
            <div className={style.postHeaderText}>Featured Post</div>
            {map(posts.slice(0, 1), post => (
              <FeaturedBlogPostCard
                key={`blog:featured:post:${post.postId}`}
                post={post}
                className={style.blog}
                avatarProps={{
                  forceAspectRatio: post.blogAuthor.isExternalAuthor,
                  withText: true,
                  stackedText: true,
                }}
              />
            ))}
          </div>
          <div className={style.webinarContent}>
            <div className={style.webinarHeaderText}>Webinars</div>
            <RecentWebinars
              className={style.recentWebinars}
              webinars={webinars}
              webinarBlogPosts={filterWebinars}
            />
          </div>
          <SVGInput
            className={style.searchMore}
            placeholder="Search All Content..."
            value={inputValue}
            onChange={({ currentTarget }) => setInputValue(currentTarget.value)}
            onKeyPress={({ currentTarget, key }) =>
              'Enter' === key &&
              navigate('/blog/search', {
                state: { searchQuery: currentTarget.value },
              })
            }
          />
        </div>
        <Divider className={style.divider}>
          <BlogLaser className={style.laser} />
        </Divider>
      </div>
      <div className={style.blogListContainer}>
        <div className={style.blogList}>
          <div className={style.actions}>
            <div className={style.headerText}>All Blog Posts</div>
            <div className={style.filters}>
              <Dropdown
                isClearable
                nameId="all-topics"
                type="primary"
                placeholder="All Topics"
                options={sortBy(topics, topic => topic.name)}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.slug}
                onChange={option => handleTopicFilter(option)}
              />
            </div>
          </div>
          <BlogPagination
            darkText
            className={style.blogArticles}
            members={members}
            topicFilters={topicFilterId}
          />
          <div className={style.subscribe}>
            {isSuccessful ? (
              <p>You&apos;re Subscribed!</p>
            ) : (
              <div className={style.subscribeContent}>
                <div className={style.headerText}>Subscribe</div>
                <HubspotTracking>
                  {({ hsContext }) => (
                    <Card disabled className={style.subscribeCard} isElevated>
                      <FinalForm
                        validateOnBlur
                        onSubmit={e => handleOnSubmit({ ...e, hsContext })}
                        initialValues={{ ctaIntent }}
                      >
                        {({ handleSubmit, pristine, submitting }) => (
                          <div className={style.formContents}>
                            <span className={style.message}>{message}</span>
                            <Contact type="email" className={style.icon} color="#000" />
                            <form className={style.form} onSubmit={handleSubmit}>
                              <Field name="email" type="email" placeholder="" validate={isEmail}>
                                {({ input, meta, placeholder, type }) => (
                                  <div className={style.inputContainer}>
                                    <Contact type="email" className={style.icon} color="white" />
                                    <input
                                      {...input}
                                      placeholder={placeholder}
                                      id={input.name}
                                      type={type}
                                      className={style.input}
                                      aria-label="Enter email to subscribe to Observian blog"
                                    />
                                    {meta.error && meta.touched && (
                                      <span
                                        data-testid="blog-subscribe-email-error"
                                        className={style.validationError}
                                      >
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                              <Field name="ctaIntent" type="hidden">
                                {({ input }) => <input {...input} type="hidden" />}
                              </Field>
                              <Button
                                type="submit"
                                label="Subscribe"
                                variant="secondary"
                                className={cx(
                                  style.buttonCTA,
                                  (pristine || submitting) && style.disabled,
                                )}
                                disabled={pristine || submitting}
                              />
                            </form>
                          </div>
                        )}
                      </FinalForm>
                    </Card>
                  )}
                </HubspotTracking>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

Hero.defaultProps = {
  ctaIntent: 'blog-subscribe',
  message: 'Get notified as soon as new blog posts come up!',
}

Hero.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object).isRequired,
  topics: PropTypes.arrayOf(PropTypes.object).isRequired,
  webinars: PropTypes.arrayOf(PropTypes.object).isRequired,
  members: PropTypes.arrayOf(PropTypes.object).isRequired,
  ctaIntent: PropTypes.string,
  message: PropTypes.string,
}

export default Hero
