import React from 'react'
import Button from 'observian-ui/lib/button'
import Modal from 'observian-ui/lib/modal'

import Form from 'components/Form'

import style from './myCompany.module.css'

class MyCompany extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      primaryModal: false,
      secondaryModal: false,
    }
  }

  handleModal = (context, value) => {
    this.setState(state => ({
      ...state,
      [context]: value,
    }))
  }

  render() {
    const { primaryModal, secondaryModal } = this.state

    return (
      <section className={style.myCompany}>
        <header className={style.header}>
          <h2 className={style.heading}>My company is ready to...</h2>
          <div className={style.buttons}>
            <Button
              variant="secondary"
              label="Move to the cloud"
              className={style.secondaryCTA}
              onClick={() => this.handleModal('secondaryModal', true)}
            />
            {secondaryModal ? (
              <Modal
                isOpen={secondaryModal}
                onClose={() => this.handleModal('secondaryModal', false)}
              >
                <Form
                  onDismiss={() => this.handleModal('secondaryModal', false)}
                  inquiryPlaceholder="Hi! My company or team would love some help moving to the cloud!"
                />
              </Modal>
            ) : null}
            <Button
              variant="primary"
              label="Level up our cloud game"
              className={style.primaryCTA}
              onClick={() => this.handleModal('primaryModal', true)}
            />
            {primaryModal ? (
              <Modal isOpen={primaryModal} onClose={() => this.handleModal('primaryModal', false)}>
                <Form
                  onDismiss={() => this.handleModal('primaryModal', false)}
                  inquiryPlaceholder="Hi! We are currently in the cloud and would like to talk about how we can optimize our implementation."
                />
              </Modal>
            ) : null}
          </div>
        </header>
      </section>
    )
  }
}

export default MyCompany
