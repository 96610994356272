import React from 'react'

import Layout from 'components/Layout'
import { Hero, Contact } from 'components/Page/Contact'

export default () => (
  <Layout
    title="Contact Us"
    description="Contact Us to help you deliver software better"
    keywords="contact,contact us,observian"
  >
    <Hero />
    <Contact />
  </Layout>
)
