import React from 'react'
import { TweenMax, Elastic, TimelineMax } from 'gsap'
import map from 'lodash/map'
import cn from 'clsx'

import style from './doItBetter.module.css'

const words = [
  'Software',
  'DevOps',
  'Security',
  'Cloud',
  'Serverless',
  'Big Data',
  'Containers',
  'Compliance',
  'Docker',
  'Kubernetes',
  'Networking',
]

class DoItBetter extends React.Component {
  componentDidMount() {
    TweenMax.set(`.${style.competency}`, {
      transformOrigin: '50% 50%',
      transformPerspective: 400,
    })
    const tl = new TimelineMax()

    tl.fromTo(
      `.${style.do}`,
      4,
      { left: '-=50', opacity: 0 },
      { left: 0, opacity: 1, ease: Elastic.easeOut.config(1, 0.7) },
    )
    tl.fromTo(
      `.${style.allCompetency}`,
      4,
      { top: '+=5', opacity: 0 },
      { top: 0, opacity: 1, ease: Elastic.easeOut.config(1, 0.7) },
      0.5,
    )
    tl.fromTo(
      `.${style.better}`,
      2,
      { left: '+=50', opacity: 0 },
      { left: 0, opacity: 1, ease: Elastic.easeOut.config(1, 0.7) },
      1,
    )

    let vspacing = 80

    if (600 >= window.innerWidth) {
      vspacing = 30
    } else if (600 < window.innerWidth && 1000 >= window.innerWidth) {
      vspacing = 50
    } else {
      vspacing = 80
    }

    const ease = Elastic.easeOut.config(1, 0.8)
    const topFade = {
      opacity: 0,
      y: -(vspacing + 5),
      rotationX: 90,
      ease,
    }
    const topObj = {
      opacity: 0.12,
      y: -vspacing,
      rotationX: 40,
      ease: Elastic.easeOut.config(1, 0.9),
    }
    const middleObj = {
      opacity: 1,
      y: 0,
      rotationX: 0,
      ease,
    }
    const bottomObj = {
      opacity: 0.12,
      y: vspacing,
      rotationX: -40,
      ease,
    }
    const bottomFade = {
      opacity: 0,
      y: vspacing + 5,
      rotationX: -90,
    }

    let index = 1
    let nextIndex = 2
    let prevIndex = words.length
    let bottomIn = index + 2
    TweenMax.set(`.word${prevIndex}`, topObj)
    TweenMax.set(`.word${index}`, { opacity: 1, y: 0 })
    TweenMax.set(`.word${index + 1}`, bottomObj)

    this.interval = setInterval(() => {
      if (index > words.length) {
        index = 1
      }

      if (prevIndex > words.length) {
        prevIndex = 1
      }

      if (bottomIn > words.length) {
        bottomIn = 1
      }

      if (nextIndex > words.length) {
        nextIndex = 1
      }

      TweenMax.to(`.word${prevIndex}`, 1, topFade)
      TweenMax.to(`.word${index}`, 1, topObj)
      TweenMax.to(`.word${nextIndex}`, 1, middleObj)
      TweenMax.fromTo(`.word${bottomIn}`, 1, bottomFade, bottomObj)

      /* eslint-disable no-plusplus */
      prevIndex++
      index++
      nextIndex++
      bottomIn++
    }, 3000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    return (
      <div className={style.techWords}>
        <span className={style.do}>Do</span>
        <span className={style.allCompetency}>
          {map(words, (word, index) => (
            <span
              key={`tech:words:${index + 1}`}
              className={cn(style.competency, `word${index + 1}`)}
            >
              {word}
            </span>
          ))}
        </span>
        <span className={style.better}>Better.</span>

        <svg
          className={style.blob}
          xmlns="http://www.w3.org/2000/svg"
          width="1135"
          height="452"
          viewBox="0 0 1135 452"
        >
          <defs>
            <linearGradient id="blob-a" x1="12.453%" x2="87.895%" y1="84.924%" y2="16.33%">
              <stop offset="0%" stopColor="#1D8DBA" />
              <stop offset="100%" stopColor="#03ECAE" />
            </linearGradient>
          </defs>
          <polygon
            fill="url(#blob-a)"
            fillRule="evenodd"
            points="366.216 75.475 236.728 10.842 153.37 168.553 0 319.171 330.143 392.742 766.137 365.876 1135 452 1032.828 154.064 1034.921 0 509.737 85.746"
            opacity="0.049"
          />
        </svg>
      </div>
    )
  }
}

export default DoItBetter
