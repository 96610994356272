import React from 'react'
import PropTypes from 'prop-types'

const MicrosoftOnAws = ({ className, classNames }) => (
  <svg
    className={className}
    width="221px"
    height="158px"
    viewBox="0 0 221 158"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient
        x1="12.4534884%"
        y1="84.9243045%"
        x2="87.8953488%"
        y2="16.3295925%"
        id="microsoft-cloud-gradient"
      >
        <stop stopColor="#1D8DBA" offset="0%" />
        <stop stopColor="#03ECAE" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="-76.2150316%"
        y1="42.7850146%"
        x2="63.7682094%"
        y2="79.6627285%"
        id="microsoft-cloud-gradient-1"
      >
        <stop stopColor="#1D8DBA" offset="0%" />
        <stop stopColor="#03ECAE" offset="100%" />
      </linearGradient>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-5.000000, 0.000000)">
        <path
          className={classNames.logo3}
          d="M47.8399299,29.4010585 L55.7149299,29.4010585 L55.7149299,36.9010585 L47.8399299,36.9010585 L47.8399299,29.4010585 Z M47.8399299,37.6510585 L55.7149299,37.6510585 L55.7149299,45.1510585 L47.8399299,45.1510585 L47.8399299,37.6510585 Z M56.4649299,37.6510585 L64.3399299,37.6510585 L64.3399299,45.1510585 L56.4649299,45.1510585 L56.4649299,37.6510585 Z M56.4649299,29.4010585 L64.3399299,29.4010585 L64.3399299,36.9010585 L56.4649299,36.9010585 L56.4649299,29.4010585 Z"
          fill="url(#microsoft-cloud-gradient)"
        />
        <path
          className={classNames.logo2}
          d="M21.9649299,57.3385585 L25.9024299,57.3385585 L25.9024299,61.0885585 L21.9649299,61.0885585 L21.9649299,57.3385585 Z M21.9649299,61.4635585 L25.9024299,61.4635585 L25.9024299,65.2135585 L21.9649299,65.2135585 L21.9649299,61.4635585 Z M26.2774299,61.4635585 L30.2149299,61.4635585 L30.2149299,65.2135585 L26.2774299,65.2135585 L26.2774299,61.4635585 Z M26.2774299,57.3385585 L30.2149299,57.3385585 L30.2149299,61.0885585 L26.2774299,61.0885585 L26.2774299,57.3385585 Z"
          fill="url(#microsoft-cloud-gradient)"
        />
        <image
          x="0.739639075"
          y="8.83455847"
          width="232.676951"
          height="152.4"
          xlinkHref="/images/clouds/microsoft-aws-cloud.png"
        />
        <path
          className={classNames.logo1}
          d="M133.271082,13.6510585 L164.771082,13.6510585 L164.771082,43.6510585 L133.271082,43.6510585 L133.271082,13.6510585 Z M133.271082,46.6510585 L164.771082,46.6510585 L164.771082,76.6510585 L133.271082,76.6510585 L133.271082,46.6510585 Z M167.771082,46.6510585 L199.271082,46.6510585 L199.271082,76.6510585 L167.771082,76.6510585 L167.771082,46.6510585 Z M167.771082,13.6510585 L199.271082,13.6510585 L199.271082,43.6510585 L167.771082,43.6510585 L167.771082,13.6510585 Z"
          fill="url(#microsoft-cloud-gradient)"
        />
        <polygon
          fill="url(#microsoft-cloud-gradient-1)"
          points="107.847254 108.114437 69.091883 92.8624843 69.091883 64.5953944 62.3960823 100.561703"
        />
        <polygon
          fill="url(#microsoft-cloud-gradient)"
          points="98.4576057 32.4933436 92.7315315 8.82000378 120.441981 0.105648315 129.072108 17.5519374 122.771448 14.8701747 120.441981 15.7029384 114.638636 6.60699109 97.7812141 10.1680262"
        />
      </g>
    </g>
  </svg>
)

MicrosoftOnAws.defaultProps = {
  className: '',
  classNames: {
    logo1: 'logo1',
    logo2: 'logo2',
    logo3: 'logo3',
  },
}

MicrosoftOnAws.propTypes = {
  className: PropTypes.string,
  classNames: PropTypes.shape({
    logo1: PropTypes.string,
    logo2: PropTypes.string,
    logo3: PropTypes.string,
  }),
}

export default MicrosoftOnAws
