import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from 'observian-ui/lib/button'
import ContactModal from 'observian-ui/lib/modal/contact'
import Divider from 'observian-ui/lib/divider'
import Avatar from 'observian-ui/lib/avatar'
import Card from 'observian-ui/lib/card'

import Cloud from 'components/SVG/cloud'
import HubspotForm from 'components/Form/Hubspot'
import TextUnderline from 'components/SVG/textUnderline'

import style from './hero.module.css'

const Hero = ({
  heading,
  title,
  date,
  description,
  presenters,
  formCTA,
  formId,
  formTitle,
  webinarId,
}) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <div className={style.hero}>
      <span role="presentation" alt="Hero Background Left" className={style.topLeftBG} />
      <Cloud type="outline" className={style.cloud} />
      <div className={style.heroContent}>
        <div className={style.container}>
          <div className={style.content}>
            <header className={style.header}>
              <h1 className={style.heading}>{heading}</h1>
              <h3 className={style.title}>{title}</h3>
              <TextUnderline />
            </header>
            <p className={style.date}>{date}</p>
            <p className={style.description}>{description}</p>
          </div>
          <div className={style.presenters}>
            <span className={style.text}>PRESENTERS</span>
            {presenters.map(presenter => (
              <Card key={`presenter:card:${presenter.id}`} square className={style.presenter}>
                <div className={style.avatarContainer}>
                  <Avatar
                    forceAspectRatio
                    image={presenter.avatar.fluid.src}
                    className={style.avatarImage}
                  />
                </div>
                <div className={style.vl} />
                <div className={style.info}>
                  <span className={style.name}>{presenter.name}</span>
                  <span className={style.title}>{`${presenter.title}, ${presenter.company}`}</span>
                </div>
              </Card>
            ))}
          </div>
        </div>
        <Button
          variant="primary"
          label="Register to Attend"
          className={style.button}
          onClick={() => setShowModal(!showModal)}
          data-webinar={`webinar-${formCTA}`}
        />
        <ContactModal
          showClose
          shouldCloseOnOverlayClick
          isOpen={showModal}
          onClose={() => setShowModal(!showModal)}
        >
          <HubspotForm
            showLogo={false}
            formId={formId}
            title={formTitle}
            type="contact"
            onDismiss={() => setShowModal(false)}
            inquiryTitle="Other information?"
            inquiryPlaceholder="Please let us know about any special dietary restrictions, or other important information."
            isSuccessfulMessage="Your registration has been confirmed!"
            ctaIntentSuffix={formCTA}
            buttonLabel="Register to Attend"
            webinarId={webinarId}
          />
        </ContactModal>
      </div>
      <Divider />
    </div>
  )
}

Hero.defaultProps = {
  webinarId: '',
}

Hero.propTypes = {
  formCTA: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  formTitle: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  presenters: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.object.isRequired,
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      companyLogo: PropTypes.object.isRequired,
    }),
  ).isRequired,
  webinarId: PropTypes.string,
}

export default Hero
