import React from 'react'
import PropTypes from 'prop-types'
import Divider from 'observian-ui/lib/divider'
import Button from 'observian-ui/lib/button'
import cx from 'clsx'
import Modal from 'observian-ui/lib/modal'
import camelCase from 'lodash/camelCase'
import { Link } from 'gatsby'

import Laser from 'components/SVG/lasers/awsPageTemplate'
import SimpleForm from 'components/Form/Simple'
import BGLines from 'components/SVG/backgroundLines'
import Form from 'components/Form'

import style from './awsPageTemplate.module.css'

class AWSPageTemplate extends React.Component {
  state = {
    showModal: false,
  }

  handleModal = () => {
    this.setState(state => ({
      showModal: !state.showModal,
    }))
  }

  render() {
    const {
      props: { content, headerIcon, inquiryPlaceholder, name, title },
      state: { showModal },
    } = this

    return (
      <>
        <section className={style.hero}>
          <BGLines className={style.lines} />
          <header className={cx(style.header, style[`${camelCase(name)}`])}>
            <h1>{name}</h1>
            <Button
              className={style.button}
              label={`Let's Talk ${name}`}
              onClick={this.handleModal}
            />
          </header>
          <Divider>
            <div className={cx(style.headerIcon, style[`${camelCase(name)}`])}>{headerIcon}</div>
            <Laser className={style.laser} />
          </Divider>
        </section>
        <section className={style.container}>
          <Link className={style.backLink} to="/aws">
            &larr; AWS SERVICES
          </Link>
          <div className={style.content}>
            <header>
              <h2>{title}</h2>
            </header>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
          <div className={style.sidebar}>
            <div className={style.moreInfo}>
              <SimpleForm ctaIntentSuffix={name} />
            </div>
            <div className={style.awsPartner}>
              <a
                name="AWS Advanced Partner"
                rel="noopener noreferrer"
                target="_blank"
                href="https://aws.amazon.com/partners/find/partnerdetails/?n=Observian%2C%20Inc.&id=0010L00001kY4bTQAS"
              >
                <img src="/images/certified/aws-advanced-partner.jpg" alt="AWS Advanced Partner" />
              </a>
            </div>
          </div>
          {showModal ? (
            <Modal isOpen={showModal} onClose={this.handleModal} ariaHideApp={false}>
              <Form onDismiss={this.handleModal} inquiryPlaceholder={inquiryPlaceholder} />
            </Modal>
          ) : null}
        </section>
      </>
    )
  }
}

AWSPageTemplate.defaultProps = {
  name: '',
  title: '',
  content: '',
  inquiryPlaceholder: '',
}

AWSPageTemplate.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  headerIcon: PropTypes.any, // eslint-disable-line
  inquiryPlaceholder: PropTypes.string,
}

export default AWSPageTemplate
