import React from 'react'
import PropTypes from 'prop-types'

const SecurityCompliance = ({ className, classNames }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="221"
    height="127"
    viewBox="0 0 221 127"
  >
    <defs>
      <linearGradient
        id="security-compliance-cloud-a"
        x1="12.453%"
        x2="87.895%"
        y1="84.924%"
        y2="16.33%"
      >
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <path
        id="security-compliance-cloud-b"
        d="M34.3125,44.159625 L30.915,44.157375 L27.69075,40.933125 C27.144,40.386375 27.144,39.490875 27.69075,38.944125 L30.915,35.719875 L39.936375,35.719875 L39.936375,31.5 C39.936375,28.39275 37.41975,25.876125 34.31025,25.876125 L6.1875,25.876125 C3.081375,25.876125 0.5625,28.39275 0.5625,31.5 L0.5625,48.376125 C0.5625,51.48225 3.08025,54 6.1875,54 L34.31025,54 C37.41975,54 39.936375,51.48225 39.936375,48.376125 L39.936375,44.157375 L37.125,41.344875 L34.3125,44.159625 Z"
      />
      <path
        id="security-compliance-cloud-d"
        d="M182.176758,66.5622252 L170.925633,66.5622252 C168.594633,66.5622252 166.706883,68.4533502 166.706883,70.7843502 L166.706883,73.5957252 L140.810508,73.5957252 L137.586258,76.8199752 C137.039508,77.3656002 137.039508,78.2611002 137.586258,78.8089752 L140.810508,82.0332252 L144.208008,82.0354752 L147.020508,79.2207252 L149.831883,82.0332252 L152.645508,79.2207252 L155.458008,82.0309752 L158.269383,79.2207252 L161.083008,82.0309752 L166.706883,82.0309752 L166.706883,84.8446002 C166.706883,87.1733502 168.594633,89.0633502 170.925633,89.0633502 L182.176758,89.0633502 C184.507758,89.0633502 186.395508,87.1733502 186.395508,84.8446002 L186.395508,70.7843502 C186.395508,68.4522252 184.507758,66.5622252 182.176758,66.5622252 Z M182.176758,82.7363502 C182.176758,83.9007252 181.232883,84.8446002 180.068508,84.8446002 C178.901883,84.8446002 177.958008,83.9007252 177.958008,82.7363502 L177.958008,72.8926002 C177.958008,71.7282252 178.901883,70.7843502 180.066258,70.7843502 C181.232883,70.7843502 182.176758,71.7282252 182.176758,72.8926002 L182.176758,82.7363502 Z"
      />
      <filter
        id="security-compliance-cloud-c"
        width="142.7%"
        height="193.3%"
        x="-21.3%"
        y="-33.3%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3" />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.144587862 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(-6)">
      <image
        width="227.856"
        height="120.129"
        x="0.521"
        y="7.087"
        xlinkHref="/images/clouds/security-compliance-cloud.png"
      />
      <g className={classNames.safe} transform="translate(74.239 25.151)">
        <polygon fill="#03D29C" fillRule="nonzero" points="10.202 54 7.201 54 7.198 48 11.7 48" />
        <polygon
          fill="#04EAAE"
          fillRule="nonzero"
          points="40.196 54 43.198 54 43.198 48 38.695 48"
        />
        <polygon
          fill="#4A5D72"
          fillOpacity="0.429"
          fillRule="nonzero"
          points="39.448 50.998 43.198 50.998 43.198 48 38.695 48"
        />
        <polygon points="40.196 54 43.198 54 43.198 48 38.695 48" />
        <polygon
          fill="#4A5D72"
          fillOpacity="0.13"
          fillRule="nonzero"
          points="11.7 48 7.198 48 7.201 50.998 10.95 50.998"
        />
        <path
          fill="url(#security-compliance-cloud-a)"
          fillRule="nonzero"
          d="M49.2,45 C49.2,46.65 47.8476,48 46.1988,48 L4.1988,48 C2.55,48 1.1976,46.65 1.1976,45 L1.1976,3 C1.1976,1.3488 2.55,0.0012 4.1988,0.0012 L46.1988,0.0012 C47.8476,1.71373332e-14 49.2,1.3488 49.2,3 L49.2,45 Z"
        />
        <rect
          width="38.998"
          height="39.001"
          x="5.7"
          y="4.499"
          fill="#4A5D72"
          fillOpacity="0.62"
          fillRule="nonzero"
        />
        <circle cx="38.7" cy="37.498" r="3.001" fill="#DCE1E0" fillRule="nonzero" />
        <circle cx="38.7" cy="37.498" r="1.2" fill="#C4C8C8" fillRule="nonzero" />
        <path
          fill="#4A5D72"
          fillOpacity="0.4"
          fillRule="nonzero"
          d="M14.1924 21.684C14.0352 22.4316 13.9488 23.2056 13.9488 23.9976 13.9488 27.3072 15.4044 30.2532 17.6772 32.3136L18.4536 31.242C16.7784 29.2848 15.75 26.7744 15.75 23.9976 15.75 23.4 15.8124 22.8144 15.9024 22.2396L14.1924 21.684zM14.9496 19.4064L21.4476 21.5184C21.5592 21.354 21.6912 21.204 21.8208 21.0564L16.752 19.4064C18.108 16.3824 20.7624 14.1192 24.0024 13.2312L24.0024 12.87C19.956 13.3032 16.5528 15.8388 14.9496 19.4064zM36.2112 21.6864L29.6832 23.8056C29.6832 23.8716 29.7012 23.9316 29.7012 23.9976 29.7012 25.0596 29.3208 26.0208 28.7088 26.7888L32.7228 32.3148C33.0876 31.9824 33.426 31.6212 33.7452 31.2456L30.5076 26.7876C31.1244 26.0184 31.5048 25.0584 31.5048 23.9964 31.5048 23.9304 31.488 23.8704 31.4832 23.8044L36.3 22.2408C36.2688 22.0572 36.2484 21.87 36.2112 21.6864z"
        />
        <path
          fill="#04EAAE"
          fillOpacity="0.387"
          fillRule="nonzero"
          d="M27,10.5 C26.6976,10.5 26.4,10.5252 26.0988,10.5468 C33.1308,11.0088 38.6988,16.8468 38.6988,23.9976 C38.6988,31.1508 33.1308,36.9888 26.0988,37.4532 C26.4,37.4724 26.6976,37.4976 27,37.4976 C34.4544,37.4976 40.5024,31.458 40.5024,23.9976 C40.5024,16.542 34.4544,10.5 27,10.5 Z"
        />
        <path
          fill="#4A5D72"
          fillOpacity="0.4"
          fillRule="nonzero"
          d="M21.4236,33.7152 L25.2144,28.4976 C25.2096,28.4976 25.206,28.5 25.2012,28.5 C24.6432,28.5 24.1188,28.3848 23.6304,28.2024 L19.62,33.7152 C21.2736,34.6644 23.1588,35.25 25.2,35.25 C25.506,35.25 25.8,35.2104 26.1012,35.1864 C24.408,35.0412 22.8252,34.5216 21.4236,33.7152 Z"
        />
        <path
          fill="#94B4D6"
          fillRule="nonzero"
          d="M26.9832,28.4976 L26.7672,28.2024 C26.5524,28.284 26.3292,28.3428 26.0976,28.392 C26.3832,28.4556 26.6772,28.4976 26.9832,28.4976 Z"
        />
        <path
          fill="#4A5D72"
          fillOpacity="0.4"
          fillRule="nonzero"
          d="M26.4,12.87 L26.4,19.6836 C27.4596,19.9764 28.3608,20.6304 28.9512,21.5232 L30.3816,21.0564 C29.8104,20.4072 29.0616,19.92 28.2036,19.6824 L28.2036,13.23 C27.6168,13.0692 27.0216,12.9348 26.4,12.87 Z"
        />
        <circle cx="25.2" cy="23.998" r="4.5" fill="#DCE1E0" fillRule="nonzero" />
        <path
          fill="#F6FBFA"
          fillRule="nonzero"
          d="M28.7076 26.784C28.2036 27.4188 27.5412 27.9132 26.7732 28.2024L30.7752 33.7152C31.4784 33.312 32.1312 32.85 32.7216 32.3136L28.7076 26.784zM29.682 23.8056L36.2064 21.684C36.0408 20.8896 35.7768 20.1324 35.4504 19.4088L28.9512 21.5184C29.3892 22.1784 29.646 22.9644 29.682 23.8056zM21.6912 26.7864L17.6772 32.3124C18.2724 32.8488 18.9252 33.3108 19.6236 33.714L23.6304 28.2012C22.8576 27.9144 22.1964 27.4224 21.6912 26.7864zM20.7204 23.8056C20.7576 22.9644 21.0144 22.1796 21.4476 21.5184L14.9496 19.4064C14.6232 20.1324 14.358 20.8872 14.1924 21.684L20.7204 23.8056zM26.4 19.6824L26.4 12.8688C25.9992 12.8268 25.6116 12.7488 25.2012 12.7488 24.7884 12.7488 24.3996 12.8256 24.0024 12.8688L24.0024 19.68C24.3864 19.5744 24.78 19.5 25.2012 19.5 25.62 19.5 26.016 19.5756 26.4 19.6824z"
        />
        <path
          fill="#F6FBFA"
          fillRule="nonzero"
          d="M25.2012,10.5 C17.7432,10.5 11.7,16.542 11.7,23.9976 C11.7,31.458 17.7444,37.4976 25.2012,37.4976 C32.6508,37.4976 38.6988,31.458 38.6988,23.9976 C38.6988,16.542 32.6508,10.5 25.2012,10.5 Z M25.2012,35.25 C18.9876,35.25 13.9488,30.2136 13.9488,23.9976 C13.9488,17.784 18.9876,12.75 25.2012,12.75 C31.416,12.75 36.45,17.784 36.45,23.9976 C36.45,30.2136 31.416,35.25 25.2012,35.25 Z"
        />
      </g>
      <polygon
        fill="url(#security-compliance-cloud-a)"
        points="117.365 9.56 134.405 .095 167.194 26.689 137.319 5.486 131.484 13.392"
      />
      <g className={classNames.lock} fillRule="nonzero" transform="translate(109.896 37.876)">
        <path
          fill="#04EAAE"
          fillOpacity="0.1"
          d="M23.061375,0.563625 C22.586625,0.563625 22.1175,0.590629436 21.655125,0.644638309 C28.760625,1.44962769 34.3125,8.30361079 34.3125,16.6364083 L34.3125,25.31475 L37.125,26.43975 L37.125,16.6364083 C37.125,7.75966429 30.82725,0.563625 23.061375,0.563625 Z"
        />
        <use fill="url(#security-compliance-cloud-a)" xlinkHref="#security-compliance-cloud-b" />
        <use fill="#000" fillOpacity="0.01" xlinkHref="#security-compliance-cloud-b" />
        <path
          fill="#C4C8C8"
          d="M27.69075,40.933125 C27.144,40.386375 27.144,39.490875 27.69075,38.944125 L28.48725,38.147625 C27.664875,34.349625 24.294375,31.5 20.25,31.5 C15.589125,31.5 11.811375,35.27775 11.811375,39.938625 C11.811375,44.59725 15.589125,48.376125 20.25,48.376125 C24.294375,48.376125 27.664875,45.527625 28.48725,41.729625 L27.69075,40.933125 Z"
        />
        <rect width="2.813" height="8.435" x="18.844" y="35.72" fill="#F6FBFA" />
        <path
          fill="#04EAAE"
          fillOpacity="0.1"
          d="M11.8125,14.625 L11.8125,20.248875 L14.625,20.248875 L14.625,14.625 C14.625,10.44675 17.66925,7.002 21.655125,6.3315 C21.196125,6.253875 20.730375,6.1875 20.248875,6.1875 C15.589125,6.1875 11.8125,9.966375 11.8125,14.625 Z"
        />
        <path
          fill="#F6FBFA"
          d="M11.8125,14.625 C11.8125,9.966375 15.589125,6.1875 20.248875,6.1875 C24.908625,6.1875 28.686375,9.966375 28.686375,14.625 L28.686375,25.876125 L34.311375,25.876125 L34.311375,14.625 C34.311375,6.859125 28.013625,0.563625 20.24775,0.563625 C12.48075,0.563625 6.18525,6.859125 6.18525,14.625 L6.18525,25.876125 L11.81025,25.876125 L11.81025,14.625 L11.8125,14.625 Z"
        />
      </g>
      <g className={classNames.key} fillRule="nonzero">
        <use
          fill="#000"
          filter="url(#security-compliance-cloud-c)"
          xlinkHref="#security-compliance-cloud-d"
        />
        <use fill="#1DECB6" xlinkHref="#security-compliance-cloud-d" />
      </g>
      <polygon
        fill="url(#security-compliance-cloud-a)"
        points="58.937 47.487 52.254 70.091 74.001 91.235 98.376 91.235 65.563 94.118 47.382 70.091"
      />
    </g>
  </svg>
)

SecurityCompliance.defaultProps = {
  className: '',
  classNames: {
    lock: 'lock',
    key: 'key',
    safe: 'safe',
  },
}

SecurityCompliance.propTypes = {
  className: PropTypes.string,
  classNames: PropTypes.shape({
    lock: PropTypes.string,
    key: PropTypes.string,
    safe: PropTypes.string,
  }),
}

export default SecurityCompliance
