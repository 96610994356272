import React from 'react'
import PropTypes from 'prop-types'
import Button from 'observian-ui/lib/button'
import Modal from 'observian-ui/lib/modal'

import Form from 'components/Form'
import BGLines from 'components/SVG/backgroundLines'
import canUseDOM from 'utils/canUseDOM'

import style from './seo.module.css'

class SEOPartnerTemplate extends React.Component {
  state = {
    showModal: false,
    htmlContent: '',
  }

  componentDidMount = () => {
    if (canUseDOM) {
      const { htmlDescription } = this.props

      this.updateHtmlContent(htmlDescription)
    }
  }

  updateHtmlContent = content => {
    if (content) {
      this.setState({
        htmlContent: content,
      })
    }
  }

  handleOpenModal = () => {
    this.setState({ showModal: true })
  }

  handleCloseModal = () => {
    this.setState({ showModal: false })
  }

  render() {
    const {
      props: { description, logo, name, url, title },
      state: { showModal, htmlContent },
    } = this

    return (
      <div className={style.partnersSEOPage}>
        <BGLines className={style.lines} />
        <div className={style.seoBackground}>
          <div className={style.seoContent}>
            <header className={style.header}>
              <a rel="noopener noreferrer" href={url}>
                <img className={style.partnersLogo} src={logo} srcSet={`${logo} 2x`} alt={name} />
              </a>
            </header>
            <h1 className={style.title}>{title}</h1>
            {htmlContent ? (
              <p
                className={style.htmlDescription}
                dangerouslySetInnerHTML={{ __html: htmlContent }}
              />
            ) : (
              <div className={style.description}>{description}</div>
            )}
            <Button
              variant="primary"
              className={style.buttonCTA}
              label="Request Demo"
              onClick={this.handleOpenModal}
            />
            <div className={style.moreInfo}>
              <h2>More Information</h2>
              <div>
                <a className={style.partnersLink} rel="noopener noreferrer" href={url}>
                  {name}
                </a>
              </div>
            </div>
          </div>
        </div>
        {showModal ? (
          <Modal isOpen={showModal} onClose={this.handleCloseModal}>
            <Form
              onDismiss={this.handleCloseModal}
              inquiryPlaceholder={`Hi, I'd like to receive a demo of ${name}, when can we chat?`}
            />
          </Modal>
        ) : null}
      </div>
    )
  }
}

SEOPartnerTemplate.defaultProps = {
  logo: '',
  name: '',
  description: [],
  url: '',
  title: '',
  htmlDescription: '',
}

SEOPartnerTemplate.propTypes = {
  logo: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.array,
  url: PropTypes.string,
  title: PropTypes.string,
  htmlDescription: PropTypes.string,
}

export default SEOPartnerTemplate
