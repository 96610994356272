import React from 'react'
import cn from 'clsx'
import Logo from 'observian-ui/lib/logo'
import { Link } from 'gatsby'
import { gsap, Elastic, Power1 } from 'gsap'

import NavDropdown from '../Dropdown'
import menu from '../Dropdown/menu'

import style from './header.module.css'

class Header extends React.Component {
  state = {
    isActive: false,
    displayServices: false,
  }

  componentDidUpdate = () => {
    const { displayServices } = this.state
    if (displayServices) {
      this.tlNavMenu.progress(0)
    }
  }

  componentDidMount = () => {
    document.addEventListener('click', this.handleOutsideClick)
    const tlNavMenu = gsap.timeline({ paused: true })
    tlNavMenu.from(
      `.${style.clouds}`,
      {
        opacity: 0,
        x: '-=20',
        y: '+=5',
        ease: Elastic.easeOut.config(1, 1),
        duration: 1.75,
        stagger: 0.1,
      },
      0.25,
    )
    tlNavMenu.from(
      `.${style.links}`,
      {
        opacity: 0,
        x: '+=20',
        y: '+=5',
        ease: Elastic.easeOut.config(1, 1),
        duration: 1.25,
        stagger: 0.05,
      },
      0.25,
    )
    tlNavMenu.from(
      `.${style.container}`,
      0.25,
      { rotationX: '-50', y: '+=5', ease: Power1.easeOut },
      0,
    )
    this.tlNavMenu = tlNavMenu
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleOutsideClick)
  }

  handleMobileMenu = () => {
    if (608 >= window.innerWidth) {
      this.setState(state => ({
        isActive: !state.isActive,
      }))
    }
  }

  handleOutsideClick = event => {
    const { isActive } = this.state

    if (!this.nav.contains(event.target)) {
      if (isActive && 608 >= window.innerWidth) {
        this.setState({
          isActive: false,
          displayServices: false,
        })
      }
    }
  }

  handleServicesMenu = e => {
    e.stopPropagation()
    if (608 >= window.innerWidth) {
      this.setState(state => ({
        displayServices: !state.displayServices,
      }))
    }
  }

  handleServicesMenuDesktop = () => {
    this.setState(
      state => ({
        displayServices: !state.displayServices,
      }),
      () => {
        const { displayServices } = this.state
        if (displayServices) {
          this.tlNavMenu.progress(0)
        }
        this.tlNavMenu.timeScale(1)
        this.tlNavMenu.play()
      },
    )
  }

  render() {
    const { isActive, displayServices } = this.state

    return (
      <header data-testid="nav-header" className={cn(style.header, isActive && style.headerColor)}>
        <div className={style.headerActions}>
          <Link aria-label="Observian Cloud Professionals" to="/">
            <Logo
              colorLogo
              showText
              className={style.logoContainer}
              classNames={{ logo: style.logo, text: style.text }}
            />
          </Link>
          <div
            data-testid="nav-menu-icon"
            role="presentation"
            className={style.navMenu}
            onClick={this.handleMobileMenu}
            ref={node => {
              this.nav = node
            }}
          >
            <div role="button" className={style.menuButton} aria-label="Navigation Menu">
              <div className={cn(style.topBar, isActive && style.isActive)} />
              <div className={cn(style.middleBar, isActive && style.isActive)} />
              <div className={cn(style.bottomBar, isActive && style.isActive)} />
            </div>
            <div className={style.menuWrapper}>
              <ul
                data-testid="nav-menu"
                className={cn(
                  style.menu,
                  isActive && style.showMenu,
                  displayServices && style.displayServices,
                )}
              >
                <li className={cn(style.navLinks, style.navLinksMobile)}>
                  <a
                    role="presentation"
                    className={style.buttonLinkMobile}
                    onClick={e => this.handleServicesMenu(e)}
                  >
                    Services
                  </a>
                  <a
                    role="presentation"
                    className={style.buttonLink}
                    onMouseOver={this.handleServicesMenuDesktop}
                    onFocus={this.handleServicesMenuDesktop}
                  >
                    Services
                  </a>
                  <NavDropdown
                    animOptions={{
                      clouds: style.clouds,
                      links: style.links,
                      container: style.container,
                    }}
                    onMouseLeave={this.handleServicesMenuDesktop}
                    className={cn(
                      style.servicesDropdownDesktop,
                      displayServices && style.displayServicesDesktop,
                    )}
                  />
                </li>
                <div
                  className={cn(
                    style.servicesDropdownMobile,
                    displayServices && style.displayServicesMobile,
                  )}
                >
                  <div className={style.break}>By Cloud</div>
                  <li className={style.nestedNavLinks}>
                    <Link className={style.nestedNavLink} to="/aws">
                      AWS
                    </Link>
                  </li>
                  <li className={style.nestedNavLinks}>
                    <Link className={style.nestedNavLink} to="/google">
                      Google
                    </Link>
                  </li>
                  <div className={style.break}>By Service</div>
                  <ul className={style.nestedLinksContainer}>
                    {menu.map(item => (
                      <li className={style.nestedLinks} key={`url:${item.href}`}>
                        <Link to={item.href} className={style.nestedLink}>
                          {item.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <li className={style.navLinks}>
                  <Link className={style.navLink} to="/partners">
                    Partners
                  </Link>
                </li>
                <li className={style.navLinks}>
                  <Link className={style.navLink} to="/gsuite">
                    G Suite
                  </Link>
                </li>
                <li className={style.navLinks}>
                  <Link className={style.navLink} to="/tools/secret-awsome">
                    Tools
                  </Link>
                </li>
                <li className={style.navLinks}>
                  <Link className={style.navLink} to="/blog">
                    Blog
                  </Link>
                </li>
                <li className={style.navLinks}>
                  <Link className={style.navLink} to="/about">
                    About
                  </Link>
                </li>
                <li className={style.navLinks}>
                  <Link className={style.navLink} to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
