import React, { useState } from 'react'
import Button from 'observian-ui/lib/button'
import ContactModal from 'observian-ui/lib/modal/contact'

import ContactForm from 'components/Form/Contact'
import CertCount from 'components/SVG/certificationCount'

import style from './certifiedExperts.module.css'

const CertifiedExperts = () => {
  const [showModal, setShowModal] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)
  return (
    <section className={style.section}>
      <div className={style.top} />
      <div className={style.contentContainer}>
        <div className={style.content}>
          <div className={style.left}>
            <svg
              className={style.angledLine}
              xmlns="http://www.w3.org/2000/svg"
              width="261"
              height="203"
              viewBox="0 0 261 203"
            >
              <polygon
                fill="#DCE1E0"
                points="0 202.699 81.484 34.57 261.074 0 89.383 44.711"
                opacity=".5"
              />
            </svg>
            <svg
              className={style.particles}
              xmlns="http://www.w3.org/2000/svg"
              width="560"
              height="382"
              viewBox="0 0 560 382"
            >
              <defs>
                <linearGradient
                  id="particles-a"
                  x1="12.453%"
                  x2="87.895%"
                  y1="71.704%"
                  y2="29.075%"
                >
                  <stop offset="0%" stopColor="#1D8DBA" />
                  <stop offset="100%" stopColor="#03ECAE" />
                </linearGradient>
                <linearGradient
                  id="particles-b"
                  x1="12.453%"
                  x2="87.895%"
                  y1="71.704%"
                  y2="29.075%"
                >
                  <stop offset="0%" stopColor="#1D8DBA" />
                  <stop offset="100%" stopColor="#03ECAE" />
                </linearGradient>
                <linearGradient
                  id="particles-c"
                  x1="12.453%"
                  x2="87.895%"
                  y1="71.704%"
                  y2="29.075%"
                >
                  <stop offset="0%" stopColor="#1D8DBA" />
                  <stop offset="100%" stopColor="#03ECAE" />
                </linearGradient>
              </defs>
              <g
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                transform="translate(-2 1)"
              >
                <polygon
                  fill="url(#particles-a)"
                  stroke="url(#particles-a)"
                  strokeWidth="3"
                  points="10.962 37.114 19.925 51.245 2 51.245"
                  transform="rotate(-13 10.962 44.18)"
                />
                <polygon
                  fill="url(#particles-b)"
                  stroke="url(#particles-b)"
                  strokeWidth="3"
                  points="550.029 4 561.104 21.461 538.955 21.461"
                  transform="rotate(-95 550.03 12.73)"
                />
                <polygon
                  fill="url(#particles-b)"
                  stroke="url(#particles-b)"
                  strokeWidth="3"
                  points="180.969 359.797 192.044 377.258 169.894 377.258"
                  transform="rotate(-95 180.97 368.527)"
                />
                <polygon
                  fill="url(#particles-c)"
                  stroke="url(#particles-c)"
                  strokeWidth="1.125"
                  points="40.969 315.254 45.122 321.801 36.816 321.801"
                  transform="rotate(180 40.97 318.527)"
                />
              </g>
            </svg>

            <header className={style.header}>
              <h2>AWS Certified Experts</h2>
              <p>
                We’ve been there, done that with AWS. We can help you avoid common pitfalls that
                cost you time and money. Our team of certified AWS experts can help guide you
                through your journey in the AWS ecosystem.
              </p>
            </header>
            <Button
              variant="secondary"
              label="Talk to an expert"
              onClick={() => setShowModal(true)}
            />
          </div>
          <div className={style.right}>
            <CertCount
              type="aws1"
              className={style.certCount}
              attrClassNames={{
                awsCount: style.awsCount,
                awsTitle: style.awsTitle,
                employeeCount: style.employeeCount,
                employeeTitle: style.employeeTitle,
                circle: style.circle,
                circle1: style.circle1,
              }}
            />
          </div>
        </div>
      </div>
      {showModal ? (
        <ContactModal
          showClose
          shouldCloseOnOverlayClick
          onClose={() => setShowModal(false)}
          onDismiss={() => setShowModal(false)}
          isOpen={showModal}
          closeButtonPosition="right"
          ariaHideApp={false}
          success={isSuccessful}
        >
          <ContactForm
            showLogo
            headerTitle="Contact Us"
            buttonLabel="Send"
            ctaIntentSuffix="modal-aws-certified-experts"
            inquiryPlaceholder="I'd love to work with your certified experts."
            isSuccessfulCallback={v => setIsSuccessful(v)}
          />
        </ContactModal>
      ) : null}
      <div className={style.bottom} />
    </section>
  )
}

export default CertifiedExperts
