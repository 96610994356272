export default [
  {
    label: 'Cloud Migrations',
    href: '/cloud-migration',
  },
  {
    label: 'Cost Optimization',
    href: '/cost-optimization',
  },
  {
    label: 'Training & Workshops',
    href: '/training-workshops',
  },
  {
    label: 'DevOps',
    href: '/devops',
  },
  {
    label: 'Containers',
    href: '/containers',
  },
  {
    label: 'Security & Compliance',
    href: '/security-compliance',
  },
  {
    label: 'Big Data & Analytics',
    href: '/big-data-analytics',
  },
]
