import React from 'react'
import Divider from 'observian-ui/lib/divider'

import TechLines from 'components/SVG/techLines'
import AWSMainCloud from 'components/SVG/clouds/awsMain'
import ObservianLogoGate from 'components/SVG/observianLogoGate'

import style from './hero.module.css'

const Hero = () => (
  <section className={style.hero}>
    <ObservianLogoGate className={style.observianLogoGate} />
    <div className={style.content}>
      <header>
        <h1 className={style.title}>AWS Consulting Services</h1>
      </header>
      <p className={style.description}>
        AWS and the cloud have the capability to enhance every business, regardless of size or age.
        Legacy players are compelled to change basic aspects of their business models, while
        start-ups and smaller companies are given access to technologies previously only available
        to the most fortunate. The winners will prevail not by dominating the value chain of their
        respective industries, but by making it easy for developers to collaborate and for customers
        to adopt their technology cheaply and easily. Hello, AWS.
      </p>
    </div>
    <TechLines className={style.techLinesRight} />
    <Divider>
      <AWSMainCloud className={style.mainCloud} />
    </Divider>
  </section>
)

export default Hero
