import React from 'react'

import {
  CertifiedExperts,
  Hero,
  PartnerBenefits,
  Quote,
  Sites,
  SecurityAssessment,
  War,
} from 'components/Page/AWSServices'
import Layout from 'components/Layout'

export default () => (
  <Layout
    title="Amazon Web Services"
    description="Observian offers advanced AWS consulting services in Utah. We do all things cloud, including DevOps, Big Data, Security, Migration, and more. Learn more!"
  >
    <Hero />
    <PartnerBenefits />
    <CertifiedExperts />
    <War />
    <SecurityAssessment />
    <Quote />
    <Sites />
  </Layout>
)
