import React from 'react'
import { gsap, Power0 } from 'gsap'

import Layout from 'components/Layout'
import AWSPageTemplate from 'templates/Page/AWS'
import SecurityCompliance from 'components/SVG/clouds/securityCompliance'

class SecurityCompliancePage extends React.Component {
  componentDidMount() {
    const securityCompliance = gsap.timeline()
    securityCompliance.to('.key, .lock', {
      x: '+=5',
      y: '-=5',
      repeat: -1,
      yoyo: true,
      ease: Power0.easeNone,
      duration: 2,
    })

    securityCompliance.to(
      '.safe',
      { x: '-=10', y: '-=12', repeat: -1, yoyo: true, ease: Power0.easeNone, duration: 5 },
      0,
    )
  }

  render() {
    return (
      <Layout
        title="AWS Security & Compliance Services"
        description="Observian can help check everything off your regulatory list with our AWS Security & Compliance services. Schedule a free consultation today!"
      >
        <AWSPageTemplate
          name="AWS Security & Compliance"
          title="Security & Compliance"
          content="
            <p> Observian’s cloud security services help companies leverage AWS security and compliance capabilities to safeguard their data and meet compliance requirements. We understand that cloud security is a high priority, and our cloud security services are here to help. </p>
            <br />
            <p> When it comes to customer trust, there are no bigger issues to a company than security and compliance. There’s also a lot of misunderstanding, and this is where Observian can help. Simply put, AWS is one of the most reputed and secure environments available to businesses. Knowing that you only pay for the services you use means you can have the security you need, without the upfront expenses, and at a lower cost than an on-premises environment. When it comes to compliance, Observian can help build, then check everything off your regulatory list. And not just when the regulators are looking either. </p>
            <br />
            <p> We partner with some of the best technologies in the cloud security world, such as Alert Logic, Armor, f5, Palo Alto Networks, Splunk, and Sumologic. Together, we can build you a secure environment that remains scalable and contemporary. </p>
            <br />
            <h2> Cloud Security Services by Design </h2>
            <br />
            <p> Keeping your data safe is a top priority. AWS provides infrastructure security using network firewalls, encryption, and connectivity options that enable private connections on-premise. Other cloud security services include DDoS Mitigation, data encryption, inventory control, monitoring and logging, identity and access control, as well as penetration testing. If you’re interested in these cloud security services, <a href='/contact'>contact us today</a> and we’ll be more than happy to give you more information.</p>
            <br />
            <h2> Cloud Security Compliance Programs </h2>
            <br />
            <p> AWS provides a litany of compliance programs to meet the various regulations brought forth by the numerous global regulatory bodies, such as PCI, CSA, and SOC (Global), DoD SRG and HIPAA (U.S.A.), GDPR (E.U), etc. Though the customer remains responsible for complying with applicable laws and regulations, Observian can guide you through certifications and requirements, and help build the functionality needed for ongoing cloud security compliance. </p>
            <br />
            <h2> Support and Guidance </h2>
            <br />
            <p> We have your back, and so does AWS. An extensive support network works with you every step of the way to not only make your data safe, but to keep it that way. We can facilitate AWS support through a Trusted Advisor and work with you to strategize in-depth security solutions. </p>
            <br />
            <p> Ready to talk AWS security and compliance? <a href='tel:801-895-3219'>Give us a call</a> and get started today! </p>"
          headerIcon={<SecurityCompliance />}
          inquiryPlaceholder="Hi! We could use some help with making sure we are secure and compliant in the cloud..."
        />
      </Layout>
    )
  }
}

export default SecurityCompliancePage
