import React from 'react'
import PropTypes from 'prop-types'

const Footer = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="1345"
    height="242"
    viewBox="0 0 1345 242"
  >
    <defs>
      <linearGradient id="footer-laser-a" x1="15.436%" x2="114.319%" y1="90.518%" y2="21.413%">
        <stop offset="0%" stopColor="#FFC42A" />
        <stop offset="100%" stopColor="#FF0061" stopOpacity="0.946" />
      </linearGradient>
      <filter
        id="footer-laser-b"
        width="104.5%"
        height="150.7%"
        x="-2.2%"
        y="-25.4%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
      </filter>
    </defs>
    <g fill="url(#footer-laser-a)" fillRule="evenodd" transform="translate(8 -2)">
      <polyline
        points="2.931 145.425 9.185 141.984 138.86 70.635 315.019 148.481 469.284 102.724 790.157 124.034 1147.886 58.008 1338.076 176.308 1147.886 65.547 795.855 162.659 469.179 106.224 312.229 146.313 145.144 80.134"
        filter="url(#footer-laser-b)"
        opacity="0.4"
        transform="rotate(5 670.504 117.158)"
      />
      <polyline
        points="2.931 125.425 9.185 121.984 138.86 50.635 315.019 128.481 469.284 82.724 790.157 104.034 1147.886 38.008 1338.076 156.308 1147.886 45.547 795.855 142.659 469.179 86.224 312.229 126.313 145.144 60.134"
        transform="rotate(1 670.504 97.158)"
      />
    </g>
  </svg>
)

Footer.defaultProps = {
  className: '',
}

Footer.propTypes = {
  className: PropTypes.string,
}

export default Footer
