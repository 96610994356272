import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import style from './sponsorPrizes.module.css'

const SponsorPrizes = ({ prizes, className }) => (
  <svg
    className={clsx(style.sponsorPrizes, className)}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1092"
    height="325"
    viewBox="0 0 1092 325"
  >
    <defs>
      <path
        id="sponsorprizes-b"
        d="M2,0 L972,0 C973.104569,-6.29904129e-14 974,0.8954305 974,2 L974,206.151804 C974,207.256374 973.104569,208.151804 972,208.151804 C971.955375,208.151804 971.910763,208.150311 971.866238,208.147326 L675.629939,188.290354 L675.629939,188.290354 L2.05917809,208.229398 C0.955092223,208.262081 0.033558857,207.393538 0.00087570315,206.289452 C0.000291933006,206.269731 -8.03194057e-14,206.250003 0,206.230274 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z"
      />
      <filter
        id="sponsorprizes-a"
        width="120.5%"
        height="196%"
        x="-10.3%"
        y="-38.4%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="20" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="30" />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.179714816 0"
        />
      </filter>
      <linearGradient id="sponsorprizes-c" x1="38.513%" x2="61.594%" y1="84.924%" y2="16.33%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <linearGradient id="sponsorprizes-d" x1="28.953%" x2="71.243%" y1="84.924%" y2="16.33%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <linearGradient id="sponsorprizes-e" x1="12.453%" x2="87.895%" y1="84.924%" y2="16.33%">
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(59 39)">
      <use fill="#000" filter="url(#sponsorprizes-a)" xlinkHref="#sponsorprizes-b" />
      <use fill="#FFF" xlinkHref="#sponsorprizes-b" />
      <g transform="translate(81.047)">
        <image width="125" height="125" x="470" y="40" xlinkHref={prizes.firstPrize.fluid.src} />
        <text
          fill="#003A54"
          fillOpacity=".9"
          fontFamily="GeomanistBold, Geomanist"
          fontSize="27"
          fontWeight="bold"
        >
          <tspan x="3" y="114.145">
            All registrants entered to win!
          </tspan>
        </text>
        <text
          fill="#1D354F"
          fillOpacity=".9"
          fontFamily="GeomanistUltra, Geomanist"
          fontSize="50"
          fontWeight="600"
          letterSpacing="1.2"
          opacity=".055"
        >
          <tspan x="0" y="89.997">
            SPONSOR PRIZE
          </tspan>
        </text>
        <image width="125" height="125" x="650" y="40" xlinkHref={prizes.secondPrize.fluid.src} />
        <g transform="translate(557.183 19.145)">
          <polyline
            fill="#FFFFFE"
            points="28.092 14.544 26.076 10.378 24.072 14.542 19.484 15.167 22.828 18.365 22.002 22.916 26.076 20.73 30.15 22.916 29.334 18.368 32.666 15.167 28.092 14.544"
          />
          <path
            fill="url(#sponsorprizes-c)"
            d="M33.058 51.171L34.496 68.934 37.862 66.9938 45.118 68.2678 41.392 47.1306C38.91 48.9424 36.096 50.3258 33.058 51.171M10.758 47.1306L7.032 68.2678 14.288 66.9938 17.654 68.934 19.092 51.171C16.054 50.3258 13.24 48.9426 10.758 47.1306"
          />
          <path
            fill="url(#sponsorprizes-d)"
            d="M26.076,52.1208 C23.656,52.1208 21.314,51.7892 19.092,51.171 L17.164,74.9748 L26.076,71.8312 L34.986,74.9748 L33.058,51.171 C30.836,51.7892 28.494,52.1208 26.076,52.1208"
          />
          <path
            fill="#F6FBFA"
            d="M13.478,26.1206 C13.478,13.64 13.594,13.5224 26.076,13.5224 C38.556,13.5224 38.674,13.64 38.674,26.1206 C38.674,38.6014 38.556,38.719 26.076,38.719 C13.594,38.719 13.478,38.6014 13.478,26.1206 Z M33.058,51.171 C36.096,50.3258 38.91,48.9424 41.392,47.1306 C47.868,42.4016 52.076,34.7532 52.076,26.1206 C52.076,11.761 40.434,0.1204 26.076,0.1204 C11.716,0.1204 0.076,11.761 0.076,26.1206 C0.076,34.7532 4.284,42.4016 10.758,47.1306 C13.24,48.9426 16.054,50.3258 19.092,51.171 C21.314,51.7892 23.656,52.1208 26.076,52.1208 C28.494,52.1208 30.836,51.7892 33.058,51.171 Z"
          />
          <path
            fill="url(#sponsorprizes-e)"
            d="M15.5820703,42.937184 C14.4269062,42.2197687 13.35175,41.38795 12.37,40.4566 L12.37,40.4566 C11.474,39.6074 10.658,38.677 9.932,37.6754 L9.932,37.6754 L9.62086111,37.2329 C9.11211111,36.4881222 8.65266667,35.7072333 8.246,34.8944 L8.246,34.8944 L7.986352,34.355152 C7.6508,33.63032 7.358,32.88232 7.11,32.1132 L7.11,32.1132 L6.943696,31.5694992 C6.73224,30.840168 6.562,30.09348 6.434,29.3322 L6.434,29.3322 L6.350256,28.7851776 C6.24856,28.052576 6.1868,27.3072 6.166,26.5512 L6.166,26.5512 L6.1575,26.27845 L6.1575,26.27845 L6.152,26.0048 C6.152,15.0016 15.072,6.0818 26.076,6.0818 C37.078,6.0818 45.998,15.0016 45.998,26.0048 C45.998,26.188 45.99,26.3692 45.984,26.5512 L45.984,26.5512 L45.961696,27.1161744 C45.92144,27.866728 45.8392,28.60612 45.716,29.3322 L45.716,29.3322 C45.556,30.2838 45.33,31.2126 45.04,32.1132 L45.04,32.1132 L44.8464,32.686048 C44.57664,33.44448 44.2608,34.18128 43.904,34.8944 L43.904,34.8944 C43.418,35.8698 42.852,36.7992 42.218,37.6754 L42.218,37.6754 L41.9013819,38.1002688 C41.2572653,38.9411714 40.5482857,39.7287143 39.782,40.4566 L39.782,40.4566 L39.3554844,40.8496129 C38.3469375,41.7522219 37.24575,42.553175 36.068,43.2376 L36.068,43.2376 L35.6821146,43.4561977 C32.8328696,45.028786 29.5596522,45.9278 26.076,45.9278 C22.432,45.9278 19.02,44.9452 16.082,43.2376 L16.082,43.2376 Z"
          />
        </g>
        <g transform="translate(727.183 19.145)">
          <polyline
            fill="#FFFFFE"
            points="28.092 14.544 26.076 10.378 24.072 14.542 19.484 15.167 22.828 18.365 22.002 22.916 26.076 20.73 30.15 22.916 29.334 18.368 32.666 15.167 28.092 14.544"
          />
          <path
            fill="url(#sponsorprizes-c)"
            d="M33.058 51.171L34.496 68.934 37.862 66.9938 45.118 68.2678 41.392 47.1306C38.91 48.9424 36.096 50.3258 33.058 51.171M10.758 47.1306L7.032 68.2678 14.288 66.9938 17.654 68.934 19.092 51.171C16.054 50.3258 13.24 48.9426 10.758 47.1306"
          />
          <path
            fill="url(#sponsorprizes-d)"
            d="M26.076,52.1208 C23.656,52.1208 21.314,51.7892 19.092,51.171 L17.164,74.9748 L26.076,71.8312 L34.986,74.9748 L33.058,51.171 C30.836,51.7892 28.494,52.1208 26.076,52.1208"
          />
          <path
            fill="#F6FBFA"
            d="M13.478,26.1206 C13.478,13.64 13.594,13.5224 26.076,13.5224 C38.556,13.5224 38.674,13.64 38.674,26.1206 C38.674,38.6014 38.556,38.719 26.076,38.719 C13.594,38.719 13.478,38.6014 13.478,26.1206 Z M33.058,51.171 C36.096,50.3258 38.91,48.9424 41.392,47.1306 C47.868,42.4016 52.076,34.7532 52.076,26.1206 C52.076,11.761 40.434,0.1204 26.076,0.1204 C11.716,0.1204 0.076,11.761 0.076,26.1206 C0.076,34.7532 4.284,42.4016 10.758,47.1306 C13.24,48.9426 16.054,50.3258 19.092,51.171 C21.314,51.7892 23.656,52.1208 26.076,52.1208 C28.494,52.1208 30.836,51.7892 33.058,51.171 Z"
          />
          <path
            fill="url(#sponsorprizes-e)"
            d="M15.5820703,42.937184 C14.4269062,42.2197687 13.35175,41.38795 12.37,40.4566 L12.37,40.4566 C11.474,39.6074 10.658,38.677 9.932,37.6754 L9.932,37.6754 L9.62086111,37.2329 C9.11211111,36.4881222 8.65266667,35.7072333 8.246,34.8944 L8.246,34.8944 L7.986352,34.355152 C7.6508,33.63032 7.358,32.88232 7.11,32.1132 L7.11,32.1132 L6.943696,31.5694992 C6.73224,30.840168 6.562,30.09348 6.434,29.3322 L6.434,29.3322 L6.350256,28.7851776 C6.24856,28.052576 6.1868,27.3072 6.166,26.5512 L6.166,26.5512 L6.1575,26.27845 L6.1575,26.27845 L6.152,26.0048 C6.152,15.0016 15.072,6.0818 26.076,6.0818 C37.078,6.0818 45.998,15.0016 45.998,26.0048 C45.998,26.188 45.99,26.3692 45.984,26.5512 L45.984,26.5512 L45.961696,27.1161744 C45.92144,27.866728 45.8392,28.60612 45.716,29.3322 L45.716,29.3322 C45.556,30.2838 45.33,31.2126 45.04,32.1132 L45.04,32.1132 L44.8464,32.686048 C44.57664,33.44448 44.2608,34.18128 43.904,34.8944 L43.904,34.8944 C43.418,35.8698 42.852,36.7992 42.218,37.6754 L42.218,37.6754 L41.9013819,38.1002688 C41.2572653,38.9411714 40.5482857,39.7287143 39.782,40.4566 L39.782,40.4566 L39.3554844,40.8496129 C38.3469375,41.7522219 37.24575,42.553175 36.068,43.2376 L36.068,43.2376 L35.6821146,43.4561977 C32.8328696,45.028786 29.5596522,45.9278 26.076,45.9278 C22.432,45.9278 19.02,44.9452 16.082,43.2376 L16.082,43.2376 Z"
          />
        </g>
        <text fill="#FFF" fillOpacity=".9" fontFamily="Geomanist" fontSize="18">
          <tspan x="574" y="51.645">
            1st
          </tspan>
        </text>
        <text fill="#FFF" fillOpacity=".9" fontFamily="Geomanist" fontSize="18">
          <tspan x="738" y="51.645">
            2nd
          </tspan>
        </text>
      </g>
      <text fill="#133650" fillOpacity=".9" fontFamily="Geomanist" fontSize="10">
        <tspan x="83.957" y="147.997">
          Registrants must attend the webinar in order to win.
        </tspan>
      </text>
    </g>
  </svg>
)

const SponsorPrizesMobile = ({ prizes, className }) => (
  <svg
    className={clsx(style.sponsorPrizesMobile, className)}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="673"
    height="528"
    viewBox="0 0 673 528"
  >
    <defs>
      <path
        id="sponsor-prizes-mobile-b"
        d="M2,0 L553,0 C554.104569,-4.2495073e-16 555,0.8954305 555,2 L555,409.481201 C555,410.58577 554.104569,411.481201 553,411.481201 C552.847422,411.481201 552.695346,411.463741 552.546738,411.429162 L384.984205,372.439839 L384.984205,372.439839 L2.20443924,411.773476 C1.10565564,411.886385 0.123384964,411.087176 0.010476289,409.988392 C0.00349667537,409.920469 -1.10186107e-15,409.852233 0,409.783953 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z"
      />
      <filter
        id="sponsor-prizes-mobile-a"
        width="136%"
        height="148.5%"
        x="-18%"
        y="-19.4%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="20" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="30" />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.179714816 0"
        />
      </filter>
      <linearGradient
        id="sponsor-prizes-mobile-c"
        x1="38.513%"
        x2="61.594%"
        y1="84.924%"
        y2="16.33%"
      >
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <linearGradient
        id="sponsor-prizes-mobile-d"
        x1="28.953%"
        x2="71.243%"
        y1="84.924%"
        y2="16.33%"
      >
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
      <linearGradient
        id="sponsor-prizes-mobile-e"
        x1="12.453%"
        x2="87.895%"
        y1="84.924%"
        y2="16.33%"
      >
        <stop offset="0%" stopColor="#1D8DBA" />
        <stop offset="100%" stopColor="#03ECAE" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(59 39)">
      <use
        fill="#000"
        filter="url(#sponsor-prizes-mobile-a)"
        xlinkHref="#sponsor-prizes-mobile-b"
      />
      <use fill="#FFF" xlinkHref="#sponsor-prizes-mobile-b" />
      <g transform="translate(81.047 42)">
        <image width="125" height="125" x="30" y="180" xlinkHref={prizes.firstPrize.fluid.src} />
        <text
          fill="#003A54"
          fillOpacity=".9"
          fontFamily="GeomanistBold, Geomanist"
          fontSize="27"
          fontWeight="bold"
        >
          <tspan x="3" y="72.145">
            All registrants entered to win!
          </tspan>
        </text>
        <text
          fill="#1D354F"
          fillOpacity=".9"
          fontFamily="GeomanistUltra, Geomanist"
          fontSize="50"
          fontWeight="600"
          letterSpacing="1.2"
          opacity=".055"
        >
          <tspan x="0" y="47.997">
            SPONSOR PRIZE
          </tspan>
        </text>
        <image width="125" height="125" x="210" y="185" xlinkHref={prizes.secondPrize.fluid.src} />
        <g transform="translate(119.802 159.145)">
          <polyline
            fill="#FFFFFE"
            points="28.092 14.544 26.076 10.378 24.072 14.542 19.484 15.167 22.828 18.365 22.002 22.916 26.076 20.73 30.15 22.916 29.334 18.368 32.666 15.167 28.092 14.544"
          />
          <path
            fill="url(#sponsor-prizes-mobile-c)"
            d="M33.058 51.171L34.496 68.934 37.862 66.9938 45.118 68.2678 41.392 47.1306C38.91 48.9424 36.096 50.3258 33.058 51.171M10.758 47.1306L7.032 68.2678 14.288 66.9938 17.654 68.934 19.092 51.171C16.054 50.3258 13.24 48.9426 10.758 47.1306"
          />
          <path
            fill="url(#sponsor-prizes-mobile-d)"
            d="M26.076,52.1208 C23.656,52.1208 21.314,51.7892 19.092,51.171 L17.164,74.9748 L26.076,71.8312 L34.986,74.9748 L33.058,51.171 C30.836,51.7892 28.494,52.1208 26.076,52.1208"
          />
          <path
            fill="#F6FBFA"
            d="M13.478,26.1206 C13.478,13.64 13.594,13.5224 26.076,13.5224 C38.556,13.5224 38.674,13.64 38.674,26.1206 C38.674,38.6014 38.556,38.719 26.076,38.719 C13.594,38.719 13.478,38.6014 13.478,26.1206 Z M33.058,51.171 C36.096,50.3258 38.91,48.9424 41.392,47.1306 C47.868,42.4016 52.076,34.7532 52.076,26.1206 C52.076,11.761 40.434,0.1204 26.076,0.1204 C11.716,0.1204 0.076,11.761 0.076,26.1206 C0.076,34.7532 4.284,42.4016 10.758,47.1306 C13.24,48.9426 16.054,50.3258 19.092,51.171 C21.314,51.7892 23.656,52.1208 26.076,52.1208 C28.494,52.1208 30.836,51.7892 33.058,51.171 Z"
          />
          <path
            fill="url(#sponsor-prizes-mobile-e)"
            d="M15.5820703,42.937184 C14.4269062,42.2197687 13.35175,41.38795 12.37,40.4566 L12.37,40.4566 C11.474,39.6074 10.658,38.677 9.932,37.6754 L9.932,37.6754 L9.62086111,37.2329 C9.11211111,36.4881222 8.65266667,35.7072333 8.246,34.8944 L8.246,34.8944 L7.986352,34.355152 C7.6508,33.63032 7.358,32.88232 7.11,32.1132 L7.11,32.1132 L6.943696,31.5694992 C6.73224,30.840168 6.562,30.09348 6.434,29.3322 L6.434,29.3322 L6.350256,28.7851776 C6.24856,28.052576 6.1868,27.3072 6.166,26.5512 L6.166,26.5512 L6.1575,26.27845 L6.1575,26.27845 L6.152,26.0048 C6.152,15.0016 15.072,6.0818 26.076,6.0818 C37.078,6.0818 45.998,15.0016 45.998,26.0048 C45.998,26.188 45.99,26.3692 45.984,26.5512 L45.984,26.5512 L45.961696,27.1161744 C45.92144,27.866728 45.8392,28.60612 45.716,29.3322 L45.716,29.3322 C45.556,30.2838 45.33,31.2126 45.04,32.1132 L45.04,32.1132 L44.8464,32.686048 C44.57664,33.44448 44.2608,34.18128 43.904,34.8944 L43.904,34.8944 C43.418,35.8698 42.852,36.7992 42.218,37.6754 L42.218,37.6754 L41.9013819,38.1002688 C41.2572653,38.9411714 40.5482857,39.7287143 39.782,40.4566 L39.782,40.4566 L39.3554844,40.8496129 C38.3469375,41.7522219 37.24575,42.553175 36.068,43.2376 L36.068,43.2376 L35.6821146,43.4561977 C32.8328696,45.028786 29.5596522,45.9278 26.076,45.9278 C22.432,45.9278 19.02,44.9452 16.082,43.2376 L16.082,43.2376 Z"
          />
        </g>
        <g transform="translate(289.802 159.145)">
          <polyline
            fill="#FFFFFE"
            points="28.092 14.544 26.076 10.378 24.072 14.542 19.484 15.167 22.828 18.365 22.002 22.916 26.076 20.73 30.15 22.916 29.334 18.368 32.666 15.167 28.092 14.544"
          />
          <path
            fill="url(#sponsor-prizes-mobile-c)"
            d="M33.058 51.171L34.496 68.934 37.862 66.9938 45.118 68.2678 41.392 47.1306C38.91 48.9424 36.096 50.3258 33.058 51.171M10.758 47.1306L7.032 68.2678 14.288 66.9938 17.654 68.934 19.092 51.171C16.054 50.3258 13.24 48.9426 10.758 47.1306"
          />
          <path
            fill="url(#sponsor-prizes-mobile-d)"
            d="M26.076,52.1208 C23.656,52.1208 21.314,51.7892 19.092,51.171 L17.164,74.9748 L26.076,71.8312 L34.986,74.9748 L33.058,51.171 C30.836,51.7892 28.494,52.1208 26.076,52.1208"
          />
          <path
            fill="#F6FBFA"
            d="M13.478,26.1206 C13.478,13.64 13.594,13.5224 26.076,13.5224 C38.556,13.5224 38.674,13.64 38.674,26.1206 C38.674,38.6014 38.556,38.719 26.076,38.719 C13.594,38.719 13.478,38.6014 13.478,26.1206 Z M33.058,51.171 C36.096,50.3258 38.91,48.9424 41.392,47.1306 C47.868,42.4016 52.076,34.7532 52.076,26.1206 C52.076,11.761 40.434,0.1204 26.076,0.1204 C11.716,0.1204 0.076,11.761 0.076,26.1206 C0.076,34.7532 4.284,42.4016 10.758,47.1306 C13.24,48.9426 16.054,50.3258 19.092,51.171 C21.314,51.7892 23.656,52.1208 26.076,52.1208 C28.494,52.1208 30.836,51.7892 33.058,51.171 Z"
          />
          <path
            fill="url(#sponsor-prizes-mobile-e)"
            d="M15.5820703,42.937184 C14.4269062,42.2197687 13.35175,41.38795 12.37,40.4566 L12.37,40.4566 C11.474,39.6074 10.658,38.677 9.932,37.6754 L9.932,37.6754 L9.62086111,37.2329 C9.11211111,36.4881222 8.65266667,35.7072333 8.246,34.8944 L8.246,34.8944 L7.986352,34.355152 C7.6508,33.63032 7.358,32.88232 7.11,32.1132 L7.11,32.1132 L6.943696,31.5694992 C6.73224,30.840168 6.562,30.09348 6.434,29.3322 L6.434,29.3322 L6.350256,28.7851776 C6.24856,28.052576 6.1868,27.3072 6.166,26.5512 L6.166,26.5512 L6.1575,26.27845 L6.1575,26.27845 L6.152,26.0048 C6.152,15.0016 15.072,6.0818 26.076,6.0818 C37.078,6.0818 45.998,15.0016 45.998,26.0048 C45.998,26.188 45.99,26.3692 45.984,26.5512 L45.984,26.5512 L45.961696,27.1161744 C45.92144,27.866728 45.8392,28.60612 45.716,29.3322 L45.716,29.3322 C45.556,30.2838 45.33,31.2126 45.04,32.1132 L45.04,32.1132 L44.8464,32.686048 C44.57664,33.44448 44.2608,34.18128 43.904,34.8944 L43.904,34.8944 C43.418,35.8698 42.852,36.7992 42.218,37.6754 L42.218,37.6754 L41.9013819,38.1002688 C41.2572653,38.9411714 40.5482857,39.7287143 39.782,40.4566 L39.782,40.4566 L39.3554844,40.8496129 C38.3469375,41.7522219 37.24575,42.553175 36.068,43.2376 L36.068,43.2376 L35.6821146,43.4561977 C32.8328696,45.028786 29.5596522,45.9278 26.076,45.9278 C22.432,45.9278 19.02,44.9452 16.082,43.2376 L16.082,43.2376 Z"
          />
        </g>
        <text fill="#FFF" fillOpacity=".9" fontFamily="Geomanist" fontSize="18">
          <tspan x="136.619" y="191.645">
            1st
          </tspan>
        </text>
        <text fill="#FFF" fillOpacity=".9" fontFamily="Geomanist" fontSize="18">
          <tspan x="300.619" y="191.645">
            2nd
          </tspan>
        </text>
      </g>
      <text fill="#133650" fillOpacity=".9" fontFamily="Geomanist" fontSize="10">
        <tspan x="83.957" y="147.997">
          Registrants must attend the webinar in order to win.
        </tspan>
      </text>
    </g>
  </svg>
)

SponsorPrizes.defaultProps = {
  className: '',
}

SponsorPrizes.propTypes = {
  className: PropTypes.string,
  prizes: PropTypes.shape({
    firstPrize: PropTypes.object,
    secondPrize: PropTypes.object,
  }).isRequired,
}

SponsorPrizesMobile.defaultProps = {
  className: '',
}

SponsorPrizesMobile.propTypes = {
  className: PropTypes.string,
  prizes: PropTypes.shape({
    firstPrize: PropTypes.object,
    secondPrize: PropTypes.object,
  }).isRequired,
}

export default SponsorPrizes
export { SponsorPrizesMobile }
