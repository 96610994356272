import React from 'react'
import PropTypes from 'prop-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Layout from 'components/Layout'
import PartnersSEO from 'templates/SEO/Partner'

const options = {
  renderText: text => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, 0 < index && <br key={index} />, textSegment] // eslint-disable-line react/no-array-index-key
    }, [])
  },
}

const PartnersTemplate = ({ pageContext }) => (
  <Layout title={pageContext.page.title} description={pageContext.page.description} keywords="">
    <PartnersSEO
      title={pageContext.title}
      logo={pageContext.logo.fluid.src}
      name={pageContext.name}
      type={pageContext.type.type}
      url={pageContext.url}
      description={documentToReactComponents(pageContext.description.json, options)}
    />
  </Layout>
)

PartnersTemplate.defaultProps = {}

PartnersTemplate.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export default PartnersTemplate
