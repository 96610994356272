import React from 'react'

import Layout from 'components/Layout'
import { Hero, Contact } from 'components/Page/AppD'

export default () => (
  <Layout
    title="Contact Us | AppDynamics"
    description="Contact Us to help you deliver software better"
    keywords="contact,contact us,observian,appdynamics"
  >
    <Hero />
    <Contact />
  </Layout>
)
