import React from 'react'
import PropTypes from 'prop-types'

const WorkPlayHard = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1356"
    height="251"
    viewBox="0 0 1356 251"
  >
    <defs>
      <text
        id="work_play_hard-b"
        fill="#F9FAFA"
        fontFamily="GeomanistUltra, Geomanist"
        fontSize="180"
        fontWeight="600"
        letterSpacing="4.32"
      >
        <tspan x="0" y="171">
          WORK HARD
        </tspan>
      </text>
      <filter
        id="work_play_hard-a"
        width="108.6%"
        height="164.8%"
        x="-4.3%"
        y="-23.1%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="20" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="20" />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 1 0"
        />
      </filter>
      <text
        id="work_play_hard-d"
        fill="#F9FAFA"
        fontFamily="GeomanistUltra, Geomanist"
        fontSize="180"
        fontWeight="600"
        letterSpacing="4.32"
      >
        <tspan x="299.326" y="273.88">
          PLAY HARD
        </tspan>
      </text>
      <filter
        id="work_play_hard-c"
        width="107.4%"
        height="131.4%"
        x="-3.7%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="20" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="15" />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 1 0"
        />
      </filter>
    </defs>
    <g fill="#F9FAFA" fillRule="evenodd" transform="translate(36 -24)">
      <g opacity="0.04">
        <use filter="url(#work_play_hard-a)" xlinkHref="#work_play_hard-b" />
        <use xlinkHref="#work_play_hard-b" />
      </g>
      <g opacity="0.05">
        <use filter="url(#work_play_hard-c)" xlinkHref="#work_play_hard-d" />
        <use xlinkHref="#work_play_hard-d" />
      </g>
    </g>
  </svg>
)

WorkPlayHard.defaultProps = {
  className: '',
}

WorkPlayHard.propTypes = {
  className: PropTypes.string,
}

export default WorkPlayHard
