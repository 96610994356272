import React from 'react'
import PropTypes from 'prop-types'

const WhatWeDo = ({ className, classNames, type }) => {
  switch (type) {
    case 'top':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          width="763"
          height="229"
          viewBox="0 0 763 229"
        >
          <defs>
            <linearGradient
              id="what-we-do-top-laser-a"
              x1="15.436%"
              x2="114.319%"
              y1="90.518%"
              y2="21.413%"
            >
              <stop offset="0%" stopColor="#FFC42A" />
              <stop offset="100%" stopColor="#FF0061" stopOpacity="0.946" />
            </linearGradient>
            <filter
              id="what-we-do-top-laser-b"
              width="107.9%"
              height="145.5%"
              x="-4%"
              y="-22.7%"
              filterUnits="objectBoundingBox"
            >
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
          </defs>
          <g fill="url(#what-we-do-top-laser-a)" fillRule="evenodd" transform="translate(0 16)">
            <polygon points="0 132 223.686 34.467 464.734 74.248 756 0 423.543 101.993 229.544 49.448" />
            <polygon
              className={classNames.glow}
              points="4.314 164.694 227.999 67.161 502.548 104.015 760.314 32.694 428.597 147.039 233.858 82.142"
              filter="url(#what-we-do-top-laser-b)"
              opacity="0.249"
              transform="rotate(-5 382.314 98.694)"
            />
          </g>
        </svg>
      )

    default:
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          width="1344"
          height="180"
          viewBox="0 0 1344 180"
        >
          <defs>
            <linearGradient
              id="what-we-do-bottom-laser-a"
              x1="12.453%"
              x2="87.895%"
              y1="84.924%"
              y2="16.33%"
            >
              <stop offset="0%" stopColor="#1D8DBA" />
              <stop offset="100%" stopColor="#03ECAE" />
            </linearGradient>
            <filter
              id="what-we-do-bottom-laser-b"
              width="114.8%"
              height="182.2%"
              x="-7.4%"
              y="-41.1%"
              filterUnits="objectBoundingBox"
            >
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
            <filter
              id="what-we-do-bottom-laser-c"
              width="107.8%"
              height="176.9%"
              x="-3.9%"
              y="-38.5%"
              filterUnits="objectBoundingBox"
            >
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
            </filter>
          </defs>
          <g fill="url(#what-we-do-bottom-laser-a)" fillRule="evenodd" transform="translate(-3 5)">
            <polygon
              points="4.258 108.605 192.227 35.605 409.258 53.386 167.038 58.27"
              filter="url(#what-we-do-bottom-laser-b)"
              opacity="0.7"
              transform="rotate(-10 206.758 72.105)"
            />
            <polygon points="5 109 192.969 36 410 53.781 167.78 58.665" />
            <polygon
              points="566.784 101.786 782.868 86.727 908.008 120.676 1185.234 141.915 1335.784 120.676 1156.224 164.727 928.008 131.443 775.028 101.786"
              filter="url(#what-we-do-bottom-laser-c)"
              opacity="0.74"
              transform="rotate(-4 951.284 125.727)"
            />
            <polygon points="567 93.06 783.084 78 908.225 111.949 1185.45 133.189 1336 111.949 1156.44 156 928.224 122.716 775.244 93.06" />
          </g>
        </svg>
      )
  }
}

WhatWeDo.defaultProps = {
  className: '',
  classNames: {
    glow: 'glow',
  },
  type: '',
}

WhatWeDo.propTypes = {
  className: PropTypes.string,
  classNames: PropTypes.shape({
    glow: PropTypes.string,
  }),
  type: PropTypes.string,
}

export default WhatWeDo
