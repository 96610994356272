import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'
import Post from 'components/Page/Blog/Post'
import { transformTopics, transformPosts } from 'utils/blogTransform'

/* eslint-disable */
class BlogPostTemplate extends React.Component {
  render() {
    const {
      data: {
        observian: {
          filteredBlogPosts: { currentPost },
          allBlogPosts: { posts },
          allTopics: { topics },
          allAuthors: { authors },
        },
        allEmployees: { members },
      },
    } = this.props

    const allCurrentTopics = transformTopics(topics)
    const allCurrentPosts = transformPosts({ authors, members, posts })
    const [post] = transformPosts({ authors, members, posts: currentPost})

    return (
      <Layout
        title={`${post && post.name || 'Blog'}`}
        description={`${post && post.metaDescription || 'Learn all things cloud from certified experts.'}`}
        keywords="blog,cloud,all things cloud,experts,certified experts,all things"
      >
        <Post post={post} topics={allCurrentTopics} posts={allCurrentPosts} />
      </Layout>
    )
  }
}

BlogPostTemplate.defaultProps = {}

BlogPostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query BlogPostTemplateQuery($slug: String!) {
    observian {
      filteredBlogPosts: blogPosts(slug: $slug, state: PUBLISHED) {
        currentPost: posts {
          postId
          slug
          postBody
          name
          topicIds
          metaDescription
          featuredImage
          publishDate
          blogAuthor {
            authorId
            avatar
            fullName
            email
          }
        }
      },
      allBlogPosts: blogPosts(state: PUBLISHED) {
        posts {
          postId
          slug
          postBody
          name
          topicIds
          metaDescription
          featuredImage
          publishDate
          blogAuthor {
            authorId
            avatar
            fullName
            email
          }
        }
      }
      allAuthors: blogPostAuthors {
        authors {
          authorId
          avatar
          email
          fullName
          slug
          displayName
        }
      }
      allTopics: blogPostTopics {
        topics {
          topicId
          name
          slug
        }
      }
    },
    allEmployees: allContentfulEmployee {
      members: nodes {
        id
        employeeId
        firstName
        lastName
        fullName
        title
        company
        photos {
          profile {
            fixed(width: 100, quality: 100) {
              src
            }
          }
        }
        emails {
          primary
          alias
        }
      }
    }
  }
`

export default BlogPostTemplate
