/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onClientEntry = async () => {
  if ('undefined' === typeof IntersectionObserver) {
    await import('intersection-observer')
  }
}
