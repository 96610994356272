import React from 'react'
import PropTypes from 'prop-types'

import getIP from 'api/ip'
import { getSpecificCookie } from 'utils/tracking'
import canUseDOM from 'utils/canUseDOM'

class HubspotTracking extends React.Component {
  static getPageTitle = () => {
    const { title } = document
    return title
  }

  static getPageUrl = () => {
    const { location } = document
    return location && location.href
  }

  state = {
    hutk: '',
    pageName: '',
    pageUri: '',
    ipAddress: '',
  }

  componentDidMount = () => {
    if (canUseDOM) {
      this.handleOnChange('hutk', getSpecificCookie('hubspotutk'))
      this.handleOnChange('pageName', HubspotTracking.getPageTitle())
      this.handleOnChange('pageUri', HubspotTracking.getPageUrl())
      this.handleIP()
    }
  }

  handleOnChange = (key, value) => {
    this.setState(state => ({
      ...state,
      [key]: value,
    }))
  }

  handleIP = async () => {
    try {
      const res = await getIP()

      return this.handleOnChange('ipAddress', res)
    } catch (error) {
      return console.error(error)
    }
  }

  render() {
    const { children } = this.props

    const hsContext = this.state
    const context = this.state
    return children({ hsContext, context })
  }
}

HubspotTracking.propTypes = {
  children: PropTypes.any.isRequired,
}

export default HubspotTracking
