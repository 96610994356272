const googleProducts = [
  {
    name: 'Google Docs',
    description:
      'Collaborate seamlessly with Google Docs. With smart-editing and styling tools, creating and working together on your documents is simple. A wide variety of templates and fonts, as well as the ability to add links, drawings, and images are available and ready for your team.',
    icon: '/images/logos/google/gsuite/docs.png',
  },
  {
    name: 'Google Sheets',
    description:
      'Keep track of all your data company-wide with user-friendly Google Sheets. Colorful charts and graphs, in addition to pivot tables, conditional formatting, and built-in formulas, makes your data easy to navigate.',
    icon: '/images/logos/google/gsuite/sheets.png',
  },
  {
    name: 'Google Slides',
    description:
      'Take your presentations on-the-go with Google Slides. Available on any device, you and your company can make the perfect presentation every time. Equipped with different types and styles of templates, Google Slides is ready for your presentation.',
    icon: '/images/logos/google/gsuite/slides.png',
  },
  {
    name: 'Google Groups',
    description:
      'Communicate and collaborate easily with Google Groups. This tool gives you the ability to send an email to multiple people with only one address, create and send invites to events, or easily share a document with a group.',
    icon: '/images/logos/google/gsuite/groups.png',
  },
  {
    name: 'Google Drive',
    description:
      'Store all your documents, presentations, and everything else in Google Drive. This is the one-stop shop for all your projects in the cloud. A versatile cloud storage service, Google Drive keeps your most valuable work in one place.',
    icon: '/images/logos/google/gsuite/drive.png',
  },
  {
    name: 'Google Sites',
    description:
      'Create a site just for your team. With Google Sites, you don’t need to be a programmer or designer to create an engaging site on any device. Feature reports and team updates that look great on desktop, tablet, and mobile are easily made with Google Sites.',
    icon: '/images/logos/google/gsuite/sites.png',
  },
  {
    name: 'Google Tasks',
    description:
      'A virtual to-do list, Google Tasks keeps you on track. This handy tool keeps all your tasks in one place, so you won’t misplace your to-do list ever again. Virtually cross items off the list, and stay on top of your workload easily.',
    icon: '/images/logos/google/gsuite/tasks.png',
  },
  {
    name: 'Google Keep',
    description:
      'Keep your random thoughts, lists, and ideas together with Google Keep. Available across all your devices, keeping track of your notes is simple with Google Keep.',
    icon: '/images/logos/google/gsuite/keep.png',
  },
  {
    name: 'Hangouts Meet',
    description:
      'Communicate easily through text, voice, or video chats one-on-one or in a group with Hangouts. Chatting with coworkers and other business associates is simple.',
    icon: '/images/logos/google/gsuite/meet.png',
  },
]

export default googleProducts
