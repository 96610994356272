import React from 'react'
import PropTypes from 'prop-types'

import Layout from 'components/Layout'
import { isStaging, isProd } from 'utils/env'

import Hero from './Hero'
import Sponsors from './Sponsors'

const testFormId = '7eabed39-de79-4164-bf46-b9ada082ac0b'
const renderDate = (timestamp, options) => {
  return new Date(timestamp).toLocaleString('en-US', options)
}

const EventsTemplate = ({ pageContext }) => {
  const formId = isProd() ? (!isStaging() ? pageContext.formId : testFormId) : testFormId
  const eventDate = renderDate(pageContext.date, {
    timeZone: 'America/Denver',
    month: 'long',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    weekday: 'long',
    hour12: true,
  })
  const endTime = renderDate(pageContext.endTime, {
    timeZone: 'America/Denver',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })

  return (
    <Layout title={`${pageContext.name} | Events`} description={pageContext.seoDescription}>
      <Hero
        eventName={pageContext.name}
        eventDate={`${eventDate} - ${endTime}`}
        eventDateSecondLine={pageContext.agendaFirst}
        eventDateThirdLine={pageContext.agendaSecond}
        eventLocation={pageContext.location}
        eventImage={pageContext.image.file.url}
        eventDescription={pageContext.description.description}
        eventFormTitle={pageContext.formTitle}
        eventFormCTA={pageContext.formCta}
        formId={formId}
        isCancelled={pageContext.cancelled}
      />
      <Sponsors
        eventFormTitle={pageContext.formTitle}
        eventFormCTA={pageContext.formCta}
        formId={formId}
        sponsors={pageContext.sponsors}
      />
    </Layout>
  )
}

EventsTemplate.defaultProps = {}

EventsTemplate.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export default EventsTemplate
export { Hero, Sponsors }
