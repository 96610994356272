import React from 'react'

import style from './doCloudBetter.module.css'

const DoCloudBetter = () => (
  <div className={style.doCloudBetter}>
    <div className={style.tealBG}>
      <div className={style.whiteBG}>
        <div className={style.content}>
          <h1>Cloud Solutions - Do Cloud Better.</h1>
          <p>
            We are a cloud solutions company. That might sound over-simplified, but it&apos;s
            cleaner than a long list of descriptors. And we like to keep things simple. So
            let&apos;s start with a question: What would the perfect cloud solution look like for
            your company? We answer that question with a collaborative explosion of technical
            know-how, development skills, and operational experience to bring you innovative
            solutions that deliver value.
          </p>
          <div className={style.horizontal} />
          <div className={style.subContent}>
            <h3>But what makes these solutions valuable?</h3>
            <div className={style.vertical} />
            <div className={style.list}>
              <h5>Optimizing your cloud services:</h5>
              <ul>
                <li>
                  Lowers operational costs by providing visibility into where you are spending.
                </li>
                <li>Frees up your best developers by automating processes.</li>
                <li>Allows you to spend more time focusing on your core business.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className={style.lightningContainer}>
      <svg
        className={style.lightning}
        xmlns="http://www.w3.org/2000/svg"
        width="406"
        height="88"
        viewBox="0 0 406 88"
      >
        <defs>
          <linearGradient id="blue_lightning-a" x1="12.5%" x2="88%" y1="85%" y2="16%">
            <stop offset="0%" stopColor="#1D8DBA" />
            <stop offset="100%" stopColor="#03ECAE" />
          </linearGradient>
          <filter
            id="blue_lightning-b"
            width="110.5%"
            height="230.9%"
            x="-5.3%"
            y="-65.5%"
            filterUnits="objectBoundingBox"
          >
            <feGaussianBlur in="SourceGraphic" stdDeviation="7" />
          </filter>
        </defs>
        <g fill="url(#blue_lightning-a)" fillRule="evenodd" transform="translate(4 -3)">
          <polygon
            points=".469 53.725 115.346 69.812 399.085 37.735 115.346 60.51"
            filter="url(#blue_lightning-b)"
            opacity="0.691"
            transform="rotate(11 199.777 53.773)"
          />
          <polygon
            points=".469 53.725 115.346 69.812 399.085 37.735 115.346 60.51"
            transform="rotate(7 199.777 53.773)"
          />
        </g>
      </svg>
    </div>
  </div>
)

export default DoCloudBetter
