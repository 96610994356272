import React from 'react'
import PropTypes from 'prop-types'

const TechLines = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="534"
    height="291"
    viewBox="0 0 534 291"
  >
    <g fill="none" fillRule="evenodd" opacity="0.404">
      <g stroke="#03BB8B" transform="matrix(-1 0 0 1 244.645 .796)">
        <polyline points="43.145 289.204 112.287 218.818 81.725 188.256 101.386 168.595 217.843 52.138" />
        <polyline points="33.418 277.688 92.287 218.818 61.725 188.256 81.386 168.595 197.843 52.138 146.53 .826" />
        <polyline points=".145 289.204 72.287 218.818 41.725 188.256 61.386 168.595 177.843 52.138 126.53 .826" />
        <polyline points="74.391 136.124 64.369 126.102 137.843 52.138 86.53 .826" />
        <polyline points="21.645 289.204 92.287 218.818 61.725 188.256" />
        <polyline points="63.145 289.204 132.287 218.818 101.725 188.256 151.386 138.595" />
        <polyline points="103.145 289.204 172.287 218.818 141.725 188.256 191.386 138.595 242.598 189.806" />
      </g>
      <circle cx="93" cy="139" r="2" fill="#03BB8B" />
      <circle cx="147" cy="114" r="2" fill="#03BB8B" />
      <circle cx="27" cy="53" r="2" fill="#03BB8B" />
      <circle cx="98" cy="2" r="2" fill="#03BB8B" />
      <circle cx="118" cy="2" r="2" fill="#03BB8B" />
      <circle cx="158" cy="2" r="2" fill="#03BB8B" />
      <circle cx="2.442" cy="189.847" r="2" fill="#03BB8B" />
      <polyline stroke="#03BB8B" points="147.893 114.719 218.984 185.81 533.18 185.81" />
    </g>
  </svg>
)

TechLines.defaultProps = {
  className: '',
}

TechLines.propTypes = {
  className: PropTypes.string,
}

export default TechLines
