import React from 'react'
import PropTypes from 'prop-types'
import cn from 'clsx'
import Logo from 'observian-ui/lib/logo'
import Button from 'observian-ui/lib/button'
import ContactModal from 'observian-ui/lib/modal/contact'

import GoogleForm from 'components/Form'

import style from './gsuite.module.css'

class GSuite extends React.Component {
  state = {
    showModal: false,
    isSuccessful: false,
  }

  handleModal = () => {
    this.setState(state => ({
      showModal: !state.showModal,
    }))
  }

  handleIsSuccessful = value => {
    this.setState({ isSuccessful: value })
  }

  render() {
    const { showModal, isSuccessful } = this.state
    const { formId } = this.props

    return (
      <section className={style.section}>
        <div className={style.contentContainer}>
          <svg
            className={style.topDivider}
            xmlns="http://www.w3.org/2000/svg"
            width="1632"
            height="76"
            viewBox="0 0 1632 76"
          >
            <polygon fill="#FFF" fillRule="evenodd" points="1631.5 0 1136 76 140.5 27.5 0 0" />
          </svg>
          <div className={style.container}>
            <div className={style.content}>
              <header className={style.header}>
                <img
                  className={cn(style.contentItem, style.gsuite)}
                  src="/images/logos/google/gsuite/gsuite.png"
                  alt="GSuite"
                />
                <span className={cn(style.contentItem, style.add)}>+</span>
                <Logo className={cn(style.contentItem, style.logo)} />
                <span className={cn(style.contentItem, style.equals)}> = </span>
                <div className={style.contentItem}>
                  <div className={style.confetti} />
                  <img className={style.thumb} alt="Thumb Emoji" src="/images/thumb.png" />
                  <svg
                    className={style.thumbBG}
                    width="116px"
                    height="123px"
                    viewBox="0 0 116 123"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <defs>
                      <linearGradient
                        x1="12.4534884%"
                        y1="84.9243045%"
                        x2="87.8953488%"
                        y2="16.3295925%"
                        id="thumbEmojiBG"
                      >
                        <stop stopColor="#1D8DBA" offset="0%" />
                        <stop stopColor="#03ECAE" offset="100%" />
                      </linearGradient>
                    </defs>
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                      opacity="0.289248512"
                    >
                      <g
                        transform="translate(-471.000000, -265.000000)"
                        fill="url(#thumbEmojiBG)"
                        fillRule="nonzero"
                      >
                        <g transform="translate(471.000000, 265.000000)">
                          <polygon points="37.5605469 41.7484304 12.2792969 41.7484304 24.9199219 60.1510051 0.91015625 84.213855 32.8730469 88.882459 32.8730469 123.023553 76.3339844 96.6850861 111.083984 96.6850861 72.4667969 72.2844662 115.433594 39.7129531 72.4667969 43.4166951 76.3339844 0.310770615" />
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              </header>
              <h1 className={style.title}>Save Money and Get Free Training</h1>
              <p className={style.description}>
                Observian has partnered with Google to bring our customers best in class
                productivity and collaboration with G Suite. Contact Observian to see how much you
                can save on licensing.
              </p>
              <Button
                variant="secondary"
                className={style.button}
                label="Get a G Suite Quote"
                onClick={this.handleModal}
              />
              {showModal ? (
                <ContactModal
                  showClose
                  shouldCloseOnOverlayClick
                  onClose={this.handleModal}
                  onDismiss={this.handleModal}
                  isOpen={showModal}
                  closeButtonPosition="right"
                  ariaHideApp={false}
                  success={isSuccessful}
                >
                  <GoogleForm
                    showLogo
                    showContactInfo
                    isGoogleForm
                    formId={formId}
                    headerTitle="Contact Us"
                    buttonLabel="Send"
                    ctaIntentSuffix="modal-google-gsuite-workshops"
                    inquiryPlaceholder="I'd like a quote for G Suite. We have [number] of users and are interested in the [Basic, Business, Enterprise] license."
                    isSuccessfulCallback={v => this.handleIsSuccessful(v)}
                  />
                </ContactModal>
              ) : null}
            </div>
            <div className={style.images}>
              <img
                alt="admin"
                src="/images/logos/google/gsuite/admin.png"
                className={cn(style.image, style.admin)}
              />
              <img
                alt="calendar"
                src="/images/logos/google/gsuite/calendar85.png"
                className={cn(style.image, style.calendar)}
              />
              <img
                alt="contacts"
                src="/images/logos/google/gsuite/contacts65.png"
                className={cn(style.image, style.contacts)}
              />
              <img
                alt="docs"
                src="/images/logos/google/gsuite/docs.png"
                className={cn(style.image, style.docs)}
              />
              <img
                alt="drive"
                src="/images/logos/google/gsuite/drive.png"
                className={cn(style.image, style.drive)}
              />
              <img
                alt="forms"
                src="/images/logos/google/gsuite/forms60.png"
                className={cn(style.image, style.forms)}
              />
              <img
                alt="gmail"
                src="/images/logos/google/gsuite/gmail.png"
                className={cn(style.image, style.gmail)}
              />
              <img
                alt="groups"
                src="/images/logos/google/gsuite/groups40.png"
                className={cn(style.image, style.groups)}
              />
              <img
                alt="hangouts"
                src="/images/logos/google/gsuite/hangouts.png"
                className={cn(style.image, style.hangouts)}
              />
              <img
                alt="keep"
                src="/images/logos/google/gsuite/keep30.png"
                className={cn(style.image, style.keep)}
              />
              <img
                alt="meet"
                src="/images/logos/google/gsuite/meet50.png"
                className={cn(style.image, style.meet)}
              />
              <img
                alt="sheets"
                src="/images/logos/google/gsuite/sheets.png"
                className={cn(style.image, style.sheets)}
              />
              <img
                alt="slides"
                src="/images/logos/google/gsuite/slides.png"
                className={cn(style.image, style.slides)}
              />
              <img
                alt="vault"
                src="/images/logos/google/gsuite/vault35.png"
                className={cn(style.image, style.vault)}
              />
            </div>
          </div>
          <svg
            className={style.bottomDivider}
            xmlns="http://www.w3.org/2000/svg"
            width="1332"
            height="87"
            viewBox="0 0 1332 87"
          >
            <polygon
              fill="#FFF"
              fillRule="evenodd"
              points="180 50.5 629 0 1332 86.5 180 86.5 0 86.5"
            />
          </svg>
        </div>
      </section>
    )
  }
}

GSuite.propTypes = {
  formId: PropTypes.string.isRequired,
}

export default GSuite
