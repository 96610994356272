import React from 'react'
import cn from 'clsx'

import WorkPlayHard from 'components/SVG/workPlayHard'
import Cloud from 'components/SVG/cloud'

import style from './workHard.module.css'

const WorkHard = () => (
  <div className={style.workHard}>
    <WorkPlayHard className={style.workPlayHardText} />
    <svg
      className={style.lightning}
      xmlns="http://www.w3.org/2000/svg"
      width="1374"
      height="655"
      viewBox="0 0 1374 655"
    >
      <defs>
        <linearGradient
          id="orange_lightning-a"
          x1="15.436%"
          x2="114.319%"
          y1="90.518%"
          y2="21.413%"
        >
          <stop offset="0%" stopColor="#FFC42A" />
          <stop offset="100%" stopColor="#FF0061" stopOpacity="0.946" />
        </linearGradient>
        <filter
          id="orange_lightning-b"
          width="104.4%"
          height="111.3%"
          x="-2.2%"
          y="-5.6%"
          filterUnits="objectBoundingBox"
        >
          <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
        </filter>
      </defs>
      <g fill="url(#orange_lightning-a)" fillRule="evenodd" transform="translate(-18 -9)">
        <polygon
          points="384.366 58.757 213.702 95.585 20.546 232.288 292.296 95.585 786.1 232.288 1027.832 443.039 1392.067 590.11 1099.707 456.274 828.499 249.257"
          filter="url(#orange_lightning-b)"
          opacity="0.323"
          transform="rotate(5 706.306 324.433)"
        />
        <polygon points="384.366 58.757 213.702 95.585 20.546 232.288 292.296 95.585 786.1 232.288 1027.832 443.039 1392.067 590.11 1099.707 456.274 828.499 249.257" />
      </g>
    </svg>
    <div className={style.images}>
      <img
        className={cn(style.image, style.first)}
        src="/images/team/team.png"
        alt="Our team of cloud computing experts in Utah"
      />
      <div className={style.smallImages}>
        <img
          className={cn(style.image, style.second)}
          src="/images/team/office.png"
          alt="Our team of cloud computing experts getting ready to meet and discuss future plans"
        />
        <img
          className={cn(style.image, style.third)}
          src="/images/team/team_work.png"
          alt="Our team of cloud computing experts working together to solve problems"
        />
      </div>
    </div>
    <div className={style.content}>
      <Cloud type="angled" className={style.cloud} />
      <div className={style.info}>
        <h2>Our Minds are in the Cloud.</h2>
        <p>
          The Observian team is comprised of thought leaders and technical gurus who share a common
          vision that cloud computing is changing the world and that Amazon and Microsoft are
          leading that charge. With new technologies being developed every day to compliment AWS and
          Azure, someone needs to keep their fingers on the pulse. With our minds in the cloud, and
          with certifications to back up our collective experience, our team is providing customers
          with the most useful cloud solutions, services, and architecture. We can help you too.
        </p>
      </div>
    </div>
  </div>
)

WorkHard.defaultProps = {}

WorkHard.propTypes = {}

export default WorkHard
